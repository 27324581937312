import { Box, Divider, Grid, ListItem, Typography } from '@material-ui/core'
import orderBy from 'lodash/orderBy'
import maxBy from 'lodash/maxBy'
import { inject, observer } from 'mobx-preact'
import {
  AlignBottomIcon,
  AlignMiddleIcon,
  AlignTopIcon,
  ExpandIcon,
  HoriziontalAlignment,
  ShrinkIcon
} from '../../common/icons'

const AlignMenuComponent = ({ art }) => {
  const handleAlignTop = () => {
    const elements = art.multiSelect
    const ys = elements.map(el => el.getClientRect().y)
    const minY = Math.min(...ys)
    for (const el of elements) {
      const box = el.getClientRect()
      const absPos = el.absolutePosition()
      el.absolutePosition({
        x: el.getAbsolutePosition().x,
        y: minY + Math.round(absPos.y - box.y)
      })
      art.updateObject(el.name(), el.id(), {
        attrs: JSON.stringify(el.attrs)
      })
    }
    art.canvas.draw()
  }

  const handleAlignBottom = () => {
    const elements = art.multiSelect
    const largest = maxBy(elements, e => e.getClientRect().height)
    for (const el of elements) {
      const box = el.getClientRect()
      const absPos = el.absolutePosition()
      const largestBox = largest.getClientRect()
      const largestBoxAbsPos = largest.absolutePosition()
      const bottomY = largestBoxAbsPos.y + largestBox.height
      if (absPos.y === largestBoxAbsPos.y) {
        continue
      }
      el.absolutePosition({
        x: el.getAbsolutePosition().x,
        y: Math.round(absPos.y - box.y) + (bottomY - box.height)
      })
      art.updateObject(el.name(), el.id(), {
        attrs: JSON.stringify(el.attrs)
      })
    }
    art.canvas.draw()
  }

  const handleAlignMiddle = () => {
    const elements = art.multiSelect
    const largest = maxBy(elements, e => e.getClientRect().height)
    for (const el of elements) {
      const box = el.getClientRect()
      const absPos = el.absolutePosition()
      const largestBox = largest.getClientRect()
      const largestBoxAbsPos = largest.absolutePosition()
      const bottomY = largestBoxAbsPos.y + largestBox.height
      if (absPos.y === largestBoxAbsPos.y) {
        continue
      }
      el.absolutePosition({
        x: el.getAbsolutePosition().x,
        y:
          Math.round(absPos.y - box.y) +
          (bottomY - box.height) -
          Math.round((largestBox.height - box.height) / 2)
      })
      art.updateObject(el.name(), el.id(), {
        attrs: JSON.stringify(el.attrs)
      })
    }
    art.canvas.draw()
  }

  const resizeToSize = larger => {
    const elements = art.multiSelect
    const scales = elements.map(e => e.scaleX())
    const newScale = larger ? Math.max(...scales) : Math.min(...scales)
    for (const el of elements) {
      const scale = el.scaleX()
      if (scale != newScale) {
        el.scale({
          x: newScale,
          y: newScale
        })
        art.updateObject(el.name(), el.id(), {
          attrs: JSON.stringify(el.attrs)
        })
      }
    }
    art.canvas.draw()
  }

  const spaceAround = () => {
    const elements = art.multiSelect

    const transformer = art.canvas._defaultTransformer
    const totalWidth = transformer.getClientRect().width
    const widths = elements.map(el => el.getClientRect().width)
    const totalElementWidths = widths.reduce((a, b) => a + b, 0)
    const totalFreeSpace = totalWidth - totalElementWidths
    const elementFreeSpace = Math.round(totalFreeSpace / (elements.length - 1))
    const orderedByXPosition = orderBy(elements, e => e.getClientRect().x)

    for (let i = 0; i < orderedByXPosition.length; i++) {
      if (i === 0 || i === orderedByXPosition.length - 1) {
        continue
      }
      const previousBox = orderedByXPosition[i - 1].getClientRect()
      const previousBoxAbsPos = orderedByXPosition[i - 1].absolutePosition()
      const element = orderedByXPosition[i]
      element.absolutePosition({
        x: previousBoxAbsPos.x + previousBox.width + elementFreeSpace,
        y: element.getAbsolutePosition().y
      })
      art.updateObject(element.name(), element.id(), {
        attrs: JSON.stringify(element.attrs)
      })
    }
    art.canvas.draw()
  }

  return (
    <Grid container direction='column'>
      <Grid item>
        <Typography variant='h6'>Alinhamento</Typography>
        <Box padding={1} />
        <Grid container justify='space-between'>
          <Grid item>
            <ListItem button onClick={handleAlignTop}>
              <AlignTopIcon />
              <Typography style={{ marginLeft: 8 }} variant='button'>
                Em cima
              </Typography>
            </ListItem>
          </Grid>
          <Grid item>
            <ListItem button onClick={handleAlignMiddle}>
              <AlignMiddleIcon />
              <Typography style={{ marginLeft: 8 }} variant='button'>
                Centro
              </Typography>
            </ListItem>
          </Grid>
          <Grid item>
            <ListItem button onClick={handleAlignBottom}>
              <AlignBottomIcon />
              <Typography style={{ marginLeft: 8 }} variant='button'>
                Em baixo
              </Typography>
            </ListItem>
          </Grid>
          <Divider variant='fullWidth' orientation='vertical' />
        </Grid>
      </Grid>
      <Box padding={1} />
      <Typography variant='h6'>Tamanho</Typography>
      <Box padding={1} />
      <Grid container item>
        <Grid item xs={6}>
          <ListItem button onClick={() => resizeToSize(false)}>
            <ShrinkIcon />
            <Typography style={{ marginLeft: 12 }} variant='button'>
              Igualar ao menor
            </Typography>
          </ListItem>
        </Grid>
        <Grid item xs={6}>
          <ListItem button onClick={() => resizeToSize(true)}>
            <ExpandIcon />
            <Typography style={{ marginLeft: 12 }} variant='button'>
              Igualar ao maior
            </Typography>
          </ListItem>
        </Grid>
      </Grid>
      <Box padding={1} />
      <Grid item>
        <Typography variant='h6'>Posição</Typography>
        <Box padding={1} />
        <Grid item>
          <ListItem button onClick={spaceAround}>
            <HoriziontalAlignment />
            <Typography style={{ marginLeft: 12 }} variant='button'>
              Espaçar igualmente
            </Typography>
          </ListItem>
        </Grid>
      </Grid>
    </Grid>
  )
}

export const AlignMenu = inject('art')(observer(AlignMenuComponent))
