import { useEffect } from 'preact/hooks'
import { useHistory, useLocation } from 'react-router'
import AppRoutes from '../navigation/routes'
import { userService } from '../user/service'

export const SignupConfirmation = () => {
  const history = useHistory()
  const params = new URLSearchParams(useLocation().search)

  useEffect(() => {
    const fetchConfirmation = async () => {
      try {
        const response = await userService.confirmation(
          params.get('token'),
          params.get('email')
        )

        history.push(`${AppRoutes.Login}?email=${response.email}`)
      } catch (e) {
        history.push(AppRoutes.Login)
      }
    }
    fetchConfirmation()
  }, [])
  return null
}
