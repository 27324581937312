import {
  Container,
  Hidden,
  IconButton,
  makeStyles,
  Toolbar as MaterialToolbar
} from '@material-ui/core'
import { PersonOutlineOutlined, TextsmsOutlined } from '@material-ui/icons'
import LogoutButton from '@material-ui/icons/ExitToAppOutlined'
import { inject, observer } from 'mobx-preact'
import { Fragment } from 'preact'
import { useState } from 'preact/hooks'
import { useHistory } from 'react-router'
import Logo from '../assets/logo_easyoffer_blue.svg'
import ConfirmDialog from '../common/confirm'
import Spacer from '../common/spacer'
import { TrialMessage } from '../common/trial-message'
import Colors from '../config/colors'
import AppRoutes from '../navigation/routes'
import { useMobileDetect } from '../utils/mobile'

const useStyles = makeStyles({
  root: {
    margin: 8
  },
  createOfferButton: {
    marginRight: 24
  },
  button: {
    color: Colors.accentColor
  },
  title: {},
  menuButton: {
    marginLeft: 20,
    marginRight: 36
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: 8,
    paddingLeft: `calc(1em + ${32}px)`
  },
  logo: {
    padding: 4,
    height: '100%'
  }
})

const ToolbarComponent = props => {
  const detectMobile = useMobileDetect()
  const classes = useStyles()
  const history = useHistory()

  const [dialog, setDialog] = useState(false)

  const { auth } = props

  const handleHelp = () => {
    if (window.$crisp) {
      window.$crisp.push(['do', 'chat:show'])
      window.$crisp.push(['do', 'chat:open'])
    }
  }

  return (
    <Container maxWidth='lg'>
      <ConfirmDialog
        cancelLabel='Cancelar'
        confirmLabel='Sair'
        open={dialog}
        content='Deseja sair do OfertaFácil?'
        onCancel={() => setDialog(false)}
        onConfirm={() => auth.logout(history)}
      />
      <MaterialToolbar className={classes.root}>
        {!detectMobile.isMobile() && (
          <Fragment>
            <Hidden smDown>
              <img
                onClick={() => history.push(AppRoutes.Offers)}
                src={Logo}
                height={22}
                style={{
                  cursor: 'pointer',
                  padding: 4,
                  fill: Colors.textPrimary
                }}
              />
            </Hidden>
            <div style={{ flex: 1 }} />
          </Fragment>
        )}
        <TrialMessage />
        <div style={{ flex: 1 }} />
        <Spacer horizontal double />
        <IconButton onClick={() => history.push(AppRoutes.Account)}>
          <PersonOutlineOutlined />
        </IconButton>
        <IconButton onClick={handleHelp}>
          <TextsmsOutlined color='primary' />
        </IconButton>
        <IconButton onClick={() => setDialog(true)}>
          <LogoutButton />
        </IconButton>
      </MaterialToolbar>
    </Container>
  )
}

export const Toolbar = inject(
  'auth',
  'art',
  'layout'
)(observer(ToolbarComponent))
