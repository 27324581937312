export default class Colors {
  static accentColor = '#2196F3'
  static lightAccentColor = '#E8F0FE'

  static textColor = '#323F52'
  static textBodyColor = '#3C4043'
  static textDescriptionColor = '#5F6368'
  static textAccentColor = '#1A73E8'
  static textCaptionColor = '#5F6368'
  static sidebarMenuColor = '#019EEC'
  static canvasBackground = '#EDF0F2'

  static dividerColor = '#DADCE0'
  static textInputColor = '#F1F3F4'

  static secondary = '#E75C12'
  static danger = '#FF3D00'

  static white = '#FFFFFF'
}
