export const fetchLogosQuery = `
  query Logos {
    logos {
         id
        _id
        _key
        _rev
        name
        imageURL
    }
  }
`

export const logosByFilterQuery = `
  query LogosByFilter($filter: LogosFilterInput! ) {
    logosByFilter(filter: $filter ) {
         id
        _id
        _key
        _rev
        name
        imageURL
    }
  }
`

export const fetchLogoQuery = `
  query Logo($id: String!) {
    logo(id: $id) {
      id
      _id
      _key
      _rev
      name
      imageURL
    }
  }
`
