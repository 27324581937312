import { captureException } from '@sentry/browser'
import { action, observable } from 'mobx'
import offerService from '../offer/providers/offer'
import uuidv4 from '../utils/uuid'
import clone from 'ramda/es/clone'

class ArtStore {
  canvas
  activeTemplate

  @observable
  update

  @observable
  undoStack = []

  @observable
  current = 0

  @observable offer = null
  @observable multiSelect = []

  @observable selectedElement = {
    type: null,
    element: null
  }

  @observable
  elementDetails = false

  @action
  showElementDetails = () => {
    this.elementDetails = true
  }

  @action
  hideElementDetails = () => {
    this.elementDetails = false
  }

  constructor (root) {
    this.root = root
    setInterval(this.saveToDb, 2000)
  }

  @action
  setMultiSelected = elements => {
    if (this.multiSelect.length === 0 && elements.length === 0) return
    this.multiSelect = elements
  }

  @action
  setSelected = (type, element) => {
    if (!type || !element) return
    this.selectedElement = {
      type,
      element
    }
  }

  setActiveTemplate = template => {
    this.activeTemplate = template
  }

  @action
  clearSelected = () => {
    if (this.selectedElement.element == null) return
    this.selectedElement = {
      type: null,
      element: null
    }
    if (this.canvas) {
      this.canvas.clearSelected()
    }
  }

  saveChanges = () => {
    this.saveToUndo()
    this.update = this.offer
  }

  @action
  saveToUndo = () => {
    this.undoStack = this.undoStack.slice(0, this.current + 1)
    this.undoStack = this.undoStack.concat([clone(this.offer)])
    this.current += 1
  }

  @action
  undo = () => {
    if (this.current === 0) return
    this.current -= 1
    const previous = this.undoStack[this.current]
    if (previous) {
      this.offer.background = clone(previous.background)
      this.offer.format = clone(previous.format)
      this.offer.logos = clone(previous.logos)
      this.offer.products = clone(previous.products)
      this.offer.showTags = previous.showTags
      this.offer.tag = clone(previous.tag)
      this.offer.texts = clone(previous.texts)
      this.offer.textColor = previous.textColor
      this.offer.textFamily = previous.textFamily
      this.offer.textStyle = previous.textStyle
      this.offer.showTags = previous.showTags
      this.canvas.draw()
    }
  }

  @action
  redo = () => {
    if (this.current === this.undoStack.length - 1) {
      return
    }
    this.current += 1
    const next = clone(this.undoStack[this.current])
    if (next) {
      this.offer.background = clone(next.background)
      this.offer.format = clone(next.format)
      this.offer.logos = clone(next.logos)
      this.offer.products = clone(next.products)
      this.offer.showTags = next.showTags
      this.offer.tag = clone(next.tag)
      this.offer.texts = clone(next.texts)
      this.offer.textColor = next.textColor
      this.offer.textFamily = next.textFamily
      this.offer.textStyle = next.textStyle
      this.offer.showTags = next.showTags
      this.canvas.draw()
    }
  }

  saveToDb = async () => {
    try {
      if (this.update && this.update._id) {
        await offerService.update(this.offer)
        this.update = null
      }
    } catch (e) {
      captureException(e)
    }
  }

  setCanvas = canvas => {
    this.canvas = canvas
  }

  @action
  setOffer = offer => {
    this.offer = clone(offer)

    this.undoStack = [offer]
    this.current = 0

    if (this.canvas) {
      this.canvas.drawOffer()
    }
  }

  @action
  selectNextProduct = id => {
    let index = this.offer.products.findIndex(p => p.id === id)
    index = index + 1 > this.offer.products.length - 1 ? (index = 0) : index + 1
    const nextProduct = this.offer.products[index]
    this.setSelected('product', nextProduct)
    this.canvas.selectProduct(nextProduct.id)
  }

  @action
  setOfferProperty = (name, value) => {
    let newValue = value
    if (name === 'format') {
      delete newValue.imageURL
    }

    this.offer[name] = newValue
    this.saveChanges()
  }

  @action
  setOfferProperties = update => {
    for (const key of Object.keys(update)) {
      this.offer[key] = update[key]
    }
    this.saveChanges()
  }

  updateObject = (tag, id, update, dontSave) => {
    const index = this.locateObjectIndex(tag, id)
    if (index < 0) return
    this.updateObjectAtIndex(tag, index, update, dontSave)
  }

  @action
  updateObjectOnCanvas = (tag, id, update) => {
    const index = this.locateObjectIndex(tag, id)
    if (index < 0) return
    if (tag === 'text') {
      Object.assign(this.offer.texts[index], update)
      this.offer.texts = [...this.offer.texts]
    }
    if (tag === 'product') {
      Object.assign(this.offer.products[index], update)
      this.offer.products = [...this.offer.products]
    }
    if (tag === 'element') {
      Object.assign(this.offer.logos[index], update)
      this.offer.logos = [...this.offer.logos]
    }
    this.saveChanges()
  }

  locateObjectIndex = (tag, id) => {
    switch (tag) {
      case 'element':
        return this.offer.logos.findIndex(l => l.id === id)
      case 'text':
        return this.offer.texts.findIndex(t => t.id === id)
      case 'product':
        return this.offer.products.findIndex(p => p.id === id)
      default:
        return 0
    }
  }

  updateObjectAtIndex = (tag, index, payload) => {
    if (tag === 'text') {
      Object.assign(this.offer.texts[index], payload)
    }
    if (tag === 'product') {
      Object.assign(this.offer.products[index], payload)
    }
    if (tag === 'element') {
      Object.assign(this.offer.logos[index], payload)
    }

    this.saveChanges()
  }

  @action
  addText = text => {
    const objectText = {
      id: text.id || uuidv4(),
      ...text
    }

    this.offer.texts = this.offer.texts || []
    this.offer.texts = [...this.offer.texts, objectText]
    this.saveChanges()
  }

  @action
  removeText = id => {
    const index = this.offer.texts.findIndex(t => t.id === id)
    this.offer.texts.splice(index, 1)
    this.offer.texts = [...this.offer.texts]
    this.canvas.removeElementFromCanvas(id)
    this.clearSelected()
    this.saveChanges()
  }

  @action
  addProduct = product => {
    product.id = uuidv4()
    product.tagVisible = true

    this.offer.products = [...this.offer.products, product]
    this.saveChanges()
  }

  @action
  addProducts = products => {
    this.offer.products = [...this.offer.products, ...products]
    this.saveChanges()
  }

  @action
  setProducts = products => {
    this.offer.products = [...products]
    this.saveChanges()
  }

  @action
  addLogos = logo => {
    logo.id = uuidv4()

    if (!this.offer) {
      return
    }

    if (this.offer.logos === null) {
      this.offer.logos = []
    }

    this.offer.logos = [...this.offer.logos, logo]
    this.saveChanges()
  }

  removeObject = (tag, id) => {
    const index = this.locateObjectIndex(tag, id)
    switch (tag) {
      case 'text':
        this.offer.texts.splice(index, 1)
        this.offer.texts = [...this.offer.texts]
        break
      case 'product':
        this.offer.products.splice(index, 1)
        this.offer.products = [...this.offer.products]
        break
      case 'element':
        this.offer.logos.splice(index, 1)
        this.offer.logos = [...this.offer.logos]
        break
      default:
        break
    }

    this.canvas.removeElementFromCanvas(id)
    this.clearSelected()
    this.saveChanges()
  }
}

export default ArtStore
