import { Grid, makeStyles } from '@material-ui/core'
import { useEffect, useState } from 'preact/hooks'
import { useHistory, useRouteMatch } from 'react-router'
import { CanvasScreen } from '../../canvas/screen'
import { LinearLoadingIndicator } from '../../common/loading'
import Colors from '../../config/colors'
import { hostname } from '../../http/hostname'
import AppRoutes from '../../navigation/routes'
import { useSubscribed } from '../../navigation/subscribed'
import { captureWithScope } from '../../sentry'
import store from '../../stores/root'
import offerService from '../providers/offer'
import { OfferContent } from './content'
import { OfferDrawer } from './drawer'
import { OfferEditMenu } from './menu'
import { OfferEditToolbar } from './toolbar'

export const OfferEditScreen = () => {
  useSubscribed()
  const history = useHistory()

  const classes = useStyles()
  const { params } = useRouteMatch()

  const [offer, setOffer] = useState(null)

  const removeSelected = e => {
    const parent = e.target.parentNode
    const canvas = document.getElementById('art-canvas')
    const context = document.getElementById('context')

    if (!canvas || !context) return
    if (
      parent &&
      parent.getAttribute &&
      parent.getAttribute('role') === 'menuitem'
    ) {
      return
    }

    const isClickInside =
      canvas.contains(e.target) || context.contains(e.target)

    if (!isClickInside) {
      store.art.clearSelected()
    }
  }

  const handleKeyUp = e => {
    if (e.keyCode === 27) {
      store.art.clearSelected()
    }
    if (e.ctrlKey && e.code === 'KeyZ') {
      store.art.undo()
    }
  }

  const contextMenu = e => {
    hostname !== 'localhost' && e.preventDefault()
  }

  useEffect(() => {
    document.addEventListener('click', removeSelected)
    document.addEventListener('keyup', handleKeyUp)
    document.addEventListener('contextmenu', contextMenu)

    if (window.$crisp) {
      window.$crisp.push(['do', 'chat:hide'])
    }

    const fetchOffer = async () => {
      try {
        let offer = await offerService.offer(params.key)

        if (!offer) {
          return history.push(AppRoutes.Offers)
        }

        if (offer.slides && offer.slides.length > 0) {
          const slide = offer.slides[0]
          offer = Object.assign(offer, slide)
          offer.slides = null
          await offerService.update(offer)
        }

        setOffer(offer)

        store.art.setOffer(offer)
        store.art.clearSelected()
      } catch (e) {
        captureWithScope(e)
      }
    }

    fetchOffer()

    return () => {
      store.art.saveToDb()
      document.removeEventListener('click', removeSelected)
      document.removeEventListener('keyup', handleKeyUp)
      document.removeEventListener('contextmenu', contextMenu)
    }
  }, [])

  if (!offer) {
    return (
      <Grid
        container
        style={{
          height: '100vh',
          margin: '0 auto',
          width: '50vw',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <LinearLoadingIndicator label='Aguenta um pouquinho, estamos carregando o encarte...' />
      </Grid>
    )
  }

  return (
    <Grid container direction='column'>
      <OfferEditToolbar />
      <Grid className={classes.root}>
        <Grid className={classes.menuContainer} item>
          <Grid className={classes.menu} item>
            <OfferEditMenu offer={offer} />
          </Grid>
          <Grid
            id='offer-content'
            style={{
              width: 500 - 80,
              height: `calc(100vh - ${store.layout.offerEditToolbarHeight}px)`,
              overflowY: 'scroll',
              overflowX: 'hidden'
            }}
            item
          >
            <OfferContent />
          </Grid>
        </Grid>
        <CanvasScreen offer={offer} />
        <OfferDrawer />
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    height: 'calc(100vh - 56px)'
  },
  menuContainer: {
    display: 'flex',
    width: 500,
    borderRight: '1px dashed #DADCE0'
  },
  menu: {
    background: Colors.sidebarMenuColor,
    width: 79,
    height: 'calc(100vh - 56px)'
  }
})
