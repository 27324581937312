import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography
} from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { inject, observer } from 'mobx-preact'
import { useState } from 'preact/hooks'
import { RaisedButton } from '../../common/button'
import { CustomTextField } from '../../common/input'
import { LinearLoadingIndicator } from '../../common/loading'
import { businesses } from '../../config/business'
import { captureWithScope } from '../../sentry'
import store from '../../stores/root'
import { userService } from '../../user/service'

export const SelectBusinessComponent = ({ auth }) => {
  const { company } = auth.user

  const [business, setBusiness] = useState([])
  const [custom, setCustom] = useState('')
  const [busy, setBusy] = useState(false)

  const handleChange = value => {
    if (business.indexOf(value) < 0) {
      business.push(value)
    } else {
      business.splice(business.indexOf(value), 1)
    }
    setBusiness([...business])
  }

  const handleSave = async () => {
    if (custom && !business.includes(custom)) {
      business.push(custom)
    }

    if (business.length === 0) {
      return store.layout.showSnackbar('Selecione ao menos um ramo', true)
    }

    try {
      setBusy(true)
      await userService.updateBusiness(business)
      auth.setBusiness(business)
      setBusy(false)
    } catch (e) {
      setBusy(false)
      captureWithScope(e)
      store.layout.showSnackbar('Erro ao salvar os ramos', true)
    }
  }

  if (!company.business || company.business.length > 0) {
    return null
  }

  return (
    <Box padding={3}>
      <Typography variant='body2' align='center'>
        Para ver conteúdo mais <strong>personalizado</strong> informe um ou mais
        ramos de atuação:
      </Typography>
      <Box padding={1} />
      <Grid container>
        {businesses.map(b => (
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                  checkedIcon={<CheckBoxIcon fontSize='small' />}
                  checked={business.includes(b.name)}
                  onChange={() => handleChange(b.name)}
                  name={b.name}
                  color='primary'
                />
              }
              label={b.label}
            />
          </Grid>
        ))}
      </Grid>
      <Box padding={1} />
      <CustomTextField
        value={custom}
        onChange={e => setCustom(e.target.value)}
        placeholder='Outro ramo...'
      />
      <Box padding={1} />
      {busy ? (
        <LinearLoadingIndicator />
      ) : (
        <RaisedButton primary fullWidth onClick={handleSave} label='Salvar' />
      )}
    </Box>
  )
}

export const SelectBusiness = inject('auth')(observer(SelectBusinessComponent))
