import { Box, Grid, Typography } from '@material-ui/core'
import { useState } from 'preact/hooks'
import { useHistory, useRouteMatch } from 'react-router'
import useSWR from 'swr'
import { RaisedButton } from '../../common/button'
import { LinearLoadingIndicator } from '../../common/loading'
import store from '../../stores/root'
import useDebounce from '../../utils/debounce'
import backgroundService from '../providers/background'
import BackgroundSearch from '../search/field'
import { SelectBusiness } from './business'
import { BackgroundList } from './container'

export const BackgroundListScreen = () => {
  const history = useHistory()
  const { url } = useRouteMatch()

  const [term, setTerm] = useState(null)
  const debounce = useDebounce(term, 500)
  const { format } = store.art.offer

  const rootUrl = url.substring(0, url.lastIndexOf('/'))
  const { data: backgrounds } = useSWR(
    `backgrounds-${format?.code || 'square'}-${debounce}`,
    () => backgroundService.backgrounds(format?.code, debounce)
  )

  return (
    <Box padding={2} paddingTop={1}>
      <Grid container>
        <BackgroundSearch onClear={() => setTerm('')} onSearch={setTerm} />
      </Grid>
      <SelectBusiness />
      <Box padding={1} />
      {!backgrounds && <LinearLoadingIndicator />}
      {backgrounds && backgrounds && backgrounds.length === 0 && (
        <Grid container justify='center'>
          <Box padding={3}>
            <Typography variant='h4' align='center'>
              Ops!
            </Typography>
            <Box padding={1} />
            <Typography variant='body1' align='center'>
              Nenhuma arte encontrada, tente outro termo.
            </Typography>
            <Grid container justify='center'>
              <Box padding={3}>
                <RaisedButton
                  onClick={() => history.push(`${rootUrl}/pedidos/registrar`)}
                  primary
                  label='Comprar Arte Customizada'
                />
              </Box>
            </Grid>
          </Box>
        </Grid>
      )}
      {backgrounds && (
        <BackgroundList
          format={store.art.offer.format}
          backgrounds={backgrounds}
          art={store.art}
        />
      )}
    </Box>
  )
}
