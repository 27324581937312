import { Box, Grid, Toolbar, Typography } from '@material-ui/core'
import { Fragment } from 'preact'
import { useState } from 'preact/hooks'
import useSWR, { mutate } from 'swr'
import { FlatButton } from '../../common/button'
import { LinearLoadingIndicator } from '../../common/loading'
import { AddFolderDialog } from '../common/add_dialog'
import { Folder } from '../common/folder'
import { folderProvider } from '../providers/folder'

export const FolderList = props => {
  const { type, onClick } = props
  const [addFolder, setAddFolder] = useState(false)

  const { data: folders } = useSWR(`folders/${type}`, () =>
    folderProvider.folders(type)
  )

  if (!folders) {
    return <LinearLoadingIndicator />
  }

  return (
    <Fragment>
      <AddFolderDialog
        open={addFolder}
        onClose={() => {
          setAddFolder(false)
          mutate(`folders/${type}`)
        }}
      />
      <Toolbar disableGutters>
        <Grid container justify='space-between'>
          <Typography variant='h6'>Minhas Pastas</Typography>
          <FlatButton
            onClick={() => setAddFolder(true)}
            primary
            label='Criar Pasta'
          />
        </Grid>
      </Toolbar>
      <Grid container justify='space-around'>
        {folders.length === 0 ? (
          <Box padding={3}>
            <Typography variant='body2' align='center'>
              Crie pastas para organizar os seus produtos.
            </Typography>
          </Box>
        ) : (
          folders.map(folder => (
            <Folder onClick={() => onClick(folder)} label={folder.name} />
          ))
        )}
      </Grid>
    </Fragment>
  )
}
