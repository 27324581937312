export const updateOfferMutation = `
mutation updateOffer($data: OfferInput!) {
  updateOffer(data: $data) {
      _id
      _key
      _rev
  }
}
`

export const removeOfferMutation = `
mutation RemoveOffer($id: String!) {
  removeOffer(id: $id) {
      _id
      _key
      _rev
  }
}
`

export const duplicateOfferMutation = `
mutation DuplicateOffer($id: String!) {
  duplicateOffer(id: $id) {
      _id
      _key
      _rev
  }
}
`
