import {
  Box,
  Grid,
  GridList,
  GridListTile,
  LinearProgress,
  Typography
} from '@material-ui/core'
import { SaveAltOutlined } from '@material-ui/icons'
import { Fragment } from 'preact'
import { useState } from 'preact/hooks'
import { useHistory, useRouteMatch } from 'react-router'
import useSWR, { mutate } from 'swr'
import { RaisedButton } from '../../common/button'
import { FileDropZone } from '../../common/dropzone'
import Colors from '../../config/colors'
import store from '../../stores/root'
import uploadImage from '../../utils/upload'
import { getImageUrl } from '../../utils/url'
import backgroundService from '../providers/background'

export const CustomBackgrounds = () => {
  const { url } = useRouteMatch()
  const history = useHistory()
  const { art, layout } = store

  const [uploading, setUploading] = useState(false)

  const handleAddBackground = async file => {
    if (!file) return

    const { width, height, size, code } = art.offer.format

    const img = new Image()
    img.onload = async function () {
      if (this.width !== width || this.height !== height) {
        return layout.showSnackbar(
          `O formato da imagem deve ser: ${size}`,
          true
        )
      }

      setUploading(true)
      const img = await fetch(file).then(r => r.blob())
      const imageURL = await uploadImage(
        img,
        art.offer.company,
        'background_custom'
      )

      if (!imageURL) {
        throw new Error('Não foi possível enviar a imagem. Tente novamente.')
      }

      await backgroundService.addCustom({
        name: '',
        imageURL,
        format: code,
        tags: ''
      })

      setUploading(false)
      mutate('backgrounds/custom', true)
    }
    img.src = file
  }

  const { data: backgrounds } = useSWR(
    'backgrounds/custom',
    backgroundService.custom
  )

  const height = (375 / 3) * art.offer.format.ratio
  const rootUrl = url.split('/').slice(0, 3).join('/')

  return (
    <Box padding={2}>
      {uploading ? (
        <Grid item>
          <Box padding={1}>
            <LinearProgress variant='indeterminate' />
          </Box>
        </Grid>
      ) : (
        <FileDropZone onSelectFile={handleAddBackground}>
          <SaveAltOutlined
            style={{ color: Colors.dividerColor, marginBottom: 8 }}
          />
          <Typography variant='body1' align='center'>
            Adicione a sua própria arte no formato {art.offer.format.size}
          </Typography>
        </FileDropZone>
      )}
      {!backgrounds && (
        <Grid item>
          <Box padding={1}>
            <LinearProgress variant='indeterminate' />
          </Box>
        </Grid>
      )}
      {backgrounds && backgrounds.length === 0 && (
        <Grid container>
          <Box padding={5} />
          <Grid item container justify='center'>
            <Grid item xs={12}>
              <Typography align='center' variant='body1'>
                Você ainda não adicionou nenhuma arte customizada
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {backgrounds && backgrounds.length > 0 && (
        <Fragment>
          <Box padding={1} />
          <GridList cellHeight={height} cols={3}>
            {backgrounds.map(b => {
              const allowedFormat = b.format === art.offer.format.code
              const onSelect = allowedFormat
                ? () => art.setOfferProperty('background', b)
                : () =>
                    layout.showSnackbar(
                      'Selecione outro formato para utilizar essa arte',
                      true
                    )

              return (
                <GridListTile
                  key={b._id}
                  style={{ opacity: allowedFormat ? 1 : 0.5 }}
                  onClick={onSelect}
                >
                  <img src={getImageUrl(b.imageURL, 180)} />
                </GridListTile>
              )
            })}
          </GridList>
        </Fragment>
      )}
    </Box>
  )
}
