import { captureWithScope } from '../sentry'
import store from '../stores/root'
import uuidv4 from '../utils/uuid'
import { templateProvider } from './providers/template'

export const applyTemplateToOffer = async (template, user) => {
  try {
    if (user) {
      template = await templateProvider.userTemplate(template._id)
    } else {
      template = await templateProvider.template(template._id)
    }

    store.art.setActiveTemplate(template)

    const offer = {
      priceFontSize: template.priceFontSize,
      nameFontSize: template.nameFontSize,
      textColor: template.textColor,
      showTags: template.showTags,
      textFamily: template.textFamily,
      textStyle: template.textStyle,
      texts: template.texts || [],
      background: template.background,
      tag: template.tag,
      logos: template.logos || [],
      products: template.products || []
    }

    offer.products = offer.products.map(p => {
      p.id = uuidv4()
      return p
    })
    offer.texts = offer.texts.map(t => {
      t.id = uuidv4()
      return t
    })
    offer.logos = offer.logos.map(o => {
      o.id = uuidv4()
      return o
    })

    store.art.setOfferProperties(offer)
  } catch (e) {
    captureWithScope(e)
    store.layout.showSnackbar('Erro a aplicar o template', true)
  }
}
