import { Grid, Typography } from '@material-ui/core'
import { FolderIcon } from '../../common/icons'
import Colors from '../../config/colors'

export const Folder = ({ icon, color, label, onClick }) => {
  return (
    <Grid
      onClick={onClick}
      style={{
        width: 110,
        cursor: 'pointer',
        textAlign: 'center',
        marginBottom: 8
      }}
      item
    >
      <div
        style={{
          borderRadius: 8,
          height: 98,
          width: 110,
          padding: 21,
          marginBottom: 6,
          backgroundColor: color || Colors.textInputColor,
          textAlign: 'center'
        }}
      >
        {icon || <FolderIcon />}
      </div>
      <Typography align='center' variant='button'>
        {label}
      </Typography>
    </Grid>
  )
}
