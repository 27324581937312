import { action, observable } from 'mobx'

export class MobileStore {
  @observable
  menu = false

  @observable
  content = 'arte'

  @action
  toggleMenu = () => {
    this.content = 'arte'
    this.menu = !this.menu
  }

  @action
  setContent = content => {
    this.content = content
  }
}
