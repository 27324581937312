import { Box, Typography } from '@material-ui/core'
import { Fragment } from 'preact'
import { useEffect } from 'preact/hooks'
import Dropzone from 'react-dropzone'
import Colors from '../config/colors'
import store from '../stores/root'
import { KeyboardKey } from './key'

export const FileDropZone = ({ children, onSelectFile, readFile = true }) => {
  const handleSelectedFile = async files => {
    if (!files || files.length === 0) {
      return
    }
    const file = files[0]
    if (readFile) {
      const reader = new FileReader()
      reader.onload = () => {
        onSelectFile(reader.result)
      }
      reader.readAsDataURL(file)
    } else {
      onSelectFile(file)
    }
  }

  const handleErrors = e => {
    const error = e['0'].errors[0]

    let message
    switch (error.code) {
      case 'file-too-large':
        message = 'O tamanho máximo do arquivo deve ser 1MB'
        break
      case 'file-invalid-type':
        message = 'A imagem deve ser no formato jpeg, png ou webp'
        break
      default:
        message = 'Erro ao enviar imagem, tente outra imagem'
        break
    }

    store.layout.showSnackbar(message, true)
  }

  useEffect(() => {
    document.onpaste = e => {
      const dt = e.clipboardData || window.clipboardData
      const file = dt?.files[0]
      if (file && file.type.indexOf('image') === 0) {
        const img = new Image(file)
        if (img.size > 10000) {
          return store.layout.showSnackbar(
            'O tamanho máximo do arquivo deve ser 1MB',
            true
          )
        }
        handleSelectedFile([file])
      }
    }
    return () => (document.onpaste = () => {})
  }, [])

  return (
    <Dropzone
      accept={['image/jpeg', 'image/png', 'image/webp']}
      maxSize={1000000}
      onDrop={handleSelectedFile}
      onDropRejected={handleErrors}
    >
      {({
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
      }) => {
        const style = {
          ...baseStyle,
          ...(isDragActive ? activeStyle : {}),
          ...(isDragAccept ? acceptStyle : {}),
          ...(isDragReject ? rejectStyle : {})
        }
        return (
          <section>
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              {children || (
                <Fragment>
                  <Typography variant='body2' align='center'>
                    <KeyboardKey label='CTRL' /> + <KeyboardKey label='V' />
                    <Box padding={1} />
                    <strong>Clique</strong>, <strong>arraste</strong> aqui ou
                    <strong> cole</strong> uma imagem
                  </Typography>
                </Fragment>
              )}
            </div>
          </section>
        )
      }}
    </Dropzone>
  )
}

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '32px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: Colors.textInputColor,
  borderStyle: 'dashed',
  backgroundColor: '#fff',
  color: '#bdbdbd',
  outline: 'none',
  cursor: 'pointer',
  transition: 'border .24s ease-in-out'
}

const activeStyle = {
  borderColor: '#019EEC'
}

const acceptStyle = {
  borderColor: '#00e676'
}

const rejectStyle = {
  borderColor: '#ff1744'
}
