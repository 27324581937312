import { Box, Grid, List, Typography } from '@material-ui/core'
import { inject, observer } from 'mobx-preact'
import { Fragment } from 'preact'
import { useState } from 'preact/hooks'
import { RaisedButton } from '../../common/button'
import { ListCheckItem } from '../../common/list_check'
import { AddFolderDialog } from '../../folder/common/add_dialog'

const ProductMenuFoldersComponent = props => {
  const [add, setAdd] = useState(false)

  const { folder, product, refresh } = props
  const foldersForProducts = folder.foldersForProduct(product._key)

  const handleFolderClick = f => {
    const inFolder = foldersForProducts && foldersForProducts.includes(f._id)
    if (inFolder) {
      folder.remove(f._id, product._key)
    } else {
      folder.insert(f._id, product._key)
    }
    if (refresh) {
      refresh()
    }
  }

  return (
    <Fragment>
      <AddFolderDialog open={add} onClose={() => setAdd(false)} />
      {folder.productFolders.length === 0 ? (
        <Box padding={2}>
          <Grid container justify='center'>
            <Typography variant='body1'>
              Você não possui nenhuma pasta
            </Typography>
          </Grid>
          <Box padding={1} />
          <Grid container justify='center'>
            <RaisedButton
              onClick={() => setAdd(true)}
              fullWidth
              primary
              label='Adicionar Pasta'
            />
          </Grid>
        </Box>
      ) : (
        <Fragment>
          <List style={{ maxHeight: 300, overflowY: 'auto' }}>
            {folder.productFolders.map(f => {
              const inFolder =
                (foldersForProducts && foldersForProducts.includes(f._id)) ||
                false
              return (
                <ListCheckItem
                  key={f._id}
                  checked={inFolder}
                  onClick={() => handleFolderClick(f)}
                  label={f.name}
                />
              )
            })}
          </List>
          <Box padding={1}>
            <Box paddingLeft={1} paddingRight={1}>
              <Grid container justify='center'>
                <RaisedButton
                  onClick={() => setAdd(true)}
                  fullWidth
                  primary
                  label='Adicionar Pasta'
                />
              </Grid>
            </Box>
          </Box>
        </Fragment>
      )}
    </Fragment>
  )
}

export const ProductMenuFolders = inject('folder')(
  observer(ProductMenuFoldersComponent)
)
