import graphql from '../../http/graphql'
import {
  duplicateOfferMutation,
  removeOfferMutation,
  updateOfferMutation
} from '../graphql/mutations'
import {
  fetchOfferQuery,
  fetchOffersByFilterQuery,
  fetchOffersQuery,
  textHistoryQuery
} from '../graphql/queries'

class OfferService {
  async update(data) {
    return graphql.query(updateOfferMutation, { data }).then(d => d.updateOffer)
  }

  offersByFilter = async filter => {
    return graphql
      .query(fetchOffersByFilterQuery, { filter })
      .then(r => r.offersByFilter)
  }

  async offers(term) {
    return graphql.query(fetchOffersQuery, { term }).then(d => d.offers)
  }

  async texts() {
    return graphql.query(textHistoryQuery).then(d => d.textHistory)
  }

  async offer(key) {
    return graphql
      .query(fetchOfferQuery, { id: `offer/${key}` })
      .then(d => d.offer)
  }

  async remove(key) {
    return graphql
      .query(removeOfferMutation, { id: `offer/${key}` })
      .then(d => d.removeOffer)
  }

  async duplicate(key) {
    return graphql
      .query(duplicateOfferMutation, { id: `offer/${key}` })
      .then(d => d.duplicateOffer)
  }
}

const offerService = new OfferService()
export default offerService
