import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Toolbar,
  Typography
} from '@material-ui/core'
import { ArrowBackOutlined, CheckOutlined } from '@material-ui/icons'
import { Fragment } from 'preact'
import AppRoutes from '../../navigation/routes'
import Colors from '../../config/colors'
import store from '../../stores/root'
import { isTrialExpired } from '../common/expired'
import { useHistory } from 'react-router'

const useStyles = makeStyles({
  inverse: {
    color: Colors.white
  },
  light: {
    color: '#fff9'
  },
  root: {
    height: '100vh'
  },
  info: {
    backgroundColor: '#2196F3',
    padding: 32
  },
  input: {
    marginBottom: 8
  },
  card: {
    marginTop: '2%',
    padding: 42,
    maxWidth: 550
  },
  price: {
    color: Colors.white,
    fontSize: 48
  },
  backButton: {
    color: Colors.white,
    marginLeft: 24
  }
})

export const SubscriptionInfo = () => {
  const history = useHistory()
  const classes = useStyles()
  const canGoBack = !isTrialExpired(store.auth.user.company.trialEnd)

  return (
    <Fragment>
      <Grid item>
        <Toolbar disableGutters>
          {canGoBack && (
            <Fragment>
              <IconButton
                onClick={() => history.push(AppRoutes.Offers)}
                className={classes.inverse}
                color='inherit'
              >
                <ArrowBackOutlined />
              </IconButton>
              <Typography variant='h6' className={classes.backButton}>
                Voltar
              </Typography>
            </Fragment>
          )}
        </Toolbar>
        <Box padding={4} />
        <Typography className={classes.inverse}>
          Infelizmente o seu período de testes acabou, mas você continuar a
          criar novas artes contratando o plano mensal
        </Typography>
        <Box padding={4} />
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.light}>
              Assinar OfertaFácil
            </Typography>
          </Grid>
          <Grid item xs={11} md={10} lg={8}>
            <Typography className={classes.price} variant='h6'>
              R$ 129,90
            </Typography>
          </Grid>
          <Grid
            container
            alignItems='center'
            justify='flex-start'
            item
            xs={1}
            md={2}
            lg={4}
          >
            <Grid className={classes.inverse}>
              por <br />
              mês
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <List>
        <ListItem>
          <CheckOutlined style={{ color: 'white', marginRight: 16 }} />
          <Typography variant='body2' style={{ color: 'white' }}>
            Acesso imediato
          </Typography>
        </ListItem>
        <ListItem>
          <CheckOutlined style={{ color: 'white', marginRight: 16 }} />
          <Typography variant='body2' style={{ color: 'white' }}>
            Escolha estilos diferentes de etiquetas e imagens temáticas e
            promocionais
          </Typography>
        </ListItem>
        <ListItem>
          <CheckOutlined style={{ color: 'white', marginRight: 16 }} />
          <Typography variant='body2' style={{ color: 'white' }}>
            Mais de 500 artes novas todo mês
          </Typography>
        </ListItem>
        <ListItem>
          <CheckOutlined style={{ color: 'white', marginRight: 16 }} />
          <Typography variant='body2' style={{ color: 'white' }}>
            Cadastre seus próprios produtos
          </Typography>
        </ListItem>
        <ListItem>
          <CheckOutlined style={{ color: 'white', marginRight: 16 }} />
          <Typography variant='body2' style={{ color: 'white' }}>
            Nossa inteligência artificial remove o fundo dos produtos para você
          </Typography>
        </ListItem>
        <ListItem>
          <CheckOutlined style={{ color: 'white', marginRight: 16 }} />
          <Typography variant='body2' style={{ color: 'white' }}>
            Organize seus produtos em pastas e favoritos
          </Typography>
        </ListItem>
      </List>
      <Box padding={3} />
      <Grid container justify='center' spacing={3} item>
        <Grid item xs={6} alignSelf='center'>
          <Typography className={classes.inverse} variant='caption'>
            Compra assegurada por
          </Typography>
          <br />
          <a href='https://stripe.com' rel='noreferrer' target='_blank'>
            <img src='/assets/images/stripe.svg' width='130' />
          </a>
        </Grid>
        <Grid item xs={6} alignSelf='flex-end'>
          <a href='https://stripe.com' rel='noreferrer' target='_blank'>
            <img src='/assets/images/stripe_logo_white.svg' height='50' />
          </a>
        </Grid>
      </Grid>
    </Fragment>
  )
}
