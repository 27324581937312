import { Route, Switch, useRouteMatch } from 'react-router'
import { BackgroundContent } from '../../background/screen'
import { LogoScreen } from '../../logo/screen'
import { FavoriteProductsScreen } from '../../product/favorites/screen'
import { ProductsInFolderScreen } from '../../product/folder/products'
import { ManageCustomProducts } from '../../product/manage/products'
import { RecentProductsScreen } from '../../product/recent'
import { ProductContent } from '../../product/screen'
import { TagScreen } from '../../tag/screen'
import { TemplateListContainer } from '../../template/list/container'
import { UserTemplates } from '../../template/user'
import { TextScreen } from '../../text/screen'

export const OfferContent = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/modelos`}>
        <TemplateListContainer />
      </Route>
      <Route path={`${path}/arte`}>
        <BackgroundContent />
      </Route>
      <Route path={`${path}/textos`}>
        <TextScreen />
      </Route>
      <Route path={`${path}/etiquetas`}>
        <TagScreen />
      </Route>
      <Route path={`${path}/elementos`}>
        <LogoScreen />
      </Route>
      <Route path={`${path}/salvos`}>
        <UserTemplates />
      </Route>
      <Route exact path={`${path}/produtos`}>
        <ProductContent />
      </Route>
      <Route path={`${path}/produtos/meus`}>
        <ManageCustomProducts />
      </Route>
      <Route path={`${path}/produtos/favoritos`}>
        <FavoriteProductsScreen />
      </Route>
      <Route path={`${path}/produtos/recentes`}>
        <RecentProductsScreen />
      </Route>
      <Route path={`${path}/produtos/pastas/:key/:name`}>
        <ProductsInFolderScreen />
      </Route>
    </Switch>
  )
}
