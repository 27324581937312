import { Box, Divider, Menu } from '@material-ui/core'
import {
  CancelOutlined,
  DeleteOutlineOutlined,
  FavoriteBorderOutlined
} from '@material-ui/icons'
import { inject, observer } from 'mobx-preact'
import { Fragment } from 'preact'
import { useState } from 'preact/hooks'
import { MenuButton } from '../common/menu_button'
import { Thumbnail } from '../common/thumbnail'
import { Portal } from '../utils/portal'
import { ProductMenuFolders } from './folder/menu'
import productService from './services'

export const ProductMenuComponent = props => {
  const { product, favorite, refresh } = props
  const [anchorEl, setAnchorEl] = useState(null)

  const isFavorite = favorite.favorites.includes(product._id)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleRemoveProduct = async () => {
    setAnchorEl(null)
    await productService.remove(product._id)
    refresh()
  }

  return (
    <Thumbnail image={product} onMenuClick={target => setAnchorEl(target)}>
      <Portal open={Boolean(anchorEl)}>
        <Menu
          onClose={handleClose}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
        >
          {isFavorite ? (
            <MenuButton
              icon={<CancelOutlined />}
              onClick={async () => {
                await favorite.removeProduct(product._id)
                if (refresh) {
                  refresh()
                }
              }}
              label='Remover de Favoritos'
            />
          ) : (
            <MenuButton
              icon={<FavoriteBorderOutlined />}
              onClick={async () => {
                await favorite.addProduct(product._id)
                if (refresh) {
                  refresh()
                }
              }}
              label='Adicionar a Favoritos'
            />
          )}
          <Box padding={1} />
          <Divider />
          <ProductMenuFolders refresh={refresh} product={product} />
          {product.custom === true && (
            <Fragment>
              <Divider />
              <Box padding={1} />
              <MenuButton
                icon={<DeleteOutlineOutlined />}
                onClick={handleRemoveProduct}
                label='Remover Produto'
              />
            </Fragment>
          )}
        </Menu>
      </Portal>
    </Thumbnail>
  )
}

export const ProductMenuThumb = inject('favorite')(
  observer(ProductMenuComponent)
)
