export const fetchBackgroundsQuery = `
  query Backgrounds($format: String, $term: String) {
     backgrounds(format: $format, term: $term) {
          _id
          _key
          _rev
          name
          imageURL
          format
          createdAt
          updatedAt
      }
  }
`

export const fetchBackgroundsByFilterQuery = `
  query BackgroundsByFilter($filter: backgroundsFilterInput!) {
    backgroundsByFilter(filter: $filter) {
          _id
          _key
          _rev
          name
          imageURL
          format
          createdAt
          updatedAt
      }
  }
`

export const fetchBackgroundQuery = `
  query Background($id: String!) {
      background(id: $id){
          _id
          _key
          _rev
          createdAt
          updatedAt
          name
          imageURL
          format
      }
  }
`

export const customBackgroundsQuery = `
  query CustomBackgrounds {
    customBackgrounds {
      _id
      _key
      _rev
      createdAt
      updatedAt
      name
      imageURL
      format
    }
  }
`

export const recentBackgroundsQuery = `
  query RecentBackgrounds($format: String) {
    recentBackgrounds(format: $format) {
      _id
      _key
      _rev
      name
      imageURL
      format
      createdAt
      updatedAt
    }
  }
`
