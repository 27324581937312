import { IconButton } from '@material-ui/core'
import Popover from '@material-ui/core/Popover'
import { PaletteOutlined } from '@material-ui/icons'
import { inject, observer } from 'mobx-preact'
import { useState } from 'preact/hooks'
import { CompactPicker } from 'react-color'

export const ColorPopover = ({ color, onUpdate }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <div id='color-picker'>
      <IconButton title='Cor do nome dos produtos' onClick={handleClick}>
        <PaletteOutlined style={{ color }} />
      </IconButton>
      <Popover
        id={open ? 'color-picker' : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <CompactPicker
          circleSize={20}
          onChangeComplete={color => {
            onUpdate(color.hex)
            setAnchorEl(null)
          }}
        />
      </Popover>
    </div>
  )
}

export default inject('art')(observer(ColorPopover))
