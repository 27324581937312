import { Grid } from '@material-ui/core'
import { useState } from 'preact/hooks'
import store from '../../stores/root'
import { AddProductForm } from './form'
import { AddProductHelp } from './help'

export const AddProductContainer = () => {
  const [product, setProduct] = useState(null)
  return (
    <Grid container>
      {!product ? (
        <AddProductHelp onProductSelected={setProduct} />
      ) : (
        <AddProductForm
          product={product}
          art={store.art}
          onProductAdded={() => setProduct(null)}
        />
      )}
    </Grid>
  )
}
