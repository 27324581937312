import {
  FilledInput,
  IconButton,
  InputAdornment,
  makeStyles
} from '@material-ui/core'
import { ArrowBackOutlined, SearchOutlined } from '@material-ui/icons'
import { useEffect, useState } from 'preact/hooks'
import Colors from '../config/colors'
import useDebounce from '../utils/debounce'

const useStyles = makeStyles({
  root: {
    borderRadius: '8px !important',
  },
  input: {
    padding: 16,
    fontFamily: 'GoogleSans'
  }
})

export default function TextSearch ({ onSearch, onClear }) {
  const [term, setTerm] = useState('')
  const debouncedTerm = useDebounce(term, 100)

  const classes = useStyles()

  useEffect(() => {
    if (term.length === 0) {
      onClear()
    }

    if (!term || term.length < 3) {
      return
    }

    const search = async () => {
      if (debouncedTerm) {
        onSearch(term)
      }
    }
    search()
  }, [debouncedTerm])

  return (
    <FilledInput
      classes={{
        root: classes.root,
        input: classes.input
      }}
      style={{
        borderRadius: '8px !important',
        backgroundColor: Colors.textInputColor
      }}
      value={term}
      disableUnderline
      placeholder='Busque textos de outros encartes'
      onChange={e => setTerm(e.target.value)}
      fullWidth
      startAdornment={
        <InputAdornment position='start'>
          {term ? (
            <IconButton
              onClick={() => {
                setTerm('')
                onClear(null)
              }}
            >
              <ArrowBackOutlined />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                setTerm('')
                onClear(null)
              }}
            >
              <SearchOutlined />
            </IconButton>
          )}
        </InputAdornment>
      }
    />
  )
}
