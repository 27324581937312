import graphql from '../../http/graphql'
import { stripeSession } from '../graphql/queries'

class AuthService {
  authenticate = credentials => {
    return graphql.authenticate(credentials)
  }

  recoverPassword = email => {
    return graphql.post('/password/recover', { email }).then(d => d.data)
  }

  verify = hash => {
    return graphql.get(`/password/verify/${hash}`).then(d => d.password)
  }

  stripeSession = () => {
    return graphql.query(stripeSession).then(r => r.session)
  }
}

const authService = new AuthService()
export default authService
