import { Box, Grid, Typography } from '@material-ui/core'
import { inject, observer } from 'mobx-preact'
import { Fragment } from 'preact'
import { useMobileDetect } from '../utils/mobile'

const TrialMessageComponent = ({ auth }) => {
  const detectMobile = useMobileDetect()

  return (
    auth.isTrial && (
      <Box padding={2}>
        <Grid container justify='center'>
          {!detectMobile.isMobile && (
            <Fragment>
              <Grid item>
                <Typography align='center' variant='caption'>
                  Seu teste acaba em
                </Typography>
                <Typography
                  color={auth.expiresInDays <= 2 ? 'secondary' : 'textPrimary'}
                  variant='h6'
                >
                  {auth.expires}
                </Typography>
              </Grid>
              <Box padding={3} />
            </Fragment>
          )}
        </Grid>
      </Box>
    )
  )
}

export const TrialMessage = inject('auth')(observer(TrialMessageComponent))
