import {
  FilledInput,
  IconButton,
  InputAdornment,
  makeStyles
} from '@material-ui/core'
import { ArrowBackOutlined } from '@material-ui/icons'
import SearchOutlined from '@material-ui/icons/SearchOutlined'
import { useEffect, useState } from 'preact/hooks'
import Colors from '../../config/colors'
import useDebounce from '../../utils/debounce'

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '8px !important'
  },
  input: {
    padding: 16,
    paddingLeft: 0,
    fontFamily: 'GoogleSans'
  }
}))

export function ProductSearchField ({ onClear, onSearch }) {
  const classes = useStyles()

  const [term, setTerm] = useState('')
  const debouncedTerm = useDebounce(term, 500)

  useEffect(() => {
    if (term.length === 0) {
      onClear('')
    }

    if (!term || term.length < 3) {
      return
    }

    const search = async () => {
      if (debouncedTerm) {
        onSearch(term)
      }
    }
    search()
  }, [debouncedTerm])

  return (
    <FilledInput
      classes={{
        root: classes.root,
        input: classes.input,
        hover: classes.hover
      }}
      style={{
        borderRadius: '8px !important',
        backgroundColor: Colors.textInputColor
      }}
      value={term}
      disableUnderline
      placeholder='Pesquise mais de 1000 produtos'
      onChange={e => setTerm(e.target.value)}
      fullWidth
      startAdornment={
        <InputAdornment position='start'>
          {term != '' ? (
            <IconButton
              onClick={() => {
                setTerm('')
                onClear(null)
              }}
            >
              <ArrowBackOutlined />
            </IconButton>
          ) : (
            <IconButton
              style={{ marginRight: 16 }}
              onClick={() => {
                setTerm('')
                onClear(null)
              }}
            >
              <SearchOutlined />
            </IconButton>
          )}
        </InputAdornment>
      }
    />
  )
}
