import { Route, Switch } from 'react-router-dom'
import { AccountSecurityScreen } from './account/security.js'
import AppRoutes from './navigation/routes'
import { OfferEditScreen } from './offer/edit/screen'
import { OfferListScreen } from './offer/list/screen'
import { MobileOfferEditScreen } from './offer/mobile/screen.js'
import { ProfileScreen } from './profile/screen'
import { PaymentScreen } from './subscription/pay/screen'
import { SubscriptionUnpaid } from './subscription/unpaid.js'

export const Home = () => {
  return (
    <Switch>
      <Route exact path={'/'}>
        <OfferListScreen />
      </Route>
      <Route exact path={AppRoutes.Offers}>
        <OfferListScreen />
      </Route>
      <Route path={AppRoutes.Subscribe}>
        <PaymentScreen />
      </Route>
      <Route path={AppRoutes.SubscriptionUnpaid}>
        <SubscriptionUnpaid />
      </Route>
      <Route exact path={AppRoutes.Account}>
        <ProfileScreen />
      </Route>
      <Route exact path={AppRoutes.AccountSecurity}>
        <AccountSecurityScreen />
      </Route>
      <Route path={AppRoutes.EditOfferMobile}>
        <MobileOfferEditScreen />
      </Route>
      <Route path={AppRoutes.EditOffer}>
        <OfferEditScreen />
      </Route>
    </Switch>
  )
}
