import { action, computed, observable } from 'mobx'
import { folderProvider } from '../folder/providers/folder'

export class FolderStore {
  constructor (root) {
    this.root = root
  }

  @observable folders = {}

  @action
  setFolders (folders) {
    for (const folder of folders) {
      this.folders[folder._id] = folder
    }
  }

  @computed
  get productFolders () {
    const folders = []
    for (const key of Object.keys(this.folders)) {
      const folder = this.folders[key]
      if (folder.type === 'product') {
        folders.push(folder)
      }
    }
    return folders
  }

  foldersForProduct (key) {
    const folders = []
    for (const k of Object.keys(this.folders)) {
      const folder = this.folders[k]
      if (folder.items.indexOf(key) > -1) {
        folders.push(k)
      }
    }
    return folders
  }

  @action
  async addProductFolder (name) {
    const folder = await folderProvider.update({
      name,
      type: 'product',
      items: []
    })
    this.folders[folder._id] = folder
    this.folders = { ...this.folders }
    this.loadFolders()
  }

  loadFolders = async () => {
    try {
      const folders = await folderProvider.folders()
      this.setFolders(folders)
    } catch (e) {
      console.log(e.message)
    }
  }

  @action
  insert = (folder, key) => {
    folderProvider.insert(folder, key)
    const f = this.folders[folder]
    f.items.push(key)
    this.folders = { ...this.folders }
  }

  @action
  remove = (folder, key) => {
    folderProvider.remove(folder, key)
    const f = this.folders[folder]
    f.items.splice(f.items.indexOf(key), 1)
    this.folders = { ...this.folders }
  }
}
