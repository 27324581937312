export const updateLogoMutation = `
   mutation UpgradeLogo($data: LogoInput!) {
      updateLogo(data: $data) {
         _id
         _key
         _rev
      }
   }
`
export const removeLogoMutation = `
   mutation RemoveLogo($id: String!) {
      removeLogo(id: $id)
   }
`
