import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  makeStyles,
  Toolbar,
  Typography
} from '@material-ui/core'
import { DeleteOutline } from '@material-ui/icons'
import { Fragment } from 'preact'
import { useState } from 'preact/hooks'
import useSWR from 'swr'
import { FlatButton } from '../common/button'
import Colors from '../config/colors'
import { captureWithScope } from '../sentry'
import store from '../stores/root'
import { applyTemplateToOffer } from './apply'
import { templateProvider } from './providers/template'

const useStyles = makeStyles({
  image: {
    cursor: 'pointer',
    objectFit: 'scale-down',
    borderRadius: 8
  },
  deleteIcon: {
    borderRadius: 12,
    padding: 2,
    color: Colors.white,
    backgroundColor: '#00000032'
  }
})

export const UserTemplates = () => {
  const classes = useStyles()
  const { offer } = store.art
  const [confirm, setConfirm] = useState('')

  const { data: templates, revalidate } = useSWR(
    [offer.format.code, 'saves'],
    templateProvider.userTemplates
  )

  const handleRemove = async () => {
    try {
      await templateProvider.userRemove(confirm)
      setConfirm('')
      revalidate()
      store.layout.showSnackbar('Modelo removido')
    } catch (e) {
      captureWithScope(e, { templateId: confirm })
      store.layout.showSnackbar('Erro ao remover o modelo')
    }
  }

  const handleSelect = template => {
    applyTemplateToOffer({ ...template }, true)
  }

  const height = (375 / 3) * offer.format.ratio

  return (
    <Fragment>
      <Dialog
        open={Boolean(confirm)}
        onClose={() => setConfirm(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle>Confirme</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja remover este modelo?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FlatButton onClick={() => setConfirm(false)} label='Cancelar' />
          <FlatButton onClick={handleRemove} primary label='Remover' />
        </DialogActions>
      </Dialog>
      <Box padding={2}>
        <Toolbar disableGutters>
          <Grid container justify='space-between'>
            <Typography variant='h6'>Modelos Salvos</Typography>
          </Grid>
        </Toolbar>
        <Grid contaier>
          <Grid item>
            {!templates ||
              (templates.length === 0 && (
                <Box padding={4}>
                  <Typography variant='body1' align='center'>
                    Salve o encarte atual como modelo para poder reutilizar o
                    design nos próximos encartes
                  </Typography>
                </Box>
              ))}
            {templates && (
              <GridList cellHeight={height} cols={3}>
                {templates &&
                  templates.map(template => (
                    <GridListTile key={template._id}>
                      <img
                        onClick={() => handleSelect(template)}
                        className={classes.image}
                        title={template.name}
                        src={template.preview}
                      />
                      <GridListTileBar
                        style={{ backgroundColor: 'transparent' }}
                        actionIcon={
                          <IconButton onClick={() => setConfirm(template._id)}>
                            <DeleteOutline className={classes.deleteIcon} />
                          </IconButton>
                        }
                      />
                    </GridListTile>
                  ))}
              </GridList>
            )}
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  )
}
