import { render } from 'preact'
import { App } from './app'

let app
if (typeof document === 'undefined') {
  app = App
} else {
  render(<App />, document.getElementById('root'))
}

export default app
