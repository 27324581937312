import { Grid } from '@material-ui/core'
import { useHistory, useRouteMatch } from 'react-router'
import { FavoritesIcon, RecentFolderIcon, UploadsIcon } from '../common/icons'
import { Folder } from '../folder/common/folder'

export const CommonFolders = () => {
  const history = useHistory()
  const { url } = useRouteMatch()

  return (
    <Grid container justify='space-between'>
      <Folder
        onClick={() => history.push(`${url}/meus`)}
        color='#ffa726'
        icon={<UploadsIcon />}
        label='Meus Produtos'
      />
      <Folder
        onClick={() => history.push(`${url}/favoritos`)}
        icon={<FavoritesIcon />}
        label='Favoritos'
      />
      <Folder
        onClick={() => history.push(`${url}/recentes`)}
        icon={<RecentFolderIcon />}
        label='Recentes'
      />
    </Grid>
  )
}
