import { Box, GridList, GridListTile, Toolbar } from '@material-ui/core'
import { Fragment } from 'preact'
import useSWR, { mutate } from 'swr'
import { BackButton } from '../../common/back'
import { LinearLoadingIndicator } from '../../common/loading'
import store from '../../stores/root'
import { ProductMenuThumb } from '../menu'
import productService from '../services'

export const ManageCustomProducts = () => {
  const { data: products } = useSWR(
    '/user-products',
    productService.userProducts
  )

  if (!products) {
    return (
      <Box padding={3}>
        <LinearLoadingIndicator />
      </Box>
    )
  }

  return (
    <Fragment>
      <Toolbar>
        <BackButton label='Meus Produtos' />
      </Toolbar>
      <Box padding={2}>
        <Box padding={1} />
        <GridList cellHeight={375 / 3} cols={3}>
          {products.map(p => (
            <GridListTile button onClick={() => store.art.addProduct(p)}>
              <ProductMenuThumb
                refresh={() => mutate('/user-products')}
                product={p}
              />
            </GridListTile>
          ))}
        </GridList>
      </Box>
    </Fragment>
  )
}
