export const businesses = [
  {
    name: 'supermercado',
    label: 'Supermercado'
  },
  {
    name: 'mercearia',
    label: 'Mercearia'
  },
  {
    name: 'panificadora',
    label: 'Panificadora'
  },
  {
    name: 'acougue',
    label: 'Açougue'
  },
  {
    name: 'hortifruti',
    label: 'Hortifruti'
  },
  {
    name: 'petshop',
    label: 'Pet Shop'
  },
  {
    name: 'farmacia',
    label: 'Farmácia'
  },
  {
    name: 'materialconstrucao',
    label: 'Material de Construção'
  },

  {
    name: 'academia',
    label: 'Academia'
  },
  {
    name: 'restaurante',
    label: 'Restaurante'
  },
  {
    name: 'lanchonete',
    label: 'Lanchonete'
  }
]
