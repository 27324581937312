import { Grid, makeStyles } from '@material-ui/core'
import { Route, Switch, useRouteMatch } from 'react-router'
import Colors from '../../config/colors'
import { LocalStorageTokens } from '../../config/constants'
import { useSubscribed } from '../../navigation/subscribed'
import { useMobileDetect } from '../../utils/mobile'
import { SubscriptionUnpaid } from '../unpaid'
import { OrderPaymentScreen } from './card'
import { SubscriptionInfo } from './info'

const useStyles = makeStyles({
  inverse: {
    color: Colors.white
  },
  light: {
    color: '#fff9'
  },
  root: {
    height: '100vh'
  },
  info: {
    backgroundColor: '#2196F3',
    padding: 32
  },
  input: {
    marginBottom: 8
  },
  card: {
    marginTop: '2%',
    maxWidth: 700
  },
  price: {
    color: Colors.white,
    fontSize: 48
  },
  backButton: {
    color: Colors.white,
    marginLeft: 24
  }
})

export const PaymentScreen = () => {
  const detectMobile = useMobileDetect()

  useSubscribed()

  const classes = useStyles()
  const { path } = useRouteMatch()

  if (window.$crisp) {
    window.$crisp.push(['do', 'chat:show'])

    const sent = localStorage.getItem(
      LocalStorageTokens.chatCheckoutWelcomeMessage,
      true
    )

    if (!sent) {
      localStorage.setItem(LocalStorageTokens.chatCheckoutWelcomeMessage, true)
      window.$crisp.push([
        'do',
        'message:show',
        ['text', 'Qualquer dúvida, chame nossa equipe!']
      ])
    }
  }

  return (
    <Grid className={classes.root} container>
      {!detectMobile.isMobile() && (
        <Grid
          className={classes.info}
          container
          direction='column'
          justify='space-between'
          item
          xs={12}
          md={4}
        >
          {!['aguardando', 'unpaid', 'cancelado', 'sucesso'].includes(
            window.location.href.split('/').pop()
          ) && <SubscriptionInfo />}
        </Grid>
      )}
      <Grid
        className={classes.card}
        alignContent='flex-start'
        container
        item
        xs={12}
        md={6}
      >
        <Switch>
          <Route exact path={path}>
            <OrderPaymentScreen />
          </Route>
          <Route exact path={`${path}/unpaid`}>
            <SubscriptionUnpaid />
          </Route>
        </Switch>
      </Grid>
    </Grid>
  )
}
