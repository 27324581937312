import { Box, Grid, LinearProgress, Typography } from '@material-ui/core'
import { ErrorOutline } from '@material-ui/icons'
import { Fragment } from 'preact'
import { useEffect, useRef, useState } from 'preact/hooks'
import { FlatButton, RaisedButton } from '../../common/button'
import { CustomTextField } from '../../common/input'
import Colors from '../../config/colors'
import { API_ROOT_URL } from '../../http/hostname'
import productService from '../services'

export const AddProductForm = ({ product, art, onProductAdded }) => {
  const input = useRef(null)

  const [name, setName] = useState('')
  const [originalImage, setOriginalImage] = useState(null)
  const [imageError, setImageError] = useState('')
  const [removeError, setRemoveError] = useState('')
  const [removing, setRemoving] = useState(false)
  const [removed, setRemoved] = useState(false)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    setOriginalImage(product)
  }, [])

  const removeBG = async () => {
    if (!originalImage) return

    setRemoving(true)
    try {
      const image = await fetch(originalImage).then(r => r.blob())

      const formData = new FormData()
      formData.append('file', image)

      const response = await fetch(`${API_ROOT_URL}/removebg`, {
        headers: {
          Authorization: localStorage.getItem('token')
        },
        method: 'POST',
        body: formData
      }).then(r => r.text())

      setOriginalImage(`data:image/png;base64,${response}`)
      setRemoved(true)
    } catch (error) {
      if (error.message === 'limit_on_removal_achieved') {
        return setRemoveError('Limite de remoções mensais atingida!')
      }
      alert('Erro ao remover fundo! Entre em contato com o nosso suporte')
      console.error(error)
    } finally {
      setRemoving(false)
    }
  }

  const handleSaveProduct = async () => {
    try {
      if (!name || name.replace(/ /g, '').length < 3 || !originalImage) {
        return alert('Digite um nome para o produto')
      }

      setSaving(true)
      const image = await fetch(originalImage).then(r => r.blob())

      const formData = new FormData()
      formData.append('photo', image)

      const imageURL = await fetch(`${API_ROOT_URL}/product/upload`, {
        headers: {
          Authorization: localStorage.getItem('token')
        },
        method: 'POST',
        body: formData
      })
        .then(r => r.json())
        .then(r => r.data)

      const product = await productService.update({
        name: name.trim(),
        imageURL
      })

      if (product) {
        art.addProduct(product)
      }

      onProductAdded()
    } catch (e) {
      setSaving(false)
      setImageError('Não foi possível salvar o produto. Tente novamente')
    }
  }

  if (saving) {
    return (
      <Grid container justify='center'>
        <Grid item>
          <Box padding={4}>
            <LinearProgress />
            <Typography align='center' variant='caption'>
              Salvando {name}...
            </Typography>
          </Box>
        </Grid>
      </Grid>
    )
  }

  return (
    <Fragment>
      {imageError && (
        <Grid container justify='center'>
          <Box padding={4} />
          <Grid container justify='center' item xs={12}>
            <ErrorOutline color='error' />
          </Grid>
          <Box padding={1} />
          <Grid container justify='center' item xs={12}>
            <Typography align='center' variant='body2'>
              Formato de imagem não suportado, utilize outra imagem.
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid
        style={{ margin: 2, marginTop: 16 }}
        container
        direction='column'
        spacing={3}
        alignItems='center'
      >
        <Grid
          style={{
            backgroundColor: Colors.textInputColor,
            borderRadius: 8
          }}
          container
          justify='center'
          item
        >
          <Grid
            style={{ width: 196, height: 196 }}
            container
            justify='center'
            alignItems='center'
            item
            xs={12}
          >
            {!removing && (
              <img
                src={originalImage}
                width='196'
                style={{
                  maxHeight: 196,
                  objectFit: 'contain'
                }}
              />
            )}
            {removing && (
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Grid item>
                  <Box padding={4}>
                    <LinearProgress />
                    <Typography align='center' variant='caption'>
                      Removendo o fundo...
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid>
            <Box padding={2} />
            {!removeError && !removed && (
              <FlatButton
                primary
                onClick={removeBG}
                label='Remover Fundo da Imagem'
              />
            )}
            {removeError && (
              <Typography align='center' color='error' variant='caption'>
                {removeError}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid style={{ padding: 0 }} container item direction='column'>
          <Box padding={1} />
          <CustomTextField
            inputRef={input}
            autoFocus
            onKeyPress={e => {
              if (e.keyCode === 13) {
                handleSaveProduct()
              }
            }}
            error={name == ''}
            helperText={'Define o nome do produto'}
            onChange={e => setName(e.target.value)}
            value={name}
            placeholder='Nome do produto...'
          />
          <Box padding={1} />
          <RaisedButton
            fullWidth
            onClick={handleSaveProduct}
            primary
            label='Salvar Produto'
          />
          <Box padding={1} />
          <FlatButton
            fullWidth
            onClick={onProductAdded}
            primary
            label='Cancelar'
          />
        </Grid>
      </Grid>
    </Fragment>
  )
}
