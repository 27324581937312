import graphql from '../../http/graphql'
import {
  fetchTagsQuery,
  fetchTagQuery,
  fetchTagsByFilterQuery
} from '../graphql/queries'
import { updateTagMutation } from '../graphql/mutations'

class TagService {
  async tags() {
    return graphql.query(fetchTagsQuery).then(t => t.tags)
  }

  async tagsByFilter(filter) {
    return graphql
      .query(fetchTagsByFilterQuery, { filter })
      .then(t => t.tagsByFilter)
  }

  async tag(id) {
    return graphql.query(fetchTagQuery, { id }).then(t => t.tag)
  }

  update = data => {
    return graphql.query(updateTagMutation, { data })
  }
}

const tagService = new TagService()
export default tagService
