import graphql from '../../http/graphql'
import { updateDownloadMutation } from '../graphql/mutations'

class DownloadProvider {
  async update (data) {
    return graphql
      .query(updateDownloadMutation, { data })
      .then(b => b.updateDownload)
  }
}

export const downloadProvider = new DownloadProvider()
