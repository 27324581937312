import { Box, Container, Grid, Toolbar, Typography } from '@material-ui/core'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { DateTime } from 'luxon'
import { Fragment } from 'preact'
import { useState } from 'preact/hooks'
import { BackButton } from '../../common/back'
import { RaisedButton } from '../../common/button'
import { LinearLoadingIndicator } from '../../common/loading'
import { API_ROOT_URL } from '../../http/hostname'
import { captureWithScope } from '../../sentry'
import store from '../../stores/root'
import { useMobileDetect } from '../../utils/mobile'
import { getDisplayErrorMessage } from '../common/errors'
import { subscriptionProvider } from '../providers/subscription'
import './styles.css'

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"GoogleSans", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '18px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
}

export const SubscribeByCardForm = ({ onPaymentComplete }) => {
  const detectMobile = useMobileDetect()
  const user = store.auth.user
  const company = store.auth.user.company

  const [name, setName] = useState(null)
  const [coupon, setCoupon] = useState('')
  const [error, setError] = useState(null)
  const [disabled, setDisabled] = useState(true)
  const [busy, setBusy] = useState(false)

  const stripe = useStripe()
  const elements = useElements()

  const todayDay = DateTime.local().day

  const retrial = async () => {
    await fetch(`${API_ROOT_URL}/retrial?email=${user.email}`)
    window.location.reload(true)
  }

  const handleSubmit = async event => {
    if (!name || name.length < 7) {
      return setError('Informe o nome conforme o cartão')
    }

    event.preventDefault()
    setBusy(true)

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement('card'),
      billing_details: {
        name,
        email: store.auth.user.email
      }
    })

    if (payload.error) {
      captureWithScope(new Error(payload.error.message), {
        error: payload.error
      })
      setError(payload.error.message)
      setBusy(false)
    } else {
      const paymentMethodId = payload.paymentMethod.id

      try {
        const response = await subscriptionProvider.subscribe({
          method: 'card',
          token: paymentMethodId,
          coupon,
          customer: {
            name
          }
        })

        const subscription = JSON.parse(response.subscription)

        if (subscription && subscription.status === 'active') {
          onPaymentComplete(coupon)
        }

        const paymentIntent = subscription.latest_invoice.payment_intent

        if (
          paymentIntent.status === 'requires_action' ||
          paymentIntent.status === 'requires_payment_method'
        ) {
          return stripe
            .confirmCardPayment(paymentIntent.client_secret, {
              payment_method: paymentMethodId
            })
            .then(result => {
              if (result.error) {
                setBusy(false)
                captureWithScope(new Error(result.error.message), {
                  error: result.error
                })
                return setError(result.error.message)
              }
              if (result.paymentIntent.status === 'succeeded') {
                onPaymentComplete(coupon)
              }
            })
            .catch(error => {
              setBusy(false)
              captureWithScope(new Error(error), { error })
              setError(error)
            })
        }
        return onPaymentComplete(coupon)
      } catch (e) {
        setBusy(false)
        captureWithScope(e)
        setError(getDisplayErrorMessage(e.message))
      }
    }
  }

  const handleChange = event => {
    setDisabled(event.empty)
    setError(event.error ? event.error.message : '')
  }

  return (
    <Container>
      {detectMobile.isMobile() && (
        <Fragment>
          <Toolbar>
            <BackButton label='Voltar' />
          </Toolbar>
          <Box padding={2} />
        </Fragment>
      )}
      <Grid style={{ maxWidth: '480px' }} item xs={12}>
        <Box padding={1}>
          <Typography variant='h5' align='center'>
            Contratar o Oferta Fácil é seguro e rapidinho!
          </Typography>
          <Box padding={1} />
          <div className='form-row'>
            <Grid container justify='center'>
              <img src='/assets/images/cards/visa.svg' height={40} />
              <img
                style={{ marginLeft: 8 }}
                src='/assets/images/cards/mastercard.svg'
                height={40}
              />
            </Grid>
            <Box padding={2} />
            <input
              style={{
                width: '100%',
                color: '#32325d',
                fontFamily: '"GoogleSans", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '18px'
              }}
              placeholder='Nome no cartão'
              class='StripeElement'
              value={name}
              onchange={e => setName(e.target.value)}
            />
            <Box padding={1} />
            <CardElement
              id='card-element'
              options={CARD_ELEMENT_OPTIONS}
              onChange={handleChange}
            />
            <Box padding={1} />
            <input
              style={{
                width: '100%',
                color: '#32325d',
                fontFamily: '"GoogleSans", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '18px'
              }}
              placeholder='Coupon (opcional)'
              class='StripeElement'
              value={coupon}
              onchange={e => setCoupon(e.target.value)}
            />
            <Box padding={1} />
            <Typography color='error'>{error}</Typography>
          </div>
          <Box padding={1} />
          <Typography variant='caption'>
            Clique no botão abaixo para que seja cobrado{' '}
            <strong>R$ R$ 129,90 </strong>no seu cartão agora e depois no dia{' '}
            <strong>{todayDay}</strong> de cada mês.
          </Typography>
          <Box padding={1} />
          <Typography variant='caption'>
            O seu acesso será liberado imediatamente e você pode cancelar a
            qualquer momento.
          </Typography>
          <Box padding={1} />
          {busy ? (
            <LinearLoadingIndicator label='Aguarde um pouquinho, processando o pagamento...' />
          ) : (
            <RaisedButton
              disabled={busy || disabled}
              fullWidth
              primary
              size='large'
              label='Contratar o Oferta Fácil'
              onClick={handleSubmit}
            />
          )}
        </Box>
      </Grid>
    </Container>
  )
}
