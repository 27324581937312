export const fetchTagsQuery = `
  query Tags {
      tags {
          _id
          _key
          _rev
          name
          imageURL
      }
  }
`

export const fetchTagsByFilterQuery = `
  query TagsByFilter($filter: TagsFilterInput!) {
    tagsByFilter(filter: $filter ) {
        _id
        _key
        _rev
        name
        imageURL
    }
  }
`

export const fetchTagQuery = `
  query Tag($id: String!) {
      tag(id: $id) {
        _id
        _key
        _rev
        name
        imageURL
      }
  }
`
