import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Drawer,
  Grid,
  makeStyles,
  Toolbar,
  Typography
} from '@material-ui/core'
import { DateTime } from 'luxon'
import { inject, observer } from 'mobx-preact'
import { useEffect, useState } from 'preact/hooks'
import { useInView } from 'react-intersection-observer'
import { useSWRInfinite } from 'swr'
import { BackButton } from '../../common/back'
import { LinearLoadingIndicator } from '../../common/loading'
import store from '../../stores/root'
import { getImageUrl } from '../../utils/url'
import offerService from '../providers/offer'

const useStyles = makeStyles({
  list: {
    width: 420
  }
})

export const OfferDrawerComponent = props => {
  const { layout } = props
  const classes = useStyles()
  const batchLimit = 8

  const [busy, setBusy] = useState(false)

  const getKey = (pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData.length) return null
    return [pageIndex, `/offers?page=${pageIndex}&limit=${batchLimit}`]
  }

  let {
    data: offers = [],
    size,
    setSize
  } = useSWRInfinite(getKey, pageIndex => {
    return offerService.offersByFilter({
      offset: pageIndex * batchLimit,
      limit: batchLimit
    })
  })

  const { ref, inView } = useInView({
    threshold: 0
  })

  const handleCopyProducts = async offer => {
    try {
      setBusy(true)
      const response = await offerService.offer(offer._key)
      store.art.setProducts(response.products)
      layout.toggleDrawer(false)
    } catch (e) {
      store.layout.showSnackbar(e.message, true)
    } finally {
      setBusy(false)
    }
  }

  useEffect(() => {
    if (inView) {
      setSize(size + 1)
    }
  }, [inView])

  return (
    <Drawer
      background='transparent'
      onClose={() => layout.toggleDrawer(false)}
      anchor='right'
      open={layout.offerDrawerOpen}
    >
      <Toolbar>
        <BackButton
          onClick={() => layout.toggleDrawer(false)}
          label='Copiar Produtos'
        />
      </Toolbar>
      <Grid className={classes.list}>
        <Box padding={2}>
          {!offers || busy ? (
            <Box padding={2}>
              <LinearLoadingIndicator />
            </Box>
          ) : (
            offers && (
              <Grid className={classes.root}>
                <Grid spacing={2} container>
                  {offers.flat().map(offer => (
                    <Grid item xs={6}>
                      <Card
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          height: '100%'
                        }}
                        elevation={1}
                      >
                        <CardActionArea
                          onClick={() => handleCopyProducts(offer)}
                        >
                          <CardMedia
                            style={{
                              height: 80,
                              paddingTop: '56.25%',
                              backgroundPosition: 'top'
                            }}
                            image={
                              offer.preview ||
                              getImageUrl(offer.background?.imageURL || '', 180)
                            }
                          />
                          <Box padding={1}>
                            <Typography variant='caption'>
                              {DateTime.fromISO(offer.updatedAt).toFormat(
                                'dd/MM/yyyy hh:mm'
                              )}
                            </Typography>
                          </Box>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
                <Box ref={ref} />
              </Grid>
            )
          )}
        </Box>
      </Grid>
    </Drawer>
  )
}

export const OfferDrawer = inject('layout')(observer(OfferDrawerComponent))
