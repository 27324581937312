import { useState } from 'preact/hooks'
import authService from '../providers/auth'

export const useRecoverPasswordState = () => {
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')

  const onSubmit = async () => {
    if (!email) {
      return alert('Digite um email válido')
    }

    try {
      await authService.recoverPassword(email)
      setSuccess(true)
    } catch (e) {
      setError('Entre em contato com o nosso suporte')
    }
  }

  return { email, setEmail, onSubmit, success, error }
}
