import { DateTime } from 'luxon'

export const isTrialExpired = trialEnd => {
  if (!trialEnd) {
    return true
  }
  const now = DateTime.local().toUTC()
  const expiration = DateTime.fromISO(trialEnd).toUTC()
  return now > expiration
}
