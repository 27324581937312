import {
  Box,
  Grid,
  LinearProgress,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core'
import { Fragment } from 'preact'
import { useHistory } from 'react-router'
import { FlatButton, RaisedButton } from '../common/button'
import Colors from '../config/colors'
import AppRoutes from '../navigation/routes'
import { SignupVerification } from '../signup/verification'
import { FacebookSigninButton } from './buttons/facebook'
import { GoogleSigninButton } from './buttons/google'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100vw',
    height: '100vh',
    backgroundSize: 'cover',
    overflowY: 'scroll',
    background: `url(/assets/images/gallaxy.svg) no-repeat`
  },
  sidebar: {
    position: 'fixed',
    overflowY: 'auto',
    zIndex: 999,
    height: '100vh',
    maxWidth: 420,
    minWidth: 360,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: Colors.white,
    padding: theme.spacing(4),
    '-moz-box-shadow': '2px 2px 5px 3px rgba(0,0,0,0.05)',
    '-webkit-box-shadow': '2px 2px 5px 3px rgba(0,0,0,0.05)',
    boxShadow: '2px 2px 5px 3px rgba(0,0,0,0.05)'
  },
  img: {
    background: 'url("/assets/images/500-images.png") no-repeat',
    backgroundPosition: 'top',
    alignSelf: 'center',
    left: '45%',
    top: '25%',
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    maxWidth: 640
  }
}))

export const LoginForm = props => {
  const history = useHistory()
  const classes = useStyles()

  const {
    busy,
    setBusy,
    credentials,
    setCredentials,
    onLogin,
    error,
    validated,
    emailSignin,
    setEmailSignin,
    verificationSent
  } = props

  if (verificationSent) {
    return <SignupVerification email={credentials.email} />
  }



  return (
    <Grid className={classes.root}>
      <div className={classes.img} />
      <Grid className={classes.sidebar}>
        <Grid item>
          <img src='/assets/logo_easyoffer_blue.svg' width={140} />
        </Grid>
        <Grid item>
          <Box padding={4} />
        </Grid>
        <Grid item>
          <Typography variant='h6'>Entrar na sua conta</Typography>
        </Grid>
        <Box padding={2} />
        {busy && (
          <Box padding={3}>
            <LinearProgress variant='indeterminate' />
          </Box>
        )}
        <Grid item>
          {!busy && (
            <Fragment>
              {!emailSignin && (
                <Fragment>
                  <GoogleSigninButton
                    context={'signin'}
                    onStart={() => setBusy(true)}
                    onError={() => setBusy(false)}
                  />
                  <Box padding={1} />
                  <FacebookSigninButton
                    onStart={() => setBusy(true)}
                    onError={() => setBusy(false)}
                  />
                  <Box padding={2} />
                  <Grid container justify='center' item>
                    <Typography variant='caption' align='center'>
                      ou
                    </Typography>
                  </Grid>
                </Fragment>
              )}
              {emailSignin && (
                <Grid container direction='column'>
                  <Grid item>
                    <TextField
                      type='email'
                      label='E-mail'
                      variant='outlined'
                      fullWidth
                      error={validated && !credentials.email}
                      helperText={
                        validated && !credentials.email
                          ? 'Digite um email válido'
                          : ''
                      }
                      value={credentials.email}
                      onChange={e =>
                        setCredentials({
                          ...credentials,
                          email: e.target.value
                        })
                      }
                    />
                  </Grid>
                  <Box padding={1} />
                  <Grid item>
                    <TextField
                      type='password'
                      label='Senha'
                      variant='outlined'
                      error={validated && !credentials.password}
                      helperText={
                        validated && !credentials.password
                          ? 'Digite uma senha'
                          : ''
                      }
                      fullWidth
                      value={credentials.password}
                      onChange={e =>
                        setCredentials({
                          ...credentials,
                          password: e.target.value
                        })
                      }
                    />
                  </Grid>
                  {error && (
                    <Fragment>
                      <Box padding={1} />
                      <Typography color='error' variant='body2'>
                        {error}
                      </Typography>
                      <Box padding={1} />
                      <Typography variant='caption'>
                        Qualquer dúvida, fale com nosso suporte pelo chat
                      </Typography>
                    </Fragment>
                  )}
                  <Box padding={1} />
                  <Grid item>
                    <RaisedButton
                      primary
                      fullWidth
                      size='large'
                      label='Acessar'
                      onClick={onLogin}
                    />
                  </Grid>
                  <Box padding={2} />
                </Grid>
              )}
              <Box padding={1} />
              <Grid container justify='center'>
                <FlatButton
                  onClick={() => setEmailSignin(!emailSignin)}
                  label={
                    emailSignin
                      ? 'Logar com Google ou Facebook'
                      : 'Logar com e-mail'
                  }
                />
              </Grid>
              <Box padding={2} />
              <Grid container justify='space-between'>
                <Grid item>
                  <FlatButton
                    primary
                    label='Recuperar Senha'
                    onClick={() => history.push(AppRoutes.RecoverPassword)}
                  />
                </Grid>
                <Grid item>
                  <FlatButton
                    primary
                    label='Registrar'
                    onClick={() => history.push(AppRoutes.Signup)}
                  />
                </Grid>
              </Grid>
              <Box padding={2} />
            </Fragment>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container justify='space-between'>
            <Typography variant='caption'>
              <a
                href='https://ofertafacilapp.com.br/termos-de-uso'
                rel='noreferrer'
                target='_blank'
              >
                Termos de Uso
              </a>
            </Typography>
            <Typography variant='caption'>
              <a
                href='https://ofertafacilapp.com.br/politica-de-privacidade'
                rel='noreferrer'
                target='_blank'
              >
                Política de Privacidade
              </a>
            </Typography>
          </Grid>
        </Grid>
        <Box padding={6} />
      </Grid>
    </Grid>
  )
}
