import {
  Checkbox,
  ListItem,
  ListItemAvatar,
  Typography
} from '@material-ui/core'

export const ListCheckItem = props => {
  const { checked, onClick, label } = props
  return (
    <ListItem button onClick={onClick} dense>
      <ListItemAvatar>
        <Checkbox
          style={{ padding: 3 }}
          padding={2}
          value={label}
          checked={checked || false}
          color='primary'
        />
      </ListItemAvatar>
      <Typography variant='button'>{label}</Typography>
    </ListItem>
  )
}
