import { colors, Grid, Typography } from '@material-ui/core'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'

export const ChangesSaved = () => {
  return (
    <Grid container>
      <CheckCircleOutline
        style={{
          color: colors.green[500],
          marginTop: 2,
          fontSize: 16,
          marginRight: 4
        }}
      />
      <Typography variant='caption'>
        Todas as alterações foram salvas
      </Typography>
    </Grid>
  )
}

export const UnsavedChanges = () => {
  return (
    <Grid container>
      <Typography variant='caption'>Alterações não salvas</Typography>
    </Grid>
  )
}
