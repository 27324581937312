import {
  Button,
  Divider,
  makeStyles,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core'
import { useState } from 'preact/hooks'
import Colors from '../config/colors'

const useStyles = makeStyles({
  root: {
    padding: 4,
    borderRadius: 8,
    position: 'fixed',
    backgroundColor: Colors.white,
    bottom: 12,
    right: 110,
    zIndex: 500,
    '-webkit-box-shadow': '3px 3px 20px -17px rgba(0,0,0,0.75)',
    '-moz-box-shadow': '3px 3px 20px -17px rgba(0,0,0,0.75)',
    'box-shadow': '3px 3px 20px -17px rgba(0,0,0,0.75)'
  }
})

const MenuTypography = ({ label }) => {
  return <Typography variant='button'>{label}</Typography>
}

export const CanvasZoom = ({ canvas }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(false)

  if (!canvas) {
    return null
  }

  const handleFit = () => {
    canvas.fitCanvasToScreen()
    setAnchorEl(null)
  }

  const handleFitToWidth = () => {
    canvas.fitCanvasToWidth()
    setAnchorEl(null)
  }

  const handleScaleChange = scale => {
    canvas.updateScale(scale)
  }

  const currentScale = Math.round(canvas.currentScale * 100).toFixed(0)

  return (
    <div className={classes.root}>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => handleScaleChange(1.25)}>
          <MenuTypography label='125%' />
        </MenuItem>
        <MenuItem onClick={() => handleScaleChange(1)}>
          <MenuTypography label='100%' />
        </MenuItem>
        <MenuItem onClick={() => handleScaleChange(0.75)}>
          <MenuTypography label='75%' />
        </MenuItem>
        <MenuItem onClick={() => handleScaleChange(0.5)}>
          <MenuTypography label='50%' />
        </MenuItem>
        <MenuItem onClick={() => handleScaleChange(0.25)}>
          <MenuTypography label='25%' />
        </MenuItem>
        <MenuItem onClick={() => handleScaleChange(0.1)}>
          <MenuTypography label='10%' />
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleFit}>
          <MenuTypography label='Inteiro' />
        </MenuItem>
        <MenuItem onClick={handleFitToWidth}>
          <MenuTypography label='Ajustar' />
        </MenuItem>
      </Menu>
      <Button onClick={e => setAnchorEl(e.currentTarget)}>
        <Typography variant='button'>{currentScale}%</Typography>
      </Button>
    </div>
  )
}
