import graphql from '../../http/graphql'
import { fetchLogosQuery, logosByFilterQuery } from '../graphql/queries'
import { updateLogoMutation, removeLogoMutation } from '../graphql/mutations'

class LogoService {
  async logos() {
    return graphql.query(fetchLogosQuery).then(t => t.logos)
  }

  async logosByFilter(filter) {
    return graphql
      .query(logosByFilterQuery, { filter })
      .then(t => t.logosByFilter)
  }

  async update(data) {
    return graphql.query(updateLogoMutation, { data })
  }

  async remove(id) {
    return graphql.query(removeLogoMutation, { id })
  }
}

const logoService = new LogoService()
export default logoService
