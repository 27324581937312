import { captureWithScope } from '../sentry'
import store from '../stores/root'
import { API_ROOT_URL } from './hostname'

class GraphQL {
  authenticate = credentials => {
    return this.post(`/authenticate`, credentials).then(d => d.data)
  }

  signup = data => {
    return this.post(`/signup`, data).then(d => d.data)
  }

  query = async (request, payload) => {
    return this.post('/graphql', {
      query: request,
      variables: payload
    }).then(r => r.data)
  }

  get = url => {
    return this.request(`${API_ROOT_URL}${url}`, 'GET')
  }

  post = (url, payload) => {
    return this.request(`${API_ROOT_URL}${url}`, 'POST', payload)
  }

  insertTokenIntoHeaders = headers => {
    const token = localStorage.getItem('token')

    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/json')

    if (token) {
      headers.append('Authorization', token)
    }
  }

  fetchWithTimeout = (url, params, timeout) =>
    new Promise((resolve, reject) => {
      const errorTimeout = setTimeout(
        reject.bind(null, new Error('Timeout')),
        timeout
      )
      fetch(`${url}`, params)
        .then(res => {
          clearTimeout(errorTimeout)
          resolve(res)
        })
        .catch(e => reject(e))
    })

  request = async (url, method, payload) => {
    const headers = new Headers()
    this.insertTokenIntoHeaders(headers)

    const params = {
      method,
      headers,
      body: JSON.stringify(payload)
    }

    const response = await this.fetchWithTimeout(`${url}`, params, 10000)
    const jsonResponse = await response.json()

    if (jsonResponse.errors) {
      const error = jsonResponse.errors[0]
      const errorMessage = new Error(error.message)

      if (error.status === 401) {
        if (!window.location.href.includes('login')) {
          store.auth.logout()
        }
        captureWithScope(errorMessage)
        throw error
      } else {
        captureWithScope(errorMessage)
        throw error
      }
    }

    return jsonResponse
  }
}

const graphql = new GraphQL()
export default graphql
