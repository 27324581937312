import { Box, Tab, Tabs } from '@material-ui/core'
import { Fragment } from 'preact'
import { Route, useHistory, useRouteMatch } from 'react-router'
import { CustomBackgrounds } from './custom/screen'
import { BackgroundListScreen } from './list/screen'

export const BackgroundContent = () => {
  const history = useHistory()
  const { url } = useRouteMatch()

  const rootUrl = url.substring(0, url.lastIndexOf('/'))
  const backgroundURL = `${rootUrl}/arte`
  const customURL = `${rootUrl}/arte/customizadas`

  return (
    <Fragment>
      <Box padding={2} paddingTop={1} paddingBottom={1}>
        <Tabs
          onChange={(e, activeTab) => {
            activeTab == 0
              ? history.push(backgroundURL)
              : history.push(customURL)
          }}
          indicatorColor='primary'
          value={window.location.href.includes('custom') ? 1 : 0}
          variant='fullWidth'
        >
          <Tab value={0} label='Artes' />
          <Tab value={1} label='Customizadas' />
        </Tabs>
      </Box>
      <Route path={customURL}>
        <CustomBackgrounds />
      </Route>
      <Route exact path={backgroundURL}>
        <BackgroundListScreen />
      </Route>
    </Fragment>
  )
}
