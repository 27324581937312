import {
  Box,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  TextField,
  Typography
} from '@material-ui/core'
import { useEffect, useState } from 'preact/hooks'
import authService from '../auth/providers/auth'
import { EasyOfferAppBar } from '../common/appbar'
import { BackButton } from '../common/back'
import { FlatButton, RaisedButton } from '../common/button'
import { CustomTextField } from '../common/input'
import { LinearLoadingIndicator } from '../common/loading'
import { MaskedCNPJInput, MaskedCPFInput } from '../common/masked-input'
import { captureWithScope } from '../sentry'
import store from '../stores/root'
import { userService } from '../user/service'
import { isValidEmail } from '../utils/validation'

export const ProfileScreen = () => {
  const authUser = store.auth.user

  const [user, setUser] = useState(null)
  const [password, setPassword] = useState('')
  const [confirmation, setConfirmation] = useState('')
  const [busy, setBusy] = useState(false)
  const [cpfMask, setCpfMask] = useState(true)
  const [stripeAccount, setStripeAccount] = useState(null)

  useEffect(() => {
    const fetchStripeAccount = async () => {
      try {
        const response = await authService.stripeSession()
        setStripeAccount(response)
      } catch (e) {
        console.log(e)
      }
    }

    const fetchUser = async () => {
      try {
        const user = await userService.user(authUser._id)
        setUser(user)
      } catch (e) {
        store.layout.showSnackbar(
          'Erro ao carregar as informações de usuário',
          true
        )
      }
    }

    fetchUser()
    fetchStripeAccount()
  }, [])

  const handleUpdatePassword = async () => {
    if (!password) {
      return store.layout.showSnackbar('Defina uma nova senha', true)
    }

    if ((password && !confirmation) || password !== confirmation) {
      return store.layout.showSnackbar('Senha e confirmação não conferem', true)
    }

    try {
      setBusy(true)
      await userService.updatePassword({
        userId: user._id,
        password
      })
      store.layout.showSnackbar('Senha atualizada com sucesso')
      setBusy(false)
      setPassword('')
      setConfirmation('')
    } catch (e) {
      setBusy(false)
      store.layout.showSnackbar('Erro ao atualizar a senha', true)
    }
  }

  const handleUpdateUser = async () => {
    const noDot = user.taxId.replaceAll('.', '')
    const noDash = noDot.replaceAll('-', '')
    const finalTaxId = noDash.replaceAll('/', '')

    if (!user.email || !isValidEmail(user.email)) {
      return store.layout.showSnackbar('E-mail inválido', true)
    }

    try {
      setBusy(true)

      const update = {
        _id: user._id,
        email: user.email,
        name: user.name,
        taxId: finalTaxId
      }

      await userService.update(update)

      store.layout.showSnackbar('Dados atualizados com sucesso')
      setBusy(false)
    } catch (e) {
      setBusy(false)
      captureWithScope(e)
    }
  }

  if (!user) {
    return <LinearLoadingIndicator />
  }

  return (
    <Container maxWidth='md'>
      <EasyOfferAppBar />
      <Box padding={1} />
      <BackButton />
      <Box padding={2} />
      {busy ? (
        <LinearLoadingIndicator />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h6'>Dados do Perfil</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Radio onChange={() => setCpfMask(true)} checked={cpfMask} />
              }
              label='CPF'
            />
            <FormControlLabel
              onChange={() => setCpfMask(false)}
              control={<Radio checked={!cpfMask} />}
              label='CNPJ'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id='document'
              placeholder='Digite'
              variant='outlined'
              value={user.taxId || ''}
              onChange={e =>
                setUser({
                  ...user,
                  taxId: e.target.value
                })
              }
              fullWidth
              InputProps={{
                inputComponent: cpfMask ? MaskedCPFInput : MaskedCNPJInput,
                component: CustomTextField
              }}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <CustomTextField
              onChange={e => setUser({ ...user, name: e.target.value })}
              value={user.name || ''}
              placeholder='Nome'
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              onChange={e => setUser({ ...user, email: e.target.value })}
              value={user.email || ''}
              placeholder='Email'
            />
          </Grid>
          <Grid item xs={12}>
            <RaisedButton primary label='Salvar' onClick={handleUpdateUser} />
          </Grid>
          <Grid item xs={12}>
            <Box padding={3} />
            <Divider />
            <Box padding={3} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>Redefinir Senha</Typography>
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              value={password}
              onChange={e => setPassword(e.target.value)}
              type='password'
              autocomplete='off'
              placeholder='Senha'
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              value={confirmation}
              onChange={e => setConfirmation(e.target.value)}
              type='password'
              autocomplete='off'
              placeholder='Confirmação de senha'
            />
          </Grid>
          <Grid item xs={12}>
            <RaisedButton
              primary
              label='Salvar nova senha'
              onClick={handleUpdatePassword}
            />
          </Grid>
        </Grid>
      )}
      <Box padding={3} />
      <Divider />
      <Box padding={3} />
      {stripeAccount && stripeAccount.url && (
        <Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>Dados de Pagamento</Typography>
            <Box padding={1} />
            <RaisedButton
              primary={true}
              label='Editar dados de pagamento'
              onClick={() => (window.location.href = stripeAccount.url)}
            />
          </Grid>
        </Grid>
      )}
      <Box padding={6} />
    </Container>
  )
}
