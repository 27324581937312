export const retrialMutation = `
  mutation Retrial($email: String!) {
    retrial(email: $email)
  }
`

export const subscribeMutation = `
  mutation Subscribe($data: SubscribeInput!) {
    subscribe(data: $data) {
      invoice
      subscription
    }
  }
`

export const resetSubscriptionMutation = `
  mutation resetSubscription {
    resetSubscription
  }
`
