import {
  Box,
  Drawer,
  Grid,
  IconButton,
  makeStyles,
  Toolbar
} from '@material-ui/core'
import { SaveOutlined } from '@material-ui/icons'
import { Fragment } from 'preact'
import { useState } from 'preact/hooks'
import { BackButton } from '../common/back'
import { RaisedButton } from '../common/button'
import { CustomTextField } from '../common/input'
import { LinearLoadingIndicator } from '../common/loading'
import store from '../stores/root'
import { templateProvider } from './providers/template'
import uploadImage from '../utils/upload'

const useStyles = makeStyles({
  list: {
    width: 420
  }
})

export const TemplateDrawer = () => {
  const { user } = store.auth
  const { offer, activeTemplate } = store.art

  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [busy, setButy] = useState(false)
  const [template, setTemplate] = useState({})

  const handleSave = async () => {
    if (!offer) return

    try {
      setButy(true)

      const template = Object.assign({}, offer)

      delete template._id
      delete template._key
      delete template._rev

      if (activeTemplate) {
        template._id = activeTemplate._id
        template._key = activeTemplate._key
        template._rev = activeTemplate._rev
      }

      store.art.setActiveTemplate(null)

      const data = store.art.canvas.toDataURL({ pixelDensity: 3 })
      const img = await fetch(data).then(r => r.blob())

      if (!data) {
        return store.layout.showSnackbar('Erro ao converter imagem', true)
      }

      const url = await uploadImage(img, null, 'preview')

      if (!url) {
        return store.layout.showSnackbar('Erro ao enviar imagem', true)
      }

      template.preview = url
      await templateProvider.update(template)

      setButy(false)
      store.layout.showSnackbar('Template salvo com sucesso')
      setTemplate({})
    } catch (e) {
      setButy(false)
      store.layout.showSnackbar(e.message, true)
    }
  }

  return (
    <Fragment>
      {user && user.staff && (
        <IconButton
          style={{ marginTop: -8, marginLeft: 24, padding: 4 }}
          onClick={() => setOpen(true)}
        >
          <SaveOutlined />
        </IconButton>
      )}
      <Drawer onClose={() => setOpen(false)} anchor='right' open={open}>
        <Toolbar>
          <BackButton onClick={() => setOpen(false)} label='Criar Modelo' />
        </Toolbar>
        {busy ? (
          <Box padding={2}>
            <LinearLoadingIndicator />
          </Box>
        ) : (
          <Box padding={2}>
            <Grid container spacing={2} className={classes.list}>
              <Grid item xs={12}>
                <CustomTextField
                  onChange={e =>
                    setTemplate({ ...template, tags: e.target.value })
                  }
                  value={template.tags || ''}
                  placeholder='Tags'
                />
              </Grid>
              <Grid item xs={12}>
                <RaisedButton
                  onClick={handleSave}
                  primary
                  fullWidth
                  label='Salvar Modelo'
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </Drawer>
    </Fragment>
  )
}
