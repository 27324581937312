import { Grid } from '@material-ui/core'
import Colors from '../config/colors'

const styles = {
  border: 'none',
  cursor: 'pointer',
  display: 'inline-flex',
  outline: 'none',
  padding: '1.125rem',
  position: 'relative',
  flexGrow: 1,
  fontSize: '0.815rem',
  background: Colors.textInputColor,
  transition: 'background-color .6s',
  alignItems: 'center',
  fontWeight: 'bold',
  userSelect: 'none',
  whiteSpace: 'nowrap',
  touchAction: 'manipulation',
  borderRadius: '.6rem',
  justifyContent: 'center',
  backgroundImage: 'none',
  color: '#333333'
}

const iconStyles = {
  left: '1rem',
  height: '1.125rem !important',
  position: 'absolute'
}

export const FacebookProviderButton = props => {
  return (
    <Grid container>
      <button {...props} style={styles} au-target-id='149'>
        <img height={24} src='/assets/fb-logo-32.png' style={iconStyles} />
        {props.label || 'Registrar com Facebook'}
      </button>
    </Grid>
  )
}

export const GoogleProviderButton = props => {
  return (
    <Grid container>
      <button
        {...props}
        id='google-signin-button'
        style={styles}
        au-target-id='149'
      >
        <img height={24} src='/assets/g-logo.svg' style={iconStyles} />
        {props.label || 'Registrar com Google'}
      </button>
    </Grid>
  )
}
