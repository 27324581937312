import { Box, Container, Divider, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { inject, observer } from 'mobx-preact'
import { Fragment } from 'preact'
import { useEffect } from 'preact/hooks'
import { useInView } from 'react-intersection-observer'
import { useHistory } from 'react-router'
import { useSWRInfinite } from 'swr'
import { EasyOfferAppBar } from '../../common/appbar'
import { UserFeedback } from '../../feedback/survey'
import { useSubscribed } from '../../navigation/subscribed'
import { captureWithScope } from '../../sentry'
import { OfferFormats } from '../formats'
import offerService from '../providers/offer'
import OfferCard from './card'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  media: {
    height: 0,
    paddingTop: '100%'
  },
  header: {
    backgroundColor: '#EAEBEF',
    color: '#2196F3',
    textAlign: 'center'
  },
  content: {
    padding: '15px',
    height: '130px',
    backgroundColor: '#EAEBEF',
    color: '#2196F3',
    textAlign: 'center'
  },
  button: {
    margin: '15px',
    display: 'inline'
  }
})

const OfferListComponent = () => {
  useSubscribed()
  const batchLimit = 10

  const history = useHistory()
  const classes = useStyles()

  if (window.$crisp) {
    window.$crisp.push(['do', 'chat:show'])
  }

  const getKey = (pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData.length) return null
    return [pageIndex, `/offers?page=${pageIndex}&limit=${batchLimit}`]
  }

  let {
    data: offers = [],
    revalidate,
    size,
    setSize
  } = useSWRInfinite(getKey, pageIndex => {
    return offerService.offersByFilter({
      offset: pageIndex * batchLimit,
      limit: batchLimit
    })
  })

  const { ref, inView } = useInView({
    threshold: 0
  })

  const handleRemoveOffer = async offer => {
    try {
      await offerService.remove(offer._key)
      revalidate()
    } catch (e) {
      captureWithScope(e)
    }
  }

  const handleDuplicateOffer = async offer => {
    try {
      await offerService.duplicate(offer._key)
      revalidate()
    } catch (e) {
      captureWithScope(e)
    }
  }

  useEffect(() => {
    if (inView) {
      setSize(size + 1)
    }
  }, [inView])

  return (
    <Container maxWidth='md'>
      <Fragment>
        <EasyOfferAppBar />
        <Fragment>
          <UserFeedback />
          <Box padding={2} />
          <OfferFormats />
          <Box padding={3} />
          {/* <RecentOffers />
          <Box padding={3} /> */}
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='h6' color='textPrimary'>
                Meus Encartes
              </Typography>
              <Box padding={1} />
              <Divider />
            </Grid>
            {!offers ||
              (offers.length == 0 && (
                <Box padding={3}>
                  <Typography>
                    Você ainda não criou seu primeiro encarte.
                  </Typography>
                </Box>
              ))}
            <Box padding={2} />
            {offers && (
              <Grid container className={classes.root} spacing={3} py={4}>
                {offers.flat().map(offer => (
                  <OfferCard
                    key={offer._id}
                    onDuplicate={() => handleDuplicateOffer(offer)}
                    onRemove={() => handleRemoveOffer(offer)}
                    history={history}
                    offer={offer}
                  />
                ))}
                <Box ref={ref} padding={4} />
              </Grid>
            )}
          </Grid>
        </Fragment>
      </Fragment>
    </Container>
  )
}

export const OfferListScreen = inject('art')(observer(OfferListComponent))
