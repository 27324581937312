import { Box, Container, Grid, IconButton, Typography } from '@material-ui/core'
import { StarBorderOutlined, StarOutlined } from '@material-ui/icons'
import { DateTime } from 'luxon'
import { useState } from 'preact/hooks'
import { feedbackService } from './service'

export const UserFeedback = () => {
  const nextVote = localStorage.getItem('nextVote')

  let showVoting = true

  if (nextVote) {
    const now = DateTime.local()
    const next = DateTime.fromMillis(Number.parseInt(nextVote))
    if (now < next) showVoting = false
  } else {
    showVoting = false
    localStorage.setItem(
      'nextVote',
      DateTime.local()
        .plus({ days: 2 })
        .toMillis()
    )
  }

  const [show, setShow] = useState(showVoting)
  const [rating, setRating] = useState(0)

  const sendFeedbackToServer = async rating => {
    try {
      localStorage.setItem(
        'nextVote',
        DateTime.local()
          .plus({ days: 7 })
          .toMillis()
      )
      setTimeout(() => setShow(false), 1500)
      await feedbackService.send(rating)
    } catch (e) {
      // noop
    }
  }

  const vote = vote => {
    if (rating > 0) {
      return
    }
    setRating(vote)
    sendFeedbackToServer(vote)
  }

  return (
    show && (
      <Container>
        <Box padding={2}>
          <Grid container justify='center'>
            <Grid item container xs={5} direction='column' alignItems='center'>
              <Grid item>
                <Typography variant='subtitle2'>
                  Oie!! Quantas estrelas a gente merece hoje?
                </Typography>
              </Grid>
              <Grid container item justify='center'>
                <Grid item>
                  <IconButton onClick={() => vote(1)}>
                    {rating > 0 ? (
                      <StarOutlined color='primary' />
                    ) : (
                      <StarBorderOutlined />
                    )}
                  </IconButton>
                  <IconButton onClick={() => vote(2)}>
                    {rating > 1 ? (
                      <StarOutlined color='primary' />
                    ) : (
                      <StarBorderOutlined />
                    )}
                  </IconButton>
                  <IconButton onClick={() => vote(3)}>
                    {rating > 2 ? (
                      <StarOutlined color='primary' />
                    ) : (
                      <StarBorderOutlined />
                    )}
                  </IconButton>
                  <IconButton onClick={() => vote(4)}>
                    {rating > 3 ? (
                      <StarOutlined color='primary' />
                    ) : (
                      <StarBorderOutlined />
                    )}
                  </IconButton>
                  <IconButton onClick={() => vote(5)}>
                    {rating > 4 ? (
                      <StarOutlined color='primary' />
                    ) : (
                      <StarBorderOutlined />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            {rating > 0 && (
              <Box padding={3}>
                <Typography align='center' variant='h6'>
                  Obrigado 🥰
                </Typography>
              </Box>
            )}
          </Grid>
        </Box>
      </Container>
    )
  )
}
