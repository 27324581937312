import { Box, Button, Container, Grid, TextField } from '@material-ui/core'
import { inject, observer } from 'mobx-preact'
import { Component, Fragment } from 'preact'
import { AccountTabs } from '../common/account_tab'
import { EasyOfferAppBar } from '../common/appbar'
import Spacer from '../common/spacer'
import { captureWithScope } from '../sentry'
import store from '../stores/root'
import { userService } from '../user/service'

export class AccountSecurity extends Component {
  state = {
    password: '',
    confirmation: '',
    busy: false
  }

  savePassword = async () => {
    const { user } = this.props.auth
    const { password, confirmation } = this.state



    try {
      this.setState({ busy: true })
      await userService.updatePassword({
        userId: user._id,
        password
      })
      this.setState({ password: '', confirmation: '' })
      store.layout.showSnackbar('Senha alterada com sucesso')
      this.setState({ busy: false })
    } catch (e) {
      store.layout.showSnackbar(
        'Erro ao alterar a senha. Fale com nosso suporte',
        true
      )
      captureWithScope(e)
      this.setState({ busy: false })
    }
  }

  handleChange = name => e => {
    this.setState({ [name]: e.target.value })
  }

  render () {
    const { password, confirmation } = this.state
    return (
      <Fragment>
        <EasyOfferAppBar />
        <Container maxWidth='lg'>
          <AccountTabs url={this.props.url} />
          <Spacer double />
          <Box marginTop={6}>
            <Grid container>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <TextField
                    value={password}
                    onChange={this.handleChange('password')}
                    variant='outlined'
                    fullWidth
                    type='password'
                    label='Senha'
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    value={confirmation}
                    onChange={this.handleChange('confirmation')}
                    variant='outlined'
                    type='password'
                    fullWidth
                    label='Confirmação de senha'
                  />
                </Grid>
              </Grid>
              <Spacer double />
            </Grid>
            <Button
              onClick={this.savePassword}
              color='primary'
              variant='contained'
            >
              Alterar Senha
            </Button>
          </Box>
        </Container>
      </Fragment>
    )
  }
}

export const AccountSecurityScreen = inject('auth')(observer(AccountSecurity))
