export const updateBackgroundMutation = `
    mutation UpdateBackround($data: BackgroundInput!) {
        updateBackground(data: $data) {
            _id
            _key
            _rev
        }
    }
`

export const addCustomBackgroundMutation = `
    mutation AddCustomBackground($data: BackgroundInput!) {
        addCustomBackground(data: $data) {
            _id
            _key
            _rev
        }
    }
`
