import { useEffect } from 'preact/hooks'
import { useHistory } from 'react-router'
import authService from '../../auth/providers/auth'
import AppRoutes from '../../navigation/routes'
import { FacebookProviderButton } from '../../common/auth_buttons'
import store from '../../stores/root'
import { captureWithScope } from '../../sentry'

export function FacebookSigninButton ({ onStart, onError }) {
  const history = useHistory()

  const authenticateFacebook = async () => {
    onStart()

    window.FB.login(
      response => {
        const auth = response.authResponse
        if (response.status === 'connected') {
          window.FB.api(
            '/me',
            { locale: 'pt_BR', fields: 'name, email, picture' },
            async profile => {
              try {
                const params = {
                  id: auth.userID,
                  name: profile.name,
                  photoURL: profile.picture?.data?.url,
                  email: profile.email,
                  providerId: 'facebook',
                  idToken: auth.accessToken
                }
                const { user, token } = await authService.authenticate(params)
                if (token) {
                  localStorage.setItem('token', token)
                  localStorage.setItem('user', JSON.stringify(user))
                  history.push(AppRoutes.Offers)
                }
              } catch (e) {
                onError(e)
                captureWithScope(e)
                store.layout.showSnackbar(e.message, true)
              }
            }
          )
        }
      },
      { scope: 'public_profile,email', return_scopes: true }
    )
  }

  const facebookInit = () => {
    window.FB.init({
      appId: '225513812136456',
      autoLogAppEvents: true,
      xfbml: false,
      version: 'v9.0',
      status: true
    })
  }

  const initFacebookSDK = () => {
    const existingScript = document.getElementById('facebookSignin')

    if (!existingScript) {
      const script = document.createElement('script')
      script.src = 'https://connect.facebook.net/pt_BR/sdk.js'
      script.id = 'facebookSignin'
      document.body.appendChild(script)
      script.onload = facebookInit
    } else {
      facebookInit()
    }
  }

  useEffect(() => {
    initFacebookSDK()
  }, [])

  return (
    <FacebookProviderButton
      label='Logar com o Facebook'
      onClick={authenticateFacebook}
    />
  )
}
