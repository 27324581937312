import { Box, Grid, LinearProgress, Typography } from '@material-ui/core'

export const LinearLoadingIndicator = ({ label }) => {
  return (
    <Grid container justify='center'>
      <Grid item xs={12}>
        <Box padding={1}>
          <LinearProgress variant='indeterminate' />
          {label && (
            <Typography variant='caption' align='center'>
              {label}
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}
