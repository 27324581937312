import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  Slider,
  withStyles
} from '@material-ui/core'
import {
  FormatBoldOutlined,
  FormatItalicOutlined,
  TextFormatOutlined
} from '@material-ui/icons'
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter'
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft'
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight'
import { useState } from 'preact/hooks'
import { CompactPicker } from 'react-color'
import { FlatButton } from '../../common/button'
import { FontSelector } from '../../common/font'
import { CustomTextField } from '../../common/input'
import Colors from '../../config/colors'

const LargeSlider = withStyles({
  root: {
    color: '#019EEC',
    height: 4
  },
  track: {
    height: 4,
    borderRadius: 4
  },
  thumb: {
    marginTop: -4
  },
  rail: {
    color: Colors.textInputColor,
    height: 4,
    borderRadius: 4
  }
})(Slider)

export const TextMenu = ({ text, art }) => {
  const [aText, setText] = useState(text.text)
  const [, setSize] = useState(text.fontSize || 80)

  return (
    <Grid container direction='column'>
      <Box padding={1} />
      <Grid item>
        <CustomTextField
          value={aText}
          rows={3}
          multiline
          onChange={e => {
            art.updateObjectOnCanvas('text', text.id, {
              text: e.target.value
            })
            setText(e.target.value)
          }}
        />
      </Grid>
      <Box padding={1} />
      <Grid container justify='center' item>
        <CompactPicker
          styles={{
            wrap: {
              boxShadow: 'none'
            }
          }}
          circleSize={20}
          onChangeComplete={color => {
            art.updateObjectOnCanvas('text', text.id, {
              color: color.hex
            })
          }}
        />
      </Grid>
      <Box padding={2} />
      <Grid container justify='space-between'>
        <Grid item>
          <Box padding={1}>
            <FontSelector
              onUpdate={fontFamily =>
                art.updateObjectOnCanvas('text', text.id, {
                  fontFamily
                })
              }
              fontFamily={text.fontFamily || 'Roboto'}
            />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box padding={1}>
            <LargeSlider
              min={16}
              step={5}
              max={200}
              value={text.fontSize || 80}
              onChange={(e, fontSize) => {
                setSize(fontSize)
                art.updateObjectOnCanvas('text', text.id, {
                  fontSize
                })
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Box padding={1} />
      <Divider height={48} />
      <Box padding={1} />
      <Grid container direction='row' justify='space-around'>
        <ButtonGroup>
          <Button
            onClick={() => {
              art.updateObjectOnCanvas('text', text.id, {
                textAlign: 'left'
              })
            }}
            style={{ border: 'none' }}
            value='left'
            size='small'
          >
            <FormatAlignLeftIcon />
          </Button>
          <Button
            onClick={() => {
              art.updateObjectOnCanvas('text', text.id, {
                textAlign: 'center'
              })
            }}
            style={{ border: 'none', padding: 0 }}
            value='center'
            size='small'
          >
            <FormatAlignCenterIcon />
          </Button>
          <Button
            onClick={() => {
              art.updateObjectOnCanvas('text', text.id, {
                textAlign: 'right'
              })
            }}
            style={{ border: 'none' }}
            value='right'
            size='small'
          >
            <FormatAlignRightIcon />
          </Button>
          <div style={{ width: '16px' }} />
          <Button
            onClick={(_, fontStyle) =>
              art.updateObjectOnCanvas('text', text.id, {
                fontStyle: 'normal'
              })
            }
            style={{ border: 'none' }}
            value='normal'
            size='small'
          >
            <TextFormatOutlined />
          </Button>
          <Button
            onClick={(_, fontStyle) =>
              art.updateObjectOnCanvas('text', text.id, {
                fontStyle: 'bold'
              })
            }
            style={{ border: 'none' }}
            value='bold'
            size='small'
          >
            <FormatBoldOutlined />
          </Button>
          <Button
            onClick={(_, fontStyle) =>
              art.updateObjectOnCanvas('text', text.id, {
                fontStyle: 'italic'
              })
            }
            style={{ border: 'none' }}
            value='italic'
            size='small'
          >
            <FormatItalicOutlined />
          </Button>
        </ButtonGroup>
      </Grid>
      <Box padding={1} />
      <Divider />
      <Box padding={1} />
      <Grid container>
        <FlatButton onClick={() => art.removeText(text.id)} label='Remover' />
      </Grid>
    </Grid>
  )
}
