import { CSSTransition } from 'react-transition-group'
import './slideup.css'

export const CSSSlideUpOpacityAnimation = ({ children }) => {
  return (
    <CSSTransition in={true} appear={true} timeout={120} classNames='slideup'>
      {children}
    </CSSTransition>
  )
}
