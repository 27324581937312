import { Box, Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import MailOutlineOutlined from '@material-ui/icons/MailOutlineOutlined'
import { useState } from 'preact/hooks'
import { useHistory } from 'react-router'
import { RaisedButton } from '../common/button'
import { CustomTextField } from '../common/input'
import { LinearLoadingIndicator } from '../common/loading'
import Colors from '../config/colors'
import AppRoutes from '../navigation/routes'
import store from '../stores/root'
import { userService } from '../user/service'

export const SignupVerification = props => {
  const classes = useStyles()
  const history = useHistory()

  const { email } = props

  const [tk, setToken] = useState('')
  const [busy, setBusy] = useState(false)

  const handleValidate = async () => {
    if (!tk) {
      return store.layout.showSnackbar('O código informado é inválido', true)
    }

    try {
      setBusy(true)
      const { token, user } = await userService.confirmation(tk, email)
      if (token) {
        localStorage.setItem('token', token)
        localStorage.setItem('user', JSON.stringify(user))
      }
      history.push(AppRoutes.Offers)
    } catch (e) {
      store.layout.showSnackbar(e.message, true)
    } finally {
      setBusy(false)
    }
  }

  return (
    <Grid className={classes.root}>
      <div className={classes.img} />
      <Grid container direction='column' className={classes.sidebar}>
        <Grid item>
          <img src='/assets/logo_easyoffer_blue.svg' width={140} />
        </Grid>
        <Box padding={4} />
        {busy ? (
          <LinearLoadingIndicator label='Aguarde um pouquinho, estamos ativando sua conta...' />
        ) : (
          <Grid item>
            <Grid container>
              <MailOutlineOutlined className={classes.mailIcon} />
              <Typography variant='h5'>Confirme seu e-mail</Typography>
            </Grid>
            <Box padding={2} />
            <Divider />
            <Box padding={2} />
            <Typography variant='body1'>
              Para ativar sua conta, digite o código de verificação enviado no
              email: <strong>{email}</strong>
            </Typography>
            <Box padding={1} />
            <Typography variant='caption'>
              Verifique também sua caixa de SPAM ou Promoções
            </Typography>
            <Box padding={1} />
            <CustomTextField
              placeholder='Código enviado no e-mail'
              value={tk}
              onChange={e => setToken(e.target.value)}
              fullWidth
              type='phone'
              variant='outlined'
            />
            <Box padding={1} />
            <RaisedButton
              primary
              fullWidth
              label='Enviar Código'
              onClick={handleValidate}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles({
  root: {
    width: '100vw',
    height: '100vh',
    backgroundSize: 'cover',
    overflowY: 'scroll',
    background: `url(/assets/images/gallaxy.svg) no-repeat`
  },
  sidebar: {
    position: 'fixed',
    overflowY: 'auto',
    zIndex: 999,
    height: '100vh',
    maxWidth: 360,
    minWidth: 360,
    display: 'flex',
    flexDirection: 'column',
    background: Colors.white,
    padding: 24,
    '-moz-box-shadow': '2px 2px 5px 3px rgba(0,0,0,0.05)',
    '-webkit-box-shadow': '2px 2px 5px 3px rgba(0,0,0,0.05)',
    boxShadow: '2px 2px 5px 3px rgba(0,0,0,0.05)'
  },
  img: {
    background: 'url("/assets/images/atraia_clientes.svg") no-repeat',
    backgroundPosition: 'top',
    alignSelf: 'center',
    left: '45%',
    top: '25%',
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    maxWidth: 640
  },
  mailIcon: {
    margin: 4,
    marginRight: 16
  }
})
