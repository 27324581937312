import { Fab, Grid } from '@material-ui/core'
import { AddOutlined } from '@material-ui/icons'
import { Fragment } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { useHistory, useRouteMatch } from 'react-router'
import { LinearLoadingIndicator } from '../../common/loading'
import AppRoutes from '../../navigation/routes'
import { useSubscribed } from '../../navigation/subscribed'
import { captureWithScope } from '../../sentry'
import store from '../../stores/root'
import offerService from '../providers/offer'
import { CanvasScreen } from './canvas'
import { MobileContext } from './context'
import { MobileMiniMenu } from './mini'
import { OfferMobileToolbar } from './toolbar'

export const MobileOfferEditScreen = () => {
  useSubscribed()

  const history = useHistory()
  const { params } = useRouteMatch()
  const [offer, setOffer] = useState(null)

  useEffect(() => {
    if (window.$crisp) {
      window.$crisp.push(['do', 'chat:hide'])
    }

    const fetchOffer = async () => {
      try {
        let offer = await offerService.offer(params.key)

        if (!offer) {
          return history.push(AppRoutes.Offers)
        }

        if (offer.slides && offer.slides.length > 0) {
          const slide = offer.slides[0]
          offer = Object.assign(offer, slide)
          offer.slides = null
          await offerService.update(offer)
        }

        setOffer(offer)

        store.art.setOffer(offer)
        store.art.clearSelected()
      } catch (e) {
        captureWithScope(e)
      }
    }

    fetchOffer()
  }, [])

  const handleAdd = () => {
    store.mobile.setContent('arte')
    store.mobile.toggleMenu()
  }

  if (!offer) {
    return (
      <Grid
        container
        style={{
          height: '100vh',
          margin: '0 auto',
          width: '50vw',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <LinearLoadingIndicator label='Aguenta um pouquinho, estamos carregando o encarte...' />
      </Grid>
    )
  }

  return (
    <Fragment>
      <Fab
        style={{
          position: 'fixed',
          right: 16,
          bottom: 16,
          zIndex: 99
        }}
        onClick={handleAdd}
        color='primary'
      >
        <AddOutlined />
      </Fab>
      <Grid container direction='column'>
        <OfferMobileToolbar />
        <CanvasScreen offer={offer} />
        <MobileMiniMenu />
        <MobileContext />
      </Grid>
    </Fragment>
  )
}
