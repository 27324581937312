import { Box, Grid } from '@material-ui/core'
import { FileDropZone } from '../../common/dropzone'

export const AddProductHelp = ({ onProductSelected }) => {
  return (
    <Grid>
      <Box padding={1} />
      <FileDropZone onSelectFile={file => onProductSelected(file)} />
    </Grid>
  )
}
