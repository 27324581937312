export const userByEmailQuery = `
  query UserByEmail($email: String!) {
    userByEmail(email: $email) {
      _id
      _key
      _rev
      name
      email
      staff
      company {
        isTrial
        trialEnd
        retrial
        business
        subscription {
          status
        }
      }
    }
  }
`

export const userQuery = `
  query user($id: String!) {
    user(id: $id) {
      _id
      _key
      _rev
      name
      email
      taxId
    }
  }
`
