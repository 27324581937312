import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { TextFormatOutlined } from '@material-ui/icons'
import { Fragment } from 'preact'
import { useState } from 'preact/hooks'
import { customFonts } from './fonts'

export const IconFontSelector = ({ fontFamily, onUpdate }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Fragment>
      <IconButton
        title='Fonte do nome dos produtos'
        onClick={e => setAnchorEl(e.target)}
      >
        <TextFormatOutlined />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {customFonts.map(font => (
          <MenuItem
            selected={font === fontFamily}
            onClick={() => {
              onUpdate(font)
              setAnchorEl(null)
            }}
          >
            <span style={{ fontFamily: font }}>{font}</span>
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  )
}
