import { Box, GridList, GridListTile } from '@material-ui/core'
import LazyLoad from 'react-lazyload'
import store from '../stores/root'
import { getImageUrl } from '../utils/url'

export function TagList({ tags, elementRef }) {
  const height = 300 / 3

  return (
    <GridList cellHeight={height} gap={16} cols={3}>
      {tags &&
        tags.flat().map(
          t =>
            !t.discount && (
              <LazyLoad
                style={{ padding: 0 }}
                scrollContainer='#offer-content'
                key={t._id}
                unmountIfInvisible={true}
                debounce={5}
                height={height}
              >
                <GridListTile
                  key={t}
                  style={{ cursor: 'pointer' }}
                  onClick={() => store.art.setOfferProperty('tag', t)}
                >
                  <img
                    alt={t.name}
                    src={getImageUrl(t.imageURL, 180)}
                    style={{
                      width: height + 50,
                      margin: 8,
                      padding: 16,
                      objectFit: 'contain'
                    }}
                  />
                </GridListTile>
              </LazyLoad>
            )
        )}
      <Box padding={3} ref={elementRef} />
    </GridList>
  )
}
