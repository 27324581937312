import { Box, Divider, Grid, ListItem, Typography } from '@material-ui/core'
import { TextFieldsOutlined } from '@material-ui/icons'
import { Fragment } from 'preact'
import { useState } from 'preact/hooks'
import useSWR from 'swr'
import offerService from '../offer/providers/offer'
import store from '../stores/root'
import TextSearch from './search'

const textStyles = {
  title: {
    fontSize: 32,
    fontWeight: 900,
    fontFamily: 'Montserrat'
  },
  subtitle: {
    fontSize: 24,
    fontFamily: 'Montserrat'
  },
  body: {
    fontSize: 14,
    fontFamily: 'Roboto'
  }
}

export const TextScreen = () => {
  const [, setOriginal] = useState([])
  const [term, setTerm] = useState('')

  let { data: texts } = useSWR('texts/recent', offerService.texts)
  setOriginal(texts)

  if (term != '') {
    texts = texts.filter(t => t.text.toLowerCase().includes(term.toLowerCase()))
  }

  return (
    <Grid container direction='column'>
      <Grid item xs={12}>
        <Box padding={2}>
          <ListItem
            style={{ marginBottom: 8 }}
            button
            onClick={() =>
              store.art.addText({
                text: 'Digite seu título',
                fontSize: 90,
                fontStyle: 'bold',
                attrs: JSON.stringify({ width: 650 }),
                fontFamily: 'Montserrat'
              })
            }
          >
            <TextFieldsOutlined style={{ marginRight: 16, fontSize: 32 }} />
            <span style={textStyles.title}>Adicionar título</span>
          </ListItem>
          <ListItem
            style={{ marginBottom: 8 }}
            button
            onClick={() =>
              store.art.addText({
                text: 'Digite seu subtítulo',
                fontSize: 60,
                attrs: JSON.stringify({ width: 650 }),
                fontFamily: 'Montserrat'
              })
            }
          >
            <TextFieldsOutlined style={{ marginRight: 24, fontSize: 24 }} />
            <span style={textStyles.subtitle}>Adicionar subtítulo</span>
          </ListItem>
          <ListItem
            button
            onClick={() =>
              store.art.addText({
                text: 'Digite seu texto',
                fontSize: 32
              })
            }
          >
            <TextFieldsOutlined style={{ marginRight: 32, fontSize: 16 }} />
            <span style={textStyles.body}>Adicionar um textinho</span>
          </ListItem>
        </Box>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Box padding={2}>
          <TextSearch onSearch={setTerm} onClear={() => setTerm('')} />
        </Box>
        {texts && texts.length === 0 && (
          <Box padding={5}>
            <Typography variant='caption' align='center'>
              Você ainda não tem nenhum texto no seu histórico de encartes
            </Typography>
          </Box>
        )}
        <Box padding={2}>
          {texts &&
            texts.map(t => (
              <Fragment>
                <Grid
                  style={{
                    backgroundColor: '#eaeaea',
                    borderRadius: 8,
                    cursor: 'pointer'
                  }}
                  onClick={() => store.art.addText(t)}
                >
                  <Box padding={1}>
                    <Typography
                      style={{
                        fontFamily: t.fontFamily,
                        fontSize: t.fontSize * 0.5 < 16 ? 16 : t.fontSize * 0.5,
                        fontStyle: t.fontStyle,
                        textAlign: t.textAlign,
                        color: t.color
                      }}
                      variant='body1'
                    >
                      {t.text}
                    </Typography>
                  </Box>
                </Grid>
                <Box padding={1} />
              </Fragment>
            ))}
        </Box>
      </Grid>
    </Grid>
  )
}
