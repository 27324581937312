import { CssBaseline } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Provider } from 'mobx-preact'
import { useEffect, useErrorBoundary } from 'preact/hooks'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { RecoverPassword } from './auth/recover/password'
import { VerifyPasswordReset } from './auth/verify/verify'
import SnackbarController from './common/snackbar'
import theme from './config/theme'
import { Home } from './home'
import './index.css'
import { LoginScreen } from './login/screen'
import { ProtectedRoute } from './navigation/protected'
import AppRoutes from './navigation/routes'
import { captureWithScope } from './sentry'
import { SignupConfirmation } from './signup/confirmation'
import { SignupScreen } from './signup/signup'
import store from './stores/root'
import { RetrialScreen } from './subscription/retrial'

export const App = () => {
  const [e] = useErrorBoundary(captureWithScope)

  useEffect(() => {
    if (window.$crisp) {
      window.$crisp.push([
        'on',
        'message:received',
        () => window.$crisp.push(['do', 'chat:show'])
      ])
      window.$crisp.push([
        'on',
        'chat:closed',
        () => {
          window.$crisp.push(['do', 'chat:hide'])
        }
      ])
    }
  }, [])

  if (e) {
    return null
  }

  return (
    <Provider {...store}>
      {''}
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Switch>
            <Route exact path={AppRoutes.Login}>
              <LoginScreen />
            </Route>
            <Route exact path={AppRoutes.Signup}>
              <SignupScreen />
            </Route>
            <Route exact path={AppRoutes.Confirmation}>
              <SignupConfirmation />
            </Route>
            <Route exact path={AppRoutes.Retrial}>
              <RetrialScreen />
            </Route>
            <Route exact path={AppRoutes.VerifyPassword}>
              <VerifyPasswordReset />
            </Route>
            <Route exact path={AppRoutes.RecoverPassword}>
              <RecoverPassword />
            </Route>
            <ProtectedRoute path={'/'}>
              <Home />
            </ProtectedRoute>
          </Switch>
        </Router>
        <SnackbarController />
      </MuiThemeProvider>
    </Provider>
  )
}
