const AppRoutes = {
  Account: '/conta',
  AccountSecurity: '/conta/seguranca',
  AccountSubscription: '/conta/assinatura',
  Confirmation: '/confirmation',
  EditOffer: '/ofertas/:key',
  EditOfferMobile: '/ofertas/mobile/:key',
  Invoices: '/assinar/cobrancas',
  Login: '/login',
  Offers: '/ofertas',
  OfferTags: '/ofertas/:key/etiquetas',
  OfferTexts: '/ofertas/:key/textos',
  RecoverPassword: '/recuperar-senha',
  Signup: '/registrar',
  SubscriptionSuccess: '/assinar/sucesso',
  SubscriptionPending: '/assinar/aguardando',
  SubscriptionUnpaid: '/assinar/unpaid',
  SubscriptionCanceled: '/assinar/cancelado',
  Subscribe: '/assinar',
  Support: '/suporte',
  VerifyPassword: '/verificar/:hash',
  Retrial: '/queromais',
  Demo: '/demo'
}

export default AppRoutes
