import { Grid, IconButton, Toolbar } from '@material-ui/core'
import { FormatBold, RedoOutlined, UndoOutlined } from '@material-ui/icons'
import { FlatButton } from '../common/button'
import { ColorPopover } from '../common/popover'
import { IconFontSelector } from '../common/smallfont'
import { TagToggle } from '../common/tag'
import Colors from '../config/colors'
import store from '../stores/root'

export const CanvasToolbar = ({ offer }) => {
  const { art } = store

  return (
    <Toolbar style={{ background: Colors.canvasBackground }}>
      <Grid container justify='space-between'>
        <Grid container item>
          <ColorPopover
            color={offer.textColor}
            onUpdate={color => art.setOfferProperty('textColor', color)}
          />
          <IconFontSelector
            fontFamily={offer.textFamily}
            onUpdate={font => art.setOfferProperty('textFamily', font)}
          />
          <IconButton
            onClick={() =>
              art.setOfferProperty(
                'textStyle',
                offer.textStyle === 'normal' ? 'bold' : 'normal'
              )
            }
          >
            <FormatBold />
          </IconButton>
          <TagToggle showTags={offer.showTags} />
          <IconButton
            disabled={art.undoStack.length === 0 || art.current < 1}
            style={{ marginLeft: 32 }}
            onClick={art.undo}
          >
            <UndoOutlined />
          </IconButton>
          <IconButton
            disabled={
              art.undoStack.length === 0 ||
              art.current === art.undoStack.length - 1
            }
            onClick={art.redo}
          >
            <RedoOutlined />
          </IconButton>
          <div style={{ flex: 1 }} />
          <Grid style={{ marginTop: 4 }} item>
            <FlatButton
              onClick={() => store.layout.toggleDrawer(true)}
              label='Copiar Produtos'
            />
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  )
}
