import { Box, Grid, LinearProgress, Typography } from '@material-ui/core'
import { ErrorOutline } from '@material-ui/icons'
import { Fragment } from 'preact'
import { useState } from 'preact/hooks'
import { FlatButton, RaisedButton } from '../../common/button'
import { FileDropZone } from '../../common/dropzone'
import Colors from '../../config/colors'
import { API_ROOT_URL } from '../../http/hostname'
import store from '../../stores/root'
import uploadImage from '../../utils/upload'
import logoService from '../providers/logo'

export const ElementUpload = ({ onElementUploaded }) => {
  const [originalImage, setOriginalImage] = useState(null)
  const [imageError, setImageError] = useState('')
  const [saving, setSaving] = useState(false)
  const [removing, setRemoving] = useState(false)
  const [removed, setRemoved] = useState(false)
  const [removeError, setRemoveError] = useState(false)

  const handleFileSelect = async e => {
    setOriginalImage(e)
  }

  const removeBG = async () => {
    if (!originalImage) return

    setRemoving(true)
    try {
      const image = await fetch(originalImage).then(r => r.blob())

      const formData = new FormData()
      formData.append('file', image)

      const response = await fetch(`${API_ROOT_URL}/removebg`, {
        headers: {
          Authorization: localStorage.getItem('token')
        },
        method: 'POST',
        body: formData
      }).then(r => r.text())

      setOriginalImage(`data:image/png;base64,${response}`)
      setRemoved(true)
    } catch (error) {
      if (error.message === 'limit_on_removal_achieved') {
        return setRemoveError('Limite de remoções mensais atingida!')
      }
      alert('Erro ao remover fundo! Entre em contato com o nosso suporte')
      console.error(error)
    } finally {
      setRemoving(false)
    }
  }

  const handleSaveProduct = async () => {
    try {
      if (!originalImage) return

      setSaving(true)

      const image = await fetch(originalImage).then(r => r.blob())
      const imageURL = await uploadImage(image, store.art.offer.company)

      await logoService.update({ imageURL })
      setSaving(false)
      setImageError(false)
      setOriginalImage(null)
      onElementUploaded()
    } catch (e) {
      setImageError('Não foi possível salvar a imagem. Tente novamente')
    } finally {
      setSaving(false)
    }
  }

  if (saving) {
    return (
      <Grid container justify='center'>
        <Grid item>
          <Box padding={4}>
            <LinearProgress />
            <Typography align='center' variant='caption'>
              Salvando elemento...
            </Typography>
          </Box>
        </Grid>
      </Grid>
    )
  }

  return (
    <Fragment>
      {!originalImage && <FileDropZone onSelectFile={handleFileSelect} />}
      <Box padding={1}>
        {imageError && (
          <Grid container justify='center'>
            <Box padding={4} />
            <Grid container justify='center' item xs={12}>
              <ErrorOutline color='error' />
            </Grid>
            <Box padding={1} />
            <Grid container justify='center' item xs={12}>
              <Typography align='center' variant='body2'>
                Formato de imagem não suportado, utilize outra imagem.
              </Typography>
            </Grid>
          </Grid>
        )}
        {originalImage && (
          <Grid container direction='column' spacing={3} alignItems='center'>
            <Grid
              style={{
                backgroundColor: Colors.textInputColor,
                borderRadius: 8
              }}
              container
              justify='center'
              item
            >
              <Grid
                style={{ width: 185, height: 185 }}
                container
                justify='center'
                alignItems='center'
                item
                xs={12}
              >
                {saving && (
                  <Grid
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Grid item>
                      <Box padding={4}>
                        <LinearProgress />
                        <Typography align='center' variant='caption'>
                          Preparando imagem para encarte...
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                )}
                {originalImage && !removing && (
                  <img
                    style={{
                      width: 185,
                      maxHeight: 185,
                      objectFit: 'contain'
                    }}
                    src={originalImage}
                  />
                )}
                {removing && (
                  <Grid
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Grid item>
                      <Box padding={4}>
                        <LinearProgress />
                        <Typography align='center' variant='caption'>
                          Removendo o fundo...
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Box padding={2} />
              {!removeError && !removed && (
                <FlatButton
                  primary
                  onClick={removeBG}
                  label='Remover Fundo da Imagem'
                />
              )}
              {removeError && (
                <Typography align='center' color='error' variant='caption'>
                  {removeError}
                </Typography>
              )}
            </Grid>
            {originalImage && (
              <Grid style={{ padding: 0 }} container item direction='column'>
                <Box padding={1} />
                <RaisedButton
                  fullWidth
                  onClick={handleSaveProduct}
                  primary
                  label='Salvar Imagem'
                />
                <Box padding={1} />
                <FlatButton
                  fullWidth
                  onClick={() => {
                    setOriginalImage(null)
                  }}
                  primary
                  label='Cancelar'
                />
              </Grid>
            )}
          </Grid>
        )}
      </Box>
    </Fragment>
  )
}
