import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Typography,
  Box,
  Button,
  IconButton
} from '@material-ui/core'
import AppRoutes from '../../navigation/routes'
import { FileCopyOutlined } from '@material-ui/icons'
import { useHistory } from 'react-router'

export const VerifyPasswordResetContent = props => {
  const classes = useStyles()
  const history = useHistory()

  const { password, error } = props

  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.formContainer}>
        <Card className={classes.form}>
          <CardHeader title='Recuperar Senha' />
          {error && (
            <Grid container justify='center'>
              <Box padding={2}>
                <Typography variant='subtitle1' color='error'>
                  {error}
                </Typography>
              </Box>
            </Grid>
          )}
          {password && (
            <CardContent>
              <Typography variant='body1'>
                A nova senha para a sua conta é:
              </Typography>
              <Box padding={2} />
              <Typography variant='h6'>
                {password}
                {window.navigator && window.navigator.clipboard && (
                  <IconButton
                    color='primary'
                    onClick={() =>
                      window.navigator.clipboard.writeText(password)
                    }
                  >
                    <FileCopyOutlined />
                  </IconButton>
                )}
              </Typography>
              <Box padding={2} />
              <Typography variant='body1'>
                Por favor copie a nova senha and faça o login na sua conta.
                Acesse o menu do usuário e altere a sua senha.
              </Typography>
              <Box padding={2} />
              <Button
                onClick={() => history.push(AppRoutes.Login)}
                disableElevation
                color='primary'
                variant='contained'
                size='large'
                fullWidth
              >
                Logar
              </Button>
            </CardContent>
          )}
        </Card>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    height: '100vh',
    backgroundImage:
      'url(https://berrytech-images.s3.sa-east-1.amazonaws.com/background_easyoffer_login_2_62bffaffb5.png)'
  },
  formContainer: {
    marginTop: '10%'
  },
  form: {
    maxWidth: 570,
    marginTop: '5%',
    padding: theme.spacing() * 2
  }
}))
