import { ListItem, ListItemAvatar, Typography } from '@material-ui/core'
import Colors from '../config/colors'

export const MenuButton = props => {
  const { icon, onClick, label } = props
  return (
    <ListItem button onClick={onClick} dense>
      <ListItemAvatar
        style={{
          color: Colors.textDescriptionColor,
          minWidth: 42,
          paddingTop: 4
        }}
      >
        {icon}
      </ListItemAvatar>
      <Typography variant='button'>{label}</Typography>
    </ListItem>
  )
}
