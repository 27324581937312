import { Box, Grid, LinearProgress, makeStyles } from '@material-ui/core'
import { SaveAltOutlined } from '@material-ui/icons'
import { captureException } from '@sentry/browser'
import { DateTime } from 'luxon'
import { inject, observer } from 'mobx-preact'
import { useState } from 'preact/hooks'
import { useHistory } from 'react-router'
import { BackButton } from '../../common/back'
import { RaisedButton } from '../../common/button'
import { downloadProvider } from '../../download/providers/download'
import AppRoutes from '../../navigation/routes'
import store from '../../stores/root'
import { TemplateDrawer } from '../../template/drawer'
import { dataURLtoBlob } from '../../utils/toblob'
import uploadImage from '../../utils/upload'
import { ChangesSaved, UnsavedChanges } from './saved'

export const OfferEditToolbarComponent = ({ art }) => {
  const classes = useStyles()
  const history = useHistory()

  const [downloading, setDownloading] = useState(false)

  const downloadCanvas = async () => {
    setDownloading(true)

    const resolution = 1 / art.canvas.currentScale
    const dataUrl = store.art.canvas.toDataURL({
      mimeType: 'image/jpeg',
      quality: 1,
      pixelRatio: resolution
    })

    try {
      const imageURL = await uploadImage(
        dataURLtoBlob(dataUrl),
        null,
        'download'
      )

      if (imageURL) {
        await downloadProvider.update({
          imageURL,
          offer: art.offer._id,
          format: art.offer.format.code,
          elements: art.offer.elements?.length || 0,
          texts: art.offer.texts?.length || 0,
          products: art.offer.products?.length || 0
        })
      }
    } catch (e) {
      captureException(e)
    } finally {
      setDownloading(false)
    }

    const link = document.createElement('a')
    link.download = `encarte-${DateTime.local().toLocaleString()}.jpg`
    link.href = dataUrl
    document.body.appendChild(link)
    link.click()
  }

  // const handleSave = async () => {
  //   setDownloading(true)

  //   const template = Object.assign({}, store.art.offer)
  //   delete template._id
  //   delete template._key
  //   delete template._rev

  //   try {
  //     const data = store.art.canvas.toDataURL({ pixelDensity: 3 })
  //     const img = await fetch(data).then(r => r.blob())

  //     if (!data) {
  //       return store.layout.showSnackbar('Erro ao converter imagem', true)
  //     }

  //     const url = await uploadImage(img, null, 'preview')

  //     if (!url) {
  //       return store.layout.showSnackbar('Erro ao enviar imagem', true)
  //     }

  //     template.preview = url
  //     await templateProvider.userUpdate(template)
  //     store.layout.showSnackbar('Template salvo com sucesso')
  //     mutate([store.art.offer.format.code, 'saves'])
  //     history.push(
  //       `${AppRoutes.EditOffer.replace(':key', store.art.offer._key)}/salvos`
  //     )
  //   } catch (e) {
  //     captureWithScope(e)
  //     store.layout.showSnackbar('Erro ao salvar o template', true)
  //   } finally {
  //     setDownloading(false)
  //   }
  // }

  return (
    <div className={classes.root}>
      <Grid container justify='flex-end'>
        {downloading ? (
          <Grid style={{ marginTop: 10 }} item xs={2}>
            <Box padding={2}>
              <LinearProgress />
            </Box>
          </Grid>
        ) : (
          <Grid
            container
            item
            style={{ marginTop: 4, marginRight: 16, marginLeft: 16 }}
          >
            <Grid item>
              <BackButton onClick={() => history.push(AppRoutes.Offers)} />
            </Grid>
            <div style={{ flex: 1 }} />
            <Box padding={1.5} style={{ marginRight: 16 }}>
              {art.update != null ? <UnsavedChanges /> : <ChangesSaved />}
            </Box>
            <Grid item>
              <RaisedButton
                style={{ marginTop: 4 }}
                primary
                startIcon={<SaveAltOutlined />}
                onClick={downloadCanvas}
                label='Baixar Encarte'
              />
            </Grid>
            <TemplateDrawer />
          </Grid>
        )}
      </Grid>
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    height: 56,
    display: 'flex',
    width: '100vw',
    zIndex: 100,
    '-webkit-box-shadow': '3px 3px 10px -5px rgba(0,0,0,0.24)',
    '-moz-box-shadow': '3px 3px 10px -5px rgba(0,0,0,0.24)',
    'box-shadow': '3px 3px 10px -5px rgba(0,0,0,0.24)'
  }
})

export const OfferEditToolbar = inject(
  'art',
  'auth'
)(observer(OfferEditToolbarComponent))
