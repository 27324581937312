import { GridList, GridListTile } from '@material-ui/core'
import LazyLoad from 'react-lazyload'
import { getImageUrl } from '../../utils/url'

export const BackgroundList = props => {
  const { backgrounds, art, format } = props
  const height = (375 / 3) * format.ratio

  return (
    backgrounds && (
      <GridList cellHeight={height} cols={3} style={{ overflow: 'hidden' }}>

        {backgrounds &&
          backgrounds.flat().map(b => (
            <LazyLoad
              scrollContainer='#offer-content'
              key={b._id}
              unmountIfInvisible={true}
              debounce={5}
              height={height}
            >
              <GridListTile
                style={{ cursor: 'pointer' }}
                onClick={() => art.setOfferProperty('background', b)}
              >
                <img src={getImageUrl(b.imageURL, 180)} />
              </GridListTile>
            </LazyLoad>
          ))}
      </GridList>
    )
  )
}
