import { Grid, makeStyles } from '@material-ui/core'
import { inject, observer } from 'mobx-preact'
import { useCallback, useEffect, useState } from 'preact/hooks'
import { useHistory } from 'react-router'
import Colors from '../config/colors'
import AppRoutes from '../navigation/routes'
import { OfferHelpButton } from '../offer/edit/help'
import { captureWithScope } from '../sentry'
import { ContextMenu } from './menu/context'
import { Canvas } from './preview'
import { CanvasToolbar } from './toolbar'
import { CanvasZoom } from './zoom'

const useStyles = makeStyles({
  canvas: {
    display: 'flex',
    justifyContent: 'center',
    overflowY: 'auto',
    overflowX: 'auto',
    height: 'calc(100vh - 64px)',
    backgroundColor: Colors.canvasBackground
  }
})

export const CanvasScreenComponent = ({ art }) => {
  const history = useHistory()

  const { offer, selectedElement } = art
  const [dimensions, setDimensions] = useState({})

  const classes = useStyles()
  const [canvas, setCanvas] = useState(null)

  useEffect(() => {
    let isCanceled = false

    const initCanvas = () => {
      if (!isCanceled) {
        try {
          if (!offer.format) {
            history.push(AppRoutes.Offers)
          }

          const { width = 1080, height = 1080 } = offer.format

          const konva = new Canvas(offer, {
            color: '#ffffff',
            container: 'art-canvas',
            width,
            height
          })
          konva.init()
          art.setCanvas(konva)

          if (isCanceled) return
          setCanvas(konva)
        } catch (e) {
          captureWithScope(e)
        }
      }
    }

    if (!canvas) {
      initCanvas()

      const content = document.getElementsByClassName('konvajs-content')
      if (!isCanceled) {
        setDimensions(content[0] || {})
      }
    }

    return () => {
      isCanceled = true
    }
  }, [offer, selectedElement.element])

  const canvasMenu = useCallback(<ContextMenu konva={dimensions} />, [
    art.selectedElement,
    art.multiSelect
  ])

  const canvasToolbar = useCallback(
    <CanvasToolbar art={art} offer={offer} canvas={canvas} />,
    [
      offer.textColor,
      offer.textFamily,
      offer.textStyle,
      offer.showTags,
      art.undoStack
    ]
  )
  const canvasZoom = useCallback(<CanvasZoom canvas={canvas} />, [
    canvas?.currentScale
  ])

  if (!offer) {
    return
  }

  return (
    <Grid
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: 'calc(100vw - 500px)'
      }}
    >
      {canvasMenu}
      {canvasToolbar}
      {canvasZoom}
      <OfferHelpButton />
      <div className={classes.canvas}>
        <div id='art-canvas' />
      </div>
    </Grid>
  )
}

export const CanvasScreen = inject('art')(observer(CanvasScreenComponent))
