import { useEffect, useState } from 'preact/hooks'

export const renderGoogleButton = context => {
  let accs = google.accounts
  const el = document.getElementById('google-button')

  if (!!el && !!accs) {
    accs.id.renderButton(el, {
      logo_alignment: 'left',
      locale: 'pt-BR',
      shape: 'rectangular',
      size: 'large',
      type: 'standard',
      width: 355,
      text: context === 'signup' ? 'signup_with' : 'signin_with'
    })
  }
}

export const useGoogleSDK = context => {
  const [user, setUser] = useState({})

  const initializeSDK = () => {
    let accs = google.accounts

    accs.id.initialize({
      context,
      client_id:
        '1073661042294-t60adhh5uqko2a8tutvjr2qr19qafvlu.apps.googleusercontent.com',
      callback: response => {
        const decoded = parseJwt(response.credential)

        const { name, picture, sub, email, hd, email_verified } = decoded

        const isGmail = !!email.includes('@gmail.com') && !!email_verified
        const isGSuite =
          !!!email.includes('@gmail.com') && !!hd && email_verified

        if (!isGmail && !isGSuite) {
          toast.error(
            'Não foi possível autenticar com o Google. Fale com nosso suporte.'
          )
        } else {
          const credentials = {
            id: sub,
            name,
            avatar: picture,
            email,
            provider: 'google',
            token: response.credential
          }
          setUser(credentials)
        }
      }
    })

    accs.id.prompt()
    renderGoogleButton(context)
  }

  useEffect(() => {
    initializeGoogleSDK(initializeSDK)
  }, [])

  return user
}

const initializeGoogleSDK = callback => {
  if (!callback) throw new Error('Invalid initialization callback')

  const existingScript = document.getElementById('googleLogin')

  if (!existingScript) {
    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.id = 'googleLogin'
    document.body.appendChild(script)
    script.onload = callback
  } else {
    callback()
  }
}

function parseJwt(token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

const initializeFacebookSDK = callback => {
  if (!callback) throw new Error('Invalid initialization callback')

  const existingScript = document.getElementById('facebookSignin')

  if (!existingScript) {
    const script = document.createElement('script')
    script.src = 'https://connect.facebook.net/pt_BR/sdk.js'
    script.id = 'facebookSignin'
    document.body.appendChild(script)
    script.onload = callback
  } else {
    callback()
  }
}

export const useFacebookSDK = () => {
  useEffect(() => {
    initializeFacebookSDK(configureFacebookSDK)
  }, [])

  return fetchFacebookUserInformation
}

export const configureFacebookSDK = () => {
  window.FB.init({
    appId: '225513812136456',
    autoLogAppEvents: true,
    xfbml: false,
    cookie: true,
    version: 'v9.0',
    status: true
  })
}

export const fetchFacebookUserInformation = async () => {
  const auth = await new Promise((resolve, reject) => {
    window.FB.login(response => {
      if (response.status === 'connected') {
        return resolve(response.authResponse)
      }
      reject(new Error('Solicitação de login com erro ou cancelada'))
    })
  })
  return new Promise((resolve, reject) => {
    window.FB.api(
      '/me',
      { locale: 'pt_BR', fields: 'name, email, picture' },
      async profile => {
        if (profile) {
          if (!profile.email) {
            return reject(
              new Error(
                'Ops! O seu e-mail no Facebook não foi confirmado, não é possível logar com essa conta.'
              )
            )
          }
          return resolve({
            id: auth.userID,
            name: profile.name,
            photoURL: profile.picture?.data?.url,
            email: profile.email,
            providerId: 'facebook',
            idToken: auth.accessToken
          })
        }
        reject(new Error('Error ao localizar os dados do usuário no Facebook'))
      }
    )
  })
}
