export const updatePasswordMutation = `
  mutation pdatePassword($userId: String!, $password: String!){
    updatePassword(userId: $userId, password: $password) {
      _id
      _key
      _rev
    }
  }
`

export const updateUserMutation = `
  mutation UpdateUser($data: UserInput!) {
    updateUser(data: $data) {
      _id
      _key
      _rev
    }
  }
`

export const updateBusinessMutation = `
  mutation UpdateBusiness($business: [String]!) {
    updateBusiness(business: $business)
  }
`
