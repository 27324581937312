import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core'
import { Fragment } from 'preact'

export const RecoverPasswordForm = props => {
  const { email, setEmail, onSubmit, success, error } = props

  const classes = useStyles()
  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.formContainer}>
        <Card className={classes.form}>
          <CardHeader title='Recuperar Senha' />
          <Divider />
          {success ? (
            <CardContent>
              <Typography variant='body1'>
                Se esta conta existir, uma mensagem com um link de recuperação
                de senha será enviada para o e-mail informado.
              </Typography>
            </CardContent>
          ) : (
            <CardContent>
              <Typography variant='body1'>
                Não se preocupe, acontece nas melhores famílias
              </Typography>
              {error && (
                <Fragment>
                  <Box padding={2} />
                  <Typography variant='subtitle1' color='error'>
                    {error}
                  </Typography>
                </Fragment>
              )}
              <Box padding={2} />
              <Typography variant='subtitle2'>Seu e-mail</Typography>
              <Box padding={1} />
              <TextField
                value={email}
                onChange={e => setEmail(e.target.value)}
                variant='outlined'
                fullWidth
              />
              <Box padding={2} />
              <Button
                onClick={onSubmit}
                disableElevation
                fullWidth
                variant='contained'
                color='primary'
                size='large'
              >
                Enviar e-mail de recuperação
              </Button>
            </CardContent>
          )}
        </Card>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    height: '100vh',
    backgroundImage:
      'url(https://berrytech-images.s3.sa-east-1.amazonaws.com/background_easyoffer_login_2_62bffaffb5.png)'
  },
  formContainer: {
    marginTop: '10%'
  },
  form: {
    maxWidth: 570,
    marginTop: '5%',
    padding: theme.spacing() * 2
  }
}))
