import { useHistory, useRouteMatch } from 'react-router'
import store from '../stores/root'
import { isTrialExpired } from '../subscription/common/expired'
import { subscriptionProvider } from '../subscription/providers/subscription'
import AppRoutes from './routes'

export const useSubscribed = async () => {
  const auth = store.auth

  const history = useHistory()
  const { path } = useRouteMatch()

  const company = auth.user.company

  if (company.isTrial) {
    if (!isTrialExpired(company.trialEnd) && path === AppRoutes.Subscribe) {
      history.replace(AppRoutes.Offers, true)
    }
    if (isTrialExpired(company.trialEnd)) {
      if (path !== AppRoutes.Subscribe) {
        history.replace(AppRoutes.Subscribe, true)
      }
    }
  }

  if (auth.subscription) {
    const status = auth.subscription.status
    switch (status) {
      case 'unpaid':
        if (!path.includes('unpaid')) {
          history.push(AppRoutes.SubscriptionUnpaid)
        }
        break
      case 'incomplete':
        if (!path.includes('unpaid')) {
          history.push(AppRoutes.SubscriptionUnpaid)
        }
        break
      case 'incomplete_expired':
        await subscriptionProvider.resetSubscription()
        if (!path.includes('assinar')) {
          history.push(AppRoutes.Subscribe)
        }
        break
      case 'past_due':
        if (!path.includes('unpaid')) {
          history.push(AppRoutes.SubscriptionUnpaid)
        }
        break
      case 'canceled':
        await subscriptionProvider.resetSubscription()
        if (!path.includes('assinar')) {
          history.push(AppRoutes.Subscribe)
        }
        break
    }

    if (window.location.href.includes('assinar') && status === 'active') {
      history.push(AppRoutes.Offers, true)
    }
  }

  if (!company.isTrial && !auth.subscription) {
    if (path !== AppRoutes.Subscribe) {
      history.replace(AppRoutes.Subscribe, true)
    }
  }
}
