import { useEffect, useState } from 'preact/hooks'
import { useHistory } from 'react-router'
import authService from '../auth/providers/auth'
import AppRoutes from '../navigation/routes'
import { captureWithScope } from '../sentry'
import store from '../stores/root'
import { useGoogleSDK } from '../utils/providers'
import { isValidEmail } from '../utils/validation'

export const useLoginState = email => {
  const history = useHistory()

  const [credentials, setCredentials] = useState({
    email: email || '',
    password: ''
  })
  const [validated, setValidated] = useState(false)

  const [busy, setBusy] = useState(false)
  const [error, setError] = useState('')
  const [emailSignin, setEmailSignin] = useState(false)
  const [verificationSent, setVerificantionSent] = useState(false)

  if (window.$crisp) {
    window.$crisp.push(['do', 'chat:show'])
  }

  const onLogin = async () => {
    if (!credentials.email || !credentials.password) {
      return setValidated(true)
    }

    if (!isValidEmail(credentials.email)) {
      store.layout.showSnackbar('Digite um e-mail válido', true)
    }

    try {
      setBusy(true)
      const { token, user } = await authService.authenticate(credentials)

      if (token) {
        localStorage.setItem('user', JSON.stringify(user))
        localStorage.setItem('token', token)
        history.push(AppRoutes.Offers)
      }
    } catch (e) {
      captureWithScope(e)
      setBusy(false)

      if (e.status == 410) {
        setVerificantionSent(true)
        setError(
          'Ops, sua conta ainda não foi confirmada. Clique no link de confirmação enviado no seu e-mail'
        )
      }

      store.layout.showSnackbar(e.message, true)
    }
  }

  return {
    busy,
    setBusy,
    credentials,
    setCredentials,
    error,
    setError,
    onLogin,
    validated,
    emailSignin,
    setEmailSignin,
    verificationSent
  }
}
