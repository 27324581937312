export const getImageUrl = (url, size) => {
  if (!url) return ''
  const idx = url.lastIndexOf('/')
  const fileName = url.substring(idx + 1)
  const parts = fileName.split('.')
  const file = parts[0]
  const ext = parts[1]

  if (size) {
    return `https://d2bxayf79dnwor.cloudfront.net/${file}-${
      size || 180
    }w.${ext}`
  }
  return `https://d2bxayf79dnwor.cloudfront.net/${file}.${ext}`
}
