import { GridList, GridListTile, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  image: {
    cursor: 'pointer',
    objectFit: 'scale-down'
  }
})

export const TemplateList = props => {
  const { onSelect } = props
  const classes = useStyles()

  const { templates, format } = props
  const height = (375 / 3) * format.ratio

  return (
    templates && (
      <GridList cellHeight={height} cols={3}>
        {templates &&
          templates.map(template => (
            <GridListTile
              className={classes.image}
              onClick={() => onSelect(template)}
              key={template._id}
            >
              <img title={template.name} src={template.preview} />
            </GridListTile>
          ))}
      </GridList>
    )
  )
}
