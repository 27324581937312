import { useEffect } from 'preact/hooks'
import { useHistory } from 'react-router'
import authService from '../../auth/providers/auth'
import AppRoutes from '../../navigation/routes'
import { captureWithScope } from '../../sentry'
import store from '../../stores/root'
import { useGoogleSDK } from '../../utils/providers'

export function GoogleSigninButton({ context, onStart, onError }) {
  const history = useHistory()
  const user = useGoogleSDK(context)

  const authenticate = async (googleUser) => {
    onStart()
    try {
      const { token, user } = await authService.authenticate({
        id: googleUser.id,
        name: googleUser.name,
        photoURL: googleUser.avatar,
        email: googleUser.email,
        providerId: 'google',
        idToken: googleUser.token
      })

      if (token && user) {
        localStorage.setItem('user', JSON.stringify(user))
        localStorage.setItem('token', token)
        history.push(AppRoutes.Offers)
      }
    } catch (e) {
      console.log(e)
      onError(e)
      captureWithScope(e)

      if (e.error == 'popup_closed_by_user') {
        return store.layout.showSnackbar('Login cancelado pelo usuário', true)
      }

      if (e.error == 'access_denied') {
        return store.layout.showSnackbar('Você não autorizou o acesso', true)
      }

      if (e.error == 'immediate_failed') {
        return store.layout.showSnackbar('Erro desconhecido', true)
      }

      store.layout.showSnackbar(e.error, true)
    }
  }

  useEffect(() => {
    if (user.email) {
      authenticate(user)
    }
  }, [JSON.stringify(user)])

  return <div id='google-button' />
}
