import { Box, Grid, IconButton, Toolbar } from '@material-ui/core'
import { KeyboardArrowLeftOutlined, SaveAltOutlined } from '@material-ui/icons'
import { captureException } from '@sentry/browser'
import { useState } from 'preact/hooks'
import { useHistory } from 'react-router'
import { FlatButton } from '../../common/button'
import { LinearLoadingIndicator } from '../../common/loading'
import store from '../../stores/root'

export const OfferMobileToolbar = () => {
  const history = useHistory()
  const [downloading, setDownloading] = useState(false)

  const downloadCanvas = () => {
    setDownloading(true)
    store.art.clearSelected()
    try {
      setTimeout(() => {
        const link = document.createElement('a')
        link.download = `encarte.png`
        link.href = store.art.canvas.toDataURL({ pixelRatio: 5 })
        document.body.appendChild(link)
        link.click()
        setDownloading(false)
      }, 1200)
    } catch (e) {
      setDownloading(false)
      captureException(e)
    }
  }
  return (
    <Toolbar>
      <Grid container>
        <IconButton onClick={() => history.go(-1)}>
          <KeyboardArrowLeftOutlined />
        </IconButton>
        <div style={{ flex: 1 }} />
        <Grid item>
          {downloading ? (
            <Box padding={2} style={{ width: 150 }}>
              <LinearLoadingIndicator />
            </Box>
          ) : (
            <FlatButton
              style={{ marginTop: 4 }}
              primary
              startIcon={<SaveAltOutlined />}
              onClick={downloadCanvas}
              label='Baixar'
            />
          )}
        </Grid>
      </Grid>
    </Toolbar>
  )
}
