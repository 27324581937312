import { Tab, Tabs } from '@material-ui/core'
import { useHistory } from 'react-router'
import AppRoutes from '../navigation/routes'

export function AccountTabs ({ url }) {
  const history = useHistory()

  return (
    <Tabs
      value={url}
      indicatorColor='primary'
      textColor='primary'
      onChange={(_, url) => history.push(url)}
    >
      <Tab value={AppRoutes.Account} label='Perfil' />
      <Tab value={AppRoutes.AccountSecurity} label='Segurança' />
    </Tabs>
  )
}
