import { Grid, makeStyles } from '@material-ui/core'
import { inject, observer } from 'mobx-preact'
import { useEffect, useState } from 'preact/hooks'
import { useHistory } from 'react-router'
import { Canvas } from '../../canvas/preview'
import Colors from '../../config/colors'
import AppRoutes from '../../navigation/routes'
import { captureWithScope } from '../../sentry'

const useStyles = makeStyles({
  canvas: {
    display: 'flex',
    justifyContent: 'center',
    overflowY: 'auto',
    overflowX: 'auto',
    height: 'calc(100vh - 56px)',
    backgroundColor: Colors.canvasBackground
  }
})

export const CanvasScreenComponent = ({ art }) => {
  const history = useHistory()

  const { offer, selectedElement } = art

  const classes = useStyles()
  const [canvas, setCanvas] = useState(null)

  useEffect(() => {
    let isCanceled = false

    const initCanvas = () => {
      if (!isCanceled) {
        try {
          if (!offer.format) {
            history.push(AppRoutes.Offers)
          }

          const { width = 1080, height = 1080 } = offer.format

          const konva = new Canvas(
            offer,
            {
              color: '#ffffff',
              container: 'art-canvas',
              width,
              height
            },
            true
          )
          konva.init()
          art.setCanvas(konva)

          if (isCanceled) return
          setCanvas(konva)
        } catch (e) {
          captureWithScope(e)
        }
      }
    }

    if (!canvas) {
      initCanvas()

      //const content = document.getElementsByClassName('konvajs-content')
      if (!isCanceled) {
        //setDimensions(content[0] || {})
      }
    }

    return () => {
      isCanceled = true
    }
  }, [offer, selectedElement.element])

  if (!offer) {
    return
  }

  return (
    <Grid
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100vw'
      }}
    >
      <div className={classes.canvas}>
        <div id='art-canvas' />
      </div>
    </Grid>
  )
}

export const CanvasScreen = inject('art')(observer(CanvasScreenComponent))
