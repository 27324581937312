import useSWR from 'swr'
import { TagList } from './list'
import tagService from './providers/tag'

export const TagScreen = () => {
  const { data: tags = [] } = useSWR(`tags`, tagService.tags)
  const normalTags = tags.filter(t => !t.discount)

  return (
    <div style={{ padding: 8 }}>
      <TagList tags={normalTags} />
    </div>
  )
}
