import { Box, GridList, GridListTile, Toolbar } from '@material-ui/core'
import { Fragment } from 'preact'
import useSWR, { mutate } from 'swr'
import { BackButton } from '../common/back'
import { LinearLoadingIndicator } from '../common/loading'
import store from '../stores/root'
import { ProductMenuThumb } from './menu'
import productService from './services'

export const RecentProductsScreen = () => {
  const { data: recent } = useSWR('/recent-products', productService.recent)

  if (!recent) {
    return (
      <Box padding={3}>
        <LinearLoadingIndicator />
      </Box>
    )
  }

  return (
    <Fragment>
      <Toolbar>
        <BackButton label='Produtos Recentes' />
      </Toolbar>
      <Box padding={2}>
        <GridList cellHeight={375 / 3} cols={3}>
          {recent.map(p => (
            <GridListTile button onClick={() => store.art.addProduct(p)}>
              <ProductMenuThumb
                refresh={() => mutate('/recent-products')}
                product={p}
              />
            </GridListTile>
          ))}
        </GridList>
      </Box>
    </Fragment>
  )
}
