import { Box, Grid } from '@material-ui/core'
import { useEffect, useState } from 'preact/hooks'
import { CSSSlideUpOpacityAnimation } from '../../animations/slideup'
import store from '../../stores/root'
import { ElementMenu } from './element'
import { AlignMenu } from './multi'
import { ProductMenu } from './product'
import { TextMenu } from './text'

export const ContextMenu = ({ konva }) => {
  const { art } = store
  const { selectedElement, multiSelect } = art

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  let konvad = {}
  if (konva.getBoundingClientRect) {
    konvad = konva.getBoundingClientRect()
  }

  useEffect(() => {
    let isCanceled = false

    if (selectedElement.element) {
      const dimensions = document.getElementById('context')

      if (!isCanceled) {
        setDimensions({
          width: dimensions?.clientWidth || 0,
          height: dimensions?.offsetHeight || 0
        })
      }
    }

    return () => {
      isCanceled = true
    }
  }, [selectedElement.element])

  const innerHeight = window.document.documentElement.clientHeight
  const left = konvad.left - dimensions.width
  const top = innerHeight / 2 - dimensions.height / 2

  return (
    <Grid
      id='context'
      style={{
        '-webkit-box-shadow': '0px 0px 60px -17px rgba(0,0,0,0.75)',
        '-moz-box-shadow': '0px 0px 60px -17px rgba(0,0,0,0.75)',
        'box-shadow': '0px 0px 60px -17px rgba(0,0,0,0.75)',
        visibility: selectedElement.type ? 'visible' : 'hidden',
        position: 'fixed',
        left,
        top,
        backgroundColor: '#ffffff',
        height: 'auto',
        width: 400,
        borderRadius: 8,
        zIndex: 1,
        overflow: 'hidden',
        overflowY: 'auto'
      }}
    >
      <div
        style={{
          width: 0,
          height: 0,
          borderTop: '20px solid transparent',
          borderBottom: '20px solid transparent',
          borderLeft: '20px solid #ffffff',
          position: 'fixed',
          left: konvad.left,
          top: innerHeight / 2
        }}
      />
      <Box padding={2} paddingTop={0}>
        <Grid container>
          {multiSelect.length > 1 && <AlignMenu />}
          {multiSelect.length < 2 &&
            selectedElement.element &&
            selectedElement.type === 'product' && (
              <CSSSlideUpOpacityAnimation>
                <ProductMenu product={selectedElement.element} art={art} />
              </CSSSlideUpOpacityAnimation>
            )}
          {multiSelect.length < 2 &&
            selectedElement.element &&
            selectedElement.type === 'element' && (
              <CSSSlideUpOpacityAnimation>
                <ElementMenu element={selectedElement.element} art={art} />
              </CSSSlideUpOpacityAnimation>
            )}
          {multiSelect.length < 2 &&
            selectedElement.element &&
            selectedElement.type === 'text' && (
              <CSSSlideUpOpacityAnimation>
                <TextMenu text={selectedElement.element} art={art} />
              </CSSSlideUpOpacityAnimation>
            )}
        </Grid>
      </Box>
    </Grid>
  )
}
