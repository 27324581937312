import { Button, Menu, MenuItem } from '@material-ui/core'
import { TextFieldsOutlined } from '@material-ui/icons'
import { Fragment } from 'preact'
import { useState } from 'preact/hooks'
import { customFonts } from './fonts'

export const FontSelector = ({ fontFamily, onUpdate }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Fragment>
      <Button
        style={{ border: 'none' }}
        onClick={e => setAnchorEl(e.target)}
        value='fonts'
        size='small'
      >
        <TextFieldsOutlined />
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {customFonts.map(font => (
          <MenuItem
            selected={font === fontFamily}
            onClick={() => {
              onUpdate(font)
              setAnchorEl(null)
            }}
          >
            <span style={{ fontFamily: font }}>{font}</span>
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  )
}
