import { Box, Grid, Typography } from '@material-ui/core'
import { inject, observer } from 'mobx-preact'
import { Fragment } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { useHistory, useRouteMatch } from 'react-router'
import { FlatButton } from '../common/button'
import { FolderList } from '../folder/list/list'
import store from '../stores/root'
import useDebounce from '../utils/debounce'
import { useMobileDetect } from '../utils/mobile'
import { AddProductContainer } from './add/product'
import { CommonFolders } from './folders'
import { ProductSearchField } from './search/field'
import { ProductSearchResults } from './search/results'
import productService from './services'

export const ProductContentComponent = ({ art }) => {
  const { url } = useRouteMatch()
  const history = useHistory()
  const mobileDetect = useMobileDetect()

  const [results, setResults] = useState(null)
  const [term, setTerm] = useState('')
  const debounced = useDebounce(term, 200)

  const products = art.offer.products || []

  useEffect(() => {
    let isCanceled = false

    const fetchProducts = async () => {
      if (!term) return
      try {
        const results = await productService.search(term)

        if (isCanceled) return
        setResults(results)
      } catch (e) {
        store.layout.showSnackbar('Ocorreu um erro, tente novamente', true)
      }
    }
    fetchProducts()

    return () => {
      isCanceled = true
    }
  }, [debounced])

  return (
    <div>
      <Box padding={2}>
        <Grid container>
          <Grid item xs={12}>
            <ProductSearchField
              onClear={() => {
                setTerm(null)
                setResults(null)
              }}
              onSearch={setTerm}
            />
          </Grid>
        </Grid>
        {!results && <AddProductContainer />}
        <Grid container justify='center'>
          {results && results.length == 0 && (
            <Grid item>
              <Box padding={6}>
                <Typography align='center' variant='body2'>
                  Nenhum produto encontrado
                </Typography>
                <Box padding={2} />
                <FlatButton
                  onClick={() =>
                    window.open(
                      `https://www.google.com/search?q=${encodeURIComponent(
                        term
                      )}&newwindow=1&source=lnms&tbm=isch&sa=X&bih=949`
                    )
                  }
                  primary
                  label={`Pesquisar "${term}" no Google`}
                />
              </Box>
            </Grid>
          )}
        </Grid>
        {!results && (
          <Fragment>
            <Box padding={2} />
            {!mobileDetect.isMobile() && <CommonFolders />}
            <Box padding={2} />
            {!mobileDetect.isMobile() && (
              <FolderList
                onClick={folder =>
                  history.push(`${url}/pastas/${folder._key}/${folder.name}`)
                }
                type='product'
              />
            )}
          </Fragment>
        )}
        <Grid container>
          <Box padding={1} />
          {results && results.length > 0 ? (
            <ProductSearchResults
              results={results}
              selected={products}
              onSelect={art.addProduct}
            />
          ) : (
            <div />
          )}
        </Grid>
      </Box>
    </div>
  )
}

export const ProductContent = inject(
  'art',
  'layout'
)(observer(ProductContentComponent))
