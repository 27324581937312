import {
  Box,
  GridList,
  GridListTile,
  Toolbar,
  Typography
} from '@material-ui/core'
import { Fragment } from 'preact'
import useSWR, { mutate } from 'swr'
import { BackButton } from '../../common/back'
import { LinearLoadingIndicator } from '../../common/loading'
import store from '../../stores/root'
import { ProductMenuThumb } from '../menu'
import productService from '../services'

export const FavoriteProductsScreen = () => {
  const cachekey = '/favorite-products'
  const { data: favorite } = useSWR(cachekey, productService.favorite)

  if (!favorite) {
    return (
      <Box padding={3}>
        <LinearLoadingIndicator />
      </Box>
    )
  }

  return (
    <Fragment>
      <Toolbar>
        <BackButton label='Favoritos' />
      </Toolbar>
      <Box padding={2}>
        {favorite.length === 0 && (
          <Box padding={3}>
            <Typography variant='body1' align='center'>
              Os produtos que você adicionar ao "Favoritos" vão aparecer aqui
            </Typography>
          </Box>
        )}
        <GridList cellHeight={375 / 3} cols={3}>
          {favorite.map(p => (
            <GridListTile button onClick={() => store.art.addProduct(p)}>
              <ProductMenuThumb refresh={() => mutate(cachekey)} product={p} />
            </GridListTile>
          ))}
        </GridList>
      </Box>
    </Fragment>
  )
}
