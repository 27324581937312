import {
  Box,
  GridList,
  GridListTile,
  Toolbar,
  Typography
} from '@material-ui/core'
import { Fragment } from 'preact'
import { useRouteMatch } from 'react-router'
import useSWR, { mutate } from 'swr'
import { BackButton } from '../../common/back'
import { LinearLoadingIndicator } from '../../common/loading'
import { folderProvider } from '../../folder/providers/folder'
import store from '../../stores/root'
import { ProductMenuThumb } from '../menu'

export const ProductsInFolderScreen = () => {
  const { params } = useRouteMatch()
  const key = [`folders/${params.key}`, 'product-in-folders']

  const { data: products } = useSWR(key, folderProvider.products)

  if (!products) {
    return <LinearLoadingIndicator />
  }

  return (
    <Fragment>
      <Toolbar>
        <BackButton label={params.name} />
      </Toolbar>
      <Box padding={2}>
        {products.length === 0 && (
          <Box padding={3}>
            <Typography variant='body1' align='center'>
              Você ainda não possui nenhum produto nessa pasta
            </Typography>
          </Box>
        )}
        <GridList cellHeight={375 / 3} cols={3}>
          {products.map(p => (
            <GridListTile button onClick={() => store.art.addProduct(p)}>
              <ProductMenuThumb refresh={() => mutate(key)} product={p} />
            </GridListTile>
          ))}
        </GridList>
      </Box>
    </Fragment>
  )
}
