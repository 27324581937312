import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from '@material-ui/core'
import { Fragment } from 'preact'
import { useEffect, useRef, useState } from 'preact/hooks'
import { FlatButton, RaisedButton } from '../../common/button'
import { CustomTextField } from '../../common/input'
import { LinearLoadingIndicator } from '../../common/loading'
import store from '../../stores/root'

export const AddFolderDialog = props => {
  const ref = useRef()

  const { open, onClose } = props
  const [name, setName] = useState('')
  const [busy, setBusy] = useState(false)

  useEffect(() => {
    if (ref.current) {
      ref.current.focus()
    }
  }, [open])

  const handleSaveFolder = async () => {
    setBusy(true)
    try {
      await store.folder.addProductFolder(name)
      setName('')
      onClose()
    } catch (e) {
      store.layout.showSnackbar(e.message, true)
    } finally {
      setBusy(false)
    }
  }

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle id='simple-dialog-title'>Nome da pasta</DialogTitle>
      {busy ? (
        <Box padding={2}>
          <LinearLoadingIndicator label='Salvando' />
        </Box>
      ) : (
        <Fragment>
          <DialogContent>
            <CustomTextField
              inputRef={ref}
              autofocus
              placeholder='Nome da pasta'
              value={name}
              onKeyPress={e => e.keyCode === 13 && handleSaveFolder()}
              onChange={e => setName(e.target.value)}
            />
          </DialogContent>
          <Box paddingRight={2} paddingLeft={2}>
            <DialogActions>
              <Grid container direction='column'>
                <RaisedButton
                  onClick={handleSaveFolder}
                  primary
                  fullWidth
                  label='Criar Pasta'
                />
                <Box padding={1} />
                <FlatButton onClick={onClose} label='Cancelar' />
                <Box padding={1} />
              </Grid>
            </DialogActions>
          </Box>
        </Fragment>
      )}
    </Dialog>
  )
}
