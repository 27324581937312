import graphql from '../../http/graphql'
import {
  insertItemIntoFolderMutation,
  removeItemFromFolderMutation,
  updateFolderMutation
} from '../graphql/mutations'
import {
  folderQuery,
  foldersQuery,
  productsForFolderQuery
} from '../graphql/queries'

class FolderProvider {
  async folders (type) {
    return graphql.query(foldersQuery, { type }).then(b => b.folders)
  }

  async folder (id) {
    return graphql.query(folderQuery, { id }).then(b => b.folder)
  }

  async update (data) {
    return graphql
      .query(updateFolderMutation, { data })
      .then(b => b.updateFolder)
  }

  async insert (folder, key) {
    return graphql
      .query(insertItemIntoFolderMutation, { folder, key })
      .then(b => b.insertItemIntoFolder)
  }

  async remove (folder, key) {
    return graphql
      .query(removeItemFromFolderMutation, { folder, key })
      .then(b => b.removeItemFromFolder)
  }

  async products (folder) {
    return graphql
      .query(productsForFolderQuery, { folder })
      .then(b => b.productsForFolder)
  }
}

export const folderProvider = new FolderProvider()
