import { Card, ClickAwayListener, Grid, makeStyles } from '@material-ui/core'
import { inject, observer } from 'mobx-preact'
import { Fragment } from 'preact'
import { ElementMenu } from '../../canvas/menu/element'
import { ProductMenu } from '../../canvas/menu/product'
import { TextMenu } from '../../canvas/menu/text'
import { FlatButton } from '../../common/button'

const MobileMiniMenuComponent = ({ art }) => {
  const classes = useStyles()

  return (
    <Fragment>
      {art.selectedElement.element && (
        <Card className={classes.menu}>
          {art.selectedElement.element && !art.elementDetails && (
            <Grid container justify='space-between'>
              <FlatButton onClick={art.showElementDetails} label='Editar' />
              <FlatButton
                onClick={() =>
                  art.removeObject(
                    art.selectedElement.type,
                    art.selectedElement.element.id
                  )
                }
                label='Remover'
              />
            </Grid>
          )}
          {art.selectedElement.element && art.elementDetails && (
            <FlatButton onClick={art.hideElementDetails} label='Voltar' />
          )}
        </Card>
      )}
      {art.selectedElement.element && art.elementDetails && (
        <Grid className={classes.overlay}>
          <ClickAwayListener onClickAway={art.hideElementDetails}>
            <Card className={classes.menu}>
              {art.selectedElement.element &&
                art.selectedElement.type === 'product' && (
                  <ProductMenu
                    product={art.selectedElement.element}
                    art={art}
                  />
                )}
              {art.selectedElement.element &&
                art.selectedElement.type === 'element' && (
                  <ElementMenu
                    element={art.selectedElement.element}
                    art={art}
                  />
                )}
              {art.selectedElement.element &&
                art.selectedElement.type === 'text' && (
                  <TextMenu text={art.selectedElement.element} art={art} />
                )}
            </Card>
          </ClickAwayListener>
        </Grid>
      )}
    </Fragment>
  )
}
const useStyles = makeStyles(() => ({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    backgroundColor: '#00000054',
    zIndex: 100
  },
  menu: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100vw',
    overflowY: 'auto',
    padding: 12,
    zIndex: 101
  }
}))

export const MobileMiniMenu = inject(
  'art',
  'mobile'
)(observer(MobileMiniMenuComponent))
