import { AppBar } from '@material-ui/core'
import { Fragment } from 'preact'
import { Toolbar } from '../layout/toolbar'

export const EasyOfferAppBar = () => {
  return (
    <Fragment>
      <AppBar
        style={{ backgroundColor: '#fff' }}
        backgroundColor='#fff'
        elevation={0}
      >
        <Toolbar />
      </AppBar>
      <div style={{ height: 84 }} />
    </Fragment>
  )
}
