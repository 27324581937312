import { useEffect, useRef } from 'preact/hooks'

export const useEventListener = (eventName, handler, elementId = '') => {
  const savedHandler = useRef()
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    const element = document.getElementById(elementId)
    const isSupported = element && element.addEventListener
    if (!isSupported) return
    const eventListener = event => savedHandler.current(event)
    element.addEventListener(eventName, eventListener)
    return () => {
      element.removeEventListener(eventName, eventListener)
    }
  }, [eventName, elementId])
}
