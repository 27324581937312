import { DateTime } from 'luxon'
import * as timeago from 'timeago.js'
import locale from './locale'

timeago.register('pt_BR', locale)

const weekdays = new Array(7)
weekdays[0] = 'Segunda-Feira'
weekdays[1] = 'Terça-Feira'
weekdays[2] = 'Quarta-Feira'
weekdays[3] = 'Quinta-Feira'
weekdays[4] = 'Sexta-Feira'
weekdays[5] = 'Sábado'
weekdays[6] = 'Domingo'

const shortWeekdays = new Array(7)
shortWeekdays[0] = 'Seg'
shortWeekdays[1] = 'Ter'
shortWeekdays[2] = 'Qua'
shortWeekdays[3] = 'Qui'
shortWeekdays[4] = 'Sex'
shortWeekdays[5] = 'Sáb'
shortWeekdays[6] = 'Dom'

export const monthNames = [
  'JAN',
  'FEV',
  'MAR',
  'ABR',
  'MAI',
  'JUN',
  'JUL',
  'AGO',
  'SET',
  'OUT',
  'NOV',
  'DEZ'
]

const ISO_DATE_FORMAT = 'yyyy-mm-ddThh:mm:ss'

export function timeAgo(date) {
  return timeago.format(date, 'pt_BR')
}

export function formatDateDisplay(date) {
  return DateTime.fromFormat(date, ISO_DATE_FORMAT).toFormat('dd/mm/yyyy')
}

export function toDateFormat(date, format, fromFormat) {
  if (fromFormat) {
    return DateTime.fromFormat(date, fromFormat).toFormat(format)
  }
  return DateTime.fromISO(date).toFormat(format)
}

export function getDayMonthDisplay(date) {
  return DateTime.fromFormat(date, ISO_DATE_FORMAT).toFormat('dd/mm')
}

export function getWeekdayName(weekday, short) {
  if (weekday < 0) {
    weekday = 0
  }
  return short ? shortWeekdays[weekday] : weekdays[weekday]
}

export function getHourFromTimestamp(timestamp) {
  const date = new Date(timestamp)
  return `${date.getHours() < 10 ? 0 : ''}${date.getHours()}`
}

export function getDateTimeFromTimestamp(date) {
  const day = `${date.getDate() < 10 ? 0 : ''}${date.getDate()}`
  const month = `${date.getMonth() + 1 < 10 ? 0 : ''}${date.getMonth() + 1}`
  const hours = date.getHours()
  const minutes = '0' + date.getMinutes()

  return day + '/' + month + ' ' + hours + ':' + minutes.substr(-2)
}

export function getTimeFromTimestamp(date) {
  return DateTime.fromFormat(date, 'yyyy-mm-dd').toFormat('mm:ss')
}

export function getNextDatePrettyName(date) {
  const now = new Date()
  if (now.getDate() === date.getDate()) {
    return 'Hoje'
  } else if (now.getDate() + 1 === date.getDate()) {
    return 'Amanhã'
  }
  let weekday = date.getDay() - 1
  if (weekday < 0) {
    weekday = 0
  }
  return weekdays[date.getDay() - 1]
}

export function formatUnixTimestamp(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp * 1000)
  var months = [
    'Jan',
    'Feb',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez'
  ]
  var year = a.getFullYear()
  var month = months[a.getMonth()]
  var date = a.getDate()
  var time = date + ' ' + month + ' ' + year + ' '
  return time
}
