export const getDisplayErrorMessage = error => {
  switch (error) {
    case 'card_declined':
      return 'Cartão recusado'
    case 'expired_card':
      return 'Cartão expirado'
    case 'incorrect_cvc':
      return 'CVC incorreto'
    case 'processing_error':
      return 'Erro de processamento, fale com nosso suporte.'
    case 'incorrect_number':
      return 'Número do cartão incorreto'
    default:
      return 'Erro desconhecido, fale com nosso suporte.'
  }
}
