import { CDN } from './http/hostname'

const formats = [
  {
    code: 'stories',
    description: 'Stories Facebook e Instagram',
    size: '1080 x 1920',
    ratio: 1.77777777778,
    height: 1920,
    width: 1080,
    vertical: true,
    imageURL: `${CDN}/app-format-stories.png`,
    help:
      'Utilize em Histórias do Whatsapp, Facebook e Instagram'
  },
  {
    code: 'feed',
    description: 'Feed Facebook e Instagram',
    size: '1080 x 1350',
    ratio: 1.25,
    height: 1350,
    width: 1080,
    vertical: true,
    imageURL: `${CDN}/app-format-feed.png`,
    help: 'Utilize na Linha do Tempo do Facebook e Instagram'
  },
  {
    code: 'square',
    description: 'Feed Facebook e Instagram',
    size: '1080 x 1080',
    ratio: 1,
    height: 1080,
    width: 1080,
    vertical: true,
    imageURL: `${CDN}/app-format-square.png`,
    help: 'Utilize na Linha do Tempo do Facebook e Instagram'
  }
]

export default formats
