import { Grid } from '@material-ui/core'
import { FlatButton } from '../../common/button'
import store from '../../stores/root'

export const HorizontalMenuOptions = () => {
  return (
    <Grid container wrap='nowrap' spacing={2}>
      <Grid item>
        <FlatButton
          onClick={() => store.mobile.setContent('arte')}
          label='Arte'
        />
      </Grid>
      <Grid item>
        <FlatButton
          onClick={() => store.mobile.setContent('produtos')}
          label='Produtos'
        />
      </Grid>
      <Grid item>
        <FlatButton
          onClick={() => store.mobile.setContent('etiquetas')}
          label='Etiquetas'
        />
      </Grid>
      <Grid item>
        <FlatButton
          onClick={() => store.mobile.setContent('elementos')}
          label='Elementos'
        />
      </Grid>
      <Grid item>
        <FlatButton
          onClick={() => store.mobile.setContent('textos')}
          label='Textos'
        />
      </Grid>
    </Grid>
  )
}
