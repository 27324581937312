export const customFonts = [
  'Arial',
  'Bungee Shade',
  'Fjalla One',
  'Grandstander',
  'Montserrat',
  'Nerko One',
  'Oleo Script',
  'Oswald',
  'Pacifico',
  'Permanent Marker',
  'Rammetto One',
  'Roboto',
  'Skranji',
  'Sriracha',
  'Titan One'
]
