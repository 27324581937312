import { DateTime } from 'luxon'
import { action, observable } from 'mobx'
import { hostname } from '../http/hostname'

class AuthStore {
  constructor(root) {
    this.root = root
  }

  @observable isTrial = false
  @observable expires = ''
  @observable expiresInDays = 0
  @observable token = undefined
  @observable user = undefined
  @observable subscription = undefined
  @observable invoices = undefined

  @action
  setSubscription(subscription) {
    this.subscription = subscription
  }

  @action
  setInvoices(invoices) {
    this.invoices = invoices
  }

  @action
  setTrialInfo(isTrial, trialExpires, retrial) {
    const now = DateTime.local().toUTC()
    const expires = DateTime.fromISO(trialExpires)
      .diff(now, ['days', 'hours'])
      .toObject()

    this.isTrial = isTrial
    this.retrial = retrial

    if (expires.days > 0) {
      this.expires = `${Math.round(expires.days)} dia${
        expires.days > 1 ? 's' : ''
      }`
    } else {
      this.expires = `${Math.round(expires.hours)} horas`
    }

    this.expiresInDays = expires.days
  }

  @action
  setBusiness = business => {
    this.user.company.business = business
  }

  @action
  setCredentials = ({ user, token }) => {
    this.user = user
    this.token = token
  }

  @action
  updateUser = user => {
    this.user = user
  }

  @action
  logout = () => {
    this.user = undefined
    this.token = undefined

    localStorage.removeItem('token')
    localStorage.removeItem('user')

    const destination =
      hostname === 'localhost'
        ? 'http://localhost:8080'
        : 'http://app.ofertafacilapp.com.br/login'

    window.location.href = destination
  }
}

export default AuthStore
