import { action, observable } from 'mobx'

export class LayoutStore {
  constructor (root) {
    this.root = root
  }

  offerEditTopBarsHeight = 120
  offerEditToolbarHeight = 56

  @observable offerDrawerOpen = false

  @action
  toggleDrawer = open => {
    open = open || false
    this.offerDrawerOpen = open
  }

  @observable snackbar = {
    open: false,
    alert: false,
    message: undefined
  }

  @action
  showSnackbar = (message, alert) => {
    this.snackbar = {
      open: true,
      alert,
      message
    }
  }

  @action
  hideSnackbar = () => {
    this.snackbar = {
      open: false,
      alert: false,
      message: undefined
    }
  }
}
