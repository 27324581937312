import graphql from '../http/graphql'
import { sendFeedbackMutation } from './mutations'

class FeedbackService {
  send (rating) {
    return graphql
      .query(sendFeedbackMutation, { rating })
      .then(d => d.sendFeedback)
  }
}

export const feedbackService = new FeedbackService()
