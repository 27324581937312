import { makeStyles } from '@material-ui/core'
import { HelpOutlineOutlined } from '@material-ui/icons'
import Colors from '../../config/colors'

export const OfferHelpButton = () => {
  const classes = useStyles()

  return (
    <a
      target='_blank'
      rel='noreferrer'
      href='https://ofertafacil.crisp.help/pt-br'
      className={classes.root}
    >
      <span style={{ marginRight: 8 }}>Ajuda</span>{' '}
      <HelpOutlineOutlined className={classes.icon} />
    </a>
  )
}

const useStyles = makeStyles({
  root: {
    cursor: 'pointer',
    padding: 11,
    borderRadius: 8,
    position: 'fixed',
    backgroundColor: Colors.accentColor,
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    bottom: 12,
    right: 12,
    zIndex: 500,
    fontSize: 14,
    fontFamily: 'GoogleSans',
    textDecoration: 'none',
    color: Colors.white,
    '-webkit-box-shadow': '3px 3px 20px -17px rgba(0,0,0,0.75)',
    '-moz-box-shadow': '3px 3px 20px -17px rgba(0,0,0,0.75)',
    'box-shadow': '3px 3px 20px -17px rgba(0,0,0,0.75)'
  },
  icon: {
    fontSize: 18,
    color: Colors.white
  }
})
