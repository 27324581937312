import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core'
import { ArrowBackOutlined } from '@material-ui/icons'
import { useHistory, useRouteMatch } from 'react-router'
import Colors from '../../config/colors'
import AppRoutes from '../../navigation/routes'

const OrderIcon = props => {
  return (
    <svg
      {...props}
      enable-background='new 0 0 32 32'
      height='28'
      viewBox='0 0 32 32'
      width='28'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        id='XMLID_1124_'
        d='m16 2c-3.309 0-6 2.691-6 6v2h-5c-.285 0-.556.121-.745.333-.19.212-.28.495-.249.777l1.703 15.331c.226 2.029 1.935 3.559 3.976 3.559h12.63c2.042 0 3.75-1.53 3.975-3.559l1.704-15.331c.032-.283-.059-.565-.249-.777-.189-.212-.46-.333-.745-.333h-5v-2c0-3.309-2.691-6-6-6zm-4 6c0-2.206 1.794-4 4-4s4 1.794 4 4v2h-8zm13.883 4-1.581 14.221c-.112 1.014-.967 1.779-1.987 1.779h-12.63c-1.021 0-1.875-.765-1.988-1.779l-1.58-14.221h3.883v2c-.552 0-1 .448-1 1s.448 1 1 1h2c.552 0 1-.448 1-1s-.448-1-1-1v-2h8v2c-.552 0-1 .448-1 1s.448 1 1 1h2c.552 0 1-.448 1-1s-.448-1-1-1v-2z'
      />
      <path
        id='XMLID_108_'
        d='m20.625 18.219c.432.346.501.975.156 1.406l-4 5c-.321.401-.901.497-1.335.207l-3-2c-.459-.307-.583-.927-.277-1.387.307-.46.926-.584 1.387-.277l2.237 1.491 3.427-4.284c.344-.431.975-.501 1.405-.156z'
      />
    </svg>
  )
}

const SavedIcon = props => {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 32 32'
      width='28'
      height='28'
    >
      <path d='M30.71 7.29l-6-6A1 1 0 0024 1H4a3 3 0 00-3 3v24a3 3 0 003 3h24a3 3 0 003-3V8a1 1 0 00-.29-.71zM20 3v6h-8V3zM8 29v-7a1 1 0 011-1h14a1 1 0 011 1v7zm21-1a1 1 0 01-1 1h-2v-7a3 3 0 00-3-3H9a3 3 0 00-3 3v7H4a1 1 0 01-1-1V4a1 1 0 011-1h6v6a2 2 0 002 2h8a2 2 0 002-2V3h1.59L29 8.41z' />
    </svg>
  )
}

const IconModels = props => {
  return (
    <svg
      {...props}
      enable-background='new 0 0 24 24'
      height='28'
      viewBox='0 0 24 24'
      width='28'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path d='m9.431 20h-4.862c-.865 0-1.569-.704-1.569-1.569v-8.862c0-.865.704-1.569 1.569-1.569h4.861c.866 0 1.57.704 1.57 1.569v8.862c0 .865-.704 1.569-1.569 1.569zm-4.862-10.5-.069 8.931 4.931.069.069-8.931c0-.039-.03-.069-.069-.069z' />
      </g>
      <g>
        <path d='m3.75 16.96c-.192 0-.384-.073-.53-.22-.293-.293-.293-.768 0-1.061l1.36-1.36c.245-.246.583-.379.931-.366.349.013.679.172.907.436l.354.427 1.261-1.399c.245-.279.607-.431.99-.416.381.018.725.203.945.508l.881 1.167c.25.331.184.801-.147 1.05-.33.249-.801.183-1.05-.147l-.706-.936-1.248 1.386c-.246.27-.593.422-.947.416-.357-.004-.699-.163-.936-.435l-.365-.441-1.17 1.171c-.146.147-.339.22-.53.22zm5.404-2.546-.005.005c.002-.002.003-.003.005-.005zm-.392-.012c.001.002.003.004.005.006z' />
      </g>
      <g>
        <path d='m19.431 20h-4.861c-.866 0-1.57-.704-1.57-1.569v-8.862c0-.865.704-1.569 1.569-1.569h4.861c.866 0 1.57.704 1.57 1.569v8.862c0 .865-.704 1.569-1.569 1.569zm-4.862-10.5-.069 8.931 4.931.069.069-8.931c0-.039-.03-.069-.069-.069z' />
      </g>
      <g>
        <path d='m13.75 16.96c-.192 0-.384-.073-.53-.22-.293-.293-.293-.768 0-1.061l1.36-1.36c.245-.246.587-.379.931-.366.349.013.679.172.907.436l.354.427 1.261-1.399c.245-.279.608-.431.99-.416.381.018.725.203.945.508l.881 1.167c.25.331.184.801-.147 1.05-.33.249-.801.183-1.05-.147l-.706-.936-1.248 1.386c-.247.27-.612.422-.947.416-.357-.004-.699-.163-.936-.435l-.365-.441-1.17 1.171c-.146.147-.339.22-.53.22zm5.404-2.546-.005.005c.002-.002.003-.003.005-.005zm-.392-.012c.001.002.003.004.005.006z' />
      </g>
      <g>
        <path d='m21.25 23h-18.5c-1.517 0-2.75-1.233-2.75-2.75v-16.5c0-1.517 1.233-2.75 2.75-2.75h18.5c1.517 0 2.75 1.233 2.75 2.75v16.5c0 1.517-1.233 2.75-2.75 2.75zm-18.5-20.5c-.689 0-1.25.561-1.25 1.25v16.5c0 .689.561 1.25 1.25 1.25h18.5c.689 0 1.25-.561 1.25-1.25v-16.5c0-.689-.561-1.25-1.25-1.25z' />
      </g>
      <g>
        <path d='m23.25 6h-22.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h22.5c.414 0 .75.336.75.75s-.336.75-.75.75z' />
      </g>
    </svg>
  )
}

const TextsIcon = props => {
  return (
    <svg
      {...props}
      enable-background='new 0 0 28 28'
      height='28'
      viewBox='0 0 512 512'
      width='28'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path d='m135.05 197.128h30v-25.128h75.95v170.849h-25.996v30h81.992v-30h-25.996v-170.849h75.95v25.128h30v-55.128h-241.9z' />
        <path d='m476.25 401.005v-290.01c20.957-8.645 35.75-29.287 35.75-53.328 0-31.798-25.869-57.667-57.667-57.667-24.041 0-44.683 14.793-53.328 35.75h-290.01c-8.645-20.957-29.287-35.75-53.328-35.75-31.798 0-57.667 25.869-57.667 57.667 0 24.041 14.793 44.684 35.75 53.329v290.009c-20.957 8.645-35.75 29.287-35.75 53.328 0 31.798 25.869 57.667 57.667 57.667 24.041 0 44.683-14.793 53.328-35.75h290.01c8.645 20.957 29.287 35.75 53.328 35.75 31.798 0 57.667-25.869 57.667-57.667 0-24.041-14.793-44.683-35.75-53.328zm-79.005 45.245h-282.49c-3.572-25.34-23.666-45.434-49.005-49.005v-282.49c25.339-3.571 45.433-23.666 49.005-49.005h282.49c3.572 25.34 23.666 45.434 49.005 49.005v282.489c-25.339 3.572-45.433 23.666-49.005 49.006zm57.088-416.25c15.256 0 27.667 12.411 27.667 27.667s-12.411 27.667-27.667 27.667c-15.255 0-27.666-12.411-27.666-27.667s12.411-27.667 27.666-27.667zm-396.666 0c15.255 0 27.666 12.411 27.666 27.667s-12.411 27.667-27.666 27.667c-15.256-.001-27.667-12.412-27.667-27.667 0-15.256 12.411-27.667 27.667-27.667zm0 452c-15.256 0-27.667-12.411-27.667-27.667s12.411-27.667 27.667-27.667c15.255 0 27.666 12.411 27.666 27.667s-12.411 27.667-27.666 27.667zm396.666 0c-15.255 0-27.666-12.411-27.666-27.667s12.411-27.667 27.666-27.667c15.256 0 27.667 12.411 27.667 27.667s-12.411 27.667-27.667 27.667z' />
      </g>
    </svg>
  )
}

const ProductsIcon = props => {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 117.89 117.89'
    >
      <g id='Camada_2' data-name='Camada 2'>
        <path d='M48,70.35a21.94,21.94,0,1,0,30-8A21.93,21.93,0,0,0,48,70.35ZM54.34,74A14.63,14.63,0,1,1,59.7,94,14.65,14.65,0,0,1,54.34,74Z' />
        <path d='M.49,89.23A34.76,34.76,0,0,0,49.05,114.8,34.51,34.51,0,0,0,97.57,79.26,34.86,34.86,0,0,1,102,57.82,20.7,20.7,0,0,0,97.22,31.5a14,14,0,0,1,16.26-6.71,3.45,3.45,0,0,0,1.9-6.64A21,21,0,0,0,91.24,28,20.72,20.72,0,0,0,66.06,37.1a33.93,33.93,0,0,1-10,11,34.54,34.54,0,0,1-.56-6.17A21,21,0,0,0,38.22,21.25,15.1,15.1,0,0,1,49.42,6.81,3.46,3.46,0,0,0,47.74.1,21.66,21.66,0,0,0,35.89,7.94a22.11,22.11,0,0,0-2.1,3.32A19.78,19.78,0,0,0,31.89,8,18.94,18.94,0,0,0,16.38,0H10.59A3.45,3.45,0,0,0,7.32,2.36,19.44,19.44,0,0,0,10,19.86,19,19,0,0,0,20.16,27.1l-.07.06a20.92,20.92,0,0,0-6,14.74A34.91,34.91,0,0,1,6.89,62.66,34.44,34.44,0,0,0,.49,89.23ZM72,40.67l.06-.12A13.82,13.82,0,1,1,96,54.38a41.74,41.74,0,0,0-5.24,25.71A27.64,27.64,0,1,1,52.44,58,40.85,40.85,0,0,0,72,40.67ZM15.62,15.83a12.47,12.47,0,0,1-2.3-8.92h3.06A12.11,12.11,0,0,1,26.28,12,12.39,12.39,0,0,1,28.58,21h-3A12.13,12.13,0,0,1,15.62,15.83Zm-3.2,51A41.88,41.88,0,0,0,21,41.9a13.82,13.82,0,1,1,27.63,0,41.45,41.45,0,0,0,1.15,9.67A34.54,34.54,0,0,0,40,108.89c.48.44,1,.86,1.49,1.26a28.21,28.21,0,0,1-12.71.2A27.58,27.58,0,0,1,12.42,66.8Z' />
      </g>
    </svg>
  )
}

const BackgroundsIcon = props => {
  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 99.02 99'>
      <g id='Camada_2' data-name='Camada 2'>
        <path d='M85.75,99H13.25A13.25,13.25,0,0,1,0,85.75H0V13.25A13.25,13.25,0,0,1,13.25,0h72.5A13.25,13.25,0,0,1,99,13.25h0v72.5A13.25,13.25,0,0,1,85.75,99ZM13.25,7.62a5.63,5.63,0,0,0-5.63,5.63h0v72.5a5.63,5.63,0,0,0,5.63,5.63h72.5a5.63,5.63,0,0,0,5.63-5.63h0V13.25a5.63,5.63,0,0,0-5.63-5.63H13.25Z' />
        <path d='M3.81,84.07a3.84,3.84,0,0,1-2.9-1.32,3.8,3.8,0,0,1,.44-5.36l0,0,26-21.93A10.15,10.15,0,0,1,41.07,56L53.41,68.34a2.54,2.54,0,0,0,3.3,0l12-8.69a10.16,10.16,0,0,1,12.39,0L97.58,72.8a3.83,3.83,0,1,1-4.77,6L76.36,65.59a2.55,2.55,0,0,0-3.1,0l-12,9.29A10.15,10.15,0,0,1,48,74L35.69,61.38a2.54,2.54,0,0,0-3.4,0l-26,21.83A3.6,3.6,0,0,1,3.81,84.07Z' />
        <path d='M64.73,48.23a14,14,0,1,1,14-14A14,14,0,0,1,64.73,48.23Zm0-20.31a6.35,6.35,0,1,0,6.35,6.35A6.35,6.35,0,0,0,64.73,27.92Z' />
      </g>
    </svg>
  )
}

const TagsIcon = props => {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 97.66 104.19'
    >
      <g id='Camada_2' data-name='Camada 2'>
        <path
          class='cls-1'
          d='M60.24,33.19a9.59,9.59,0,0,0,9.59-9.86A9.7,9.7,0,0,0,60.51,14a9.59,9.59,0,0,0-9.86,9.59h0A9.61,9.61,0,0,0,60.24,33.19Zm-4.87-9.46a5,5,0,0,1,5-5h0a5,5,0,1,1-5,5Z'
        />
        <path
          class='cls-1'
          d='M96.12,21h0L74.67,1.35A3.21,3.21,0,0,0,72.49.5H43.11a3.26,3.26,0,0,0-1.56.4L19.29,13.17a3.24,3.24,0,0,0-1.58,2.07L.59,86.35A3.23,3.23,0,0,0,3,90.24l22.72,5.48a3.41,3.41,0,0,0,1.3,0v4.7a3.23,3.23,0,0,0,3.23,3.22H93.94a3.22,3.22,0,0,0,3.22-3.22V23.36A3.25,3.25,0,0,0,96.12,21ZM28,21A3.23,3.23,0,0,0,27,23.36v66L7.61,84.72,23,20.73a6.08,6.08,0,0,1,3-3.89L42.5,7.74ZM90.72,97.24H33.44V27.59a6.39,6.39,0,0,1,2.07-4.71L52.29,7.53A2.18,2.18,0,0,1,53.77,7H70.35a2.26,2.26,0,0,1,1.54.6L89.44,23.62a3.92,3.92,0,0,1,1.28,2.89Z'
        />
      </g>
    </svg>
  )
}

const ElementsIcon = props => {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 100.37 100.7'
    >
      <g id='Camada_2' data-name='Camada 2'>
        <path
          class='cls-1'
          d='M43.77,10.46c-1.64-5.59-6.16-8.87-12.36-8.91q-8.53,0-17.08,0a14.59,14.59,0,0,0-3.49.38C4.94,3.48,1.55,8,1.52,14.4q0,8.46,0,16.93A14.78,14.78,0,0,0,1.93,35c1.5,5.74,5.88,9.13,11.9,9.24,3,0,6,0,9,0h8.68c7.67,0,12.68-5.06,12.71-12.75,0-5.74,0-11.48,0-17.22A13.14,13.14,0,0,0,43.77,10.46ZM39.41,31.92a7.23,7.23,0,0,1-7.52,7.51q-9,.06-18,0c-4.53,0-7.51-3-7.56-7.59,0-3,0-6,0-9s0-5.89,0-8.83c0-4.66,3-7.6,7.64-7.63q8.9,0,17.8,0c4.57,0,7.56,3,7.59,7.56Q39.46,22.94,39.41,31.92Z'
        />
        <path
          class='cls-1'
          d='M97.16,75a7.28,7.28,0,0,0-6.82-7.48c-1.4-.17-3.3.62-4-.36s-.2-2.73-.3-4.14a7,7,0,0,0-6.61-6.5c-1.17-.07-2.35,0-3.53,0v0c-1,0-2,0-2.95,0a7.18,7.18,0,0,0-7.34,7.36c0,1.17.5,2.66-.22,3.41s-2.23.19-3.39.24c-4.29.17-7.29,3-7.46,7.16-.09,2.1-.09,4.22,0,6.33A7.23,7.23,0,0,0,61.26,88c1.41.12,3.32-.61,4.07.32s.2,2.71.29,4.12A7,7,0,0,0,71.74,99a36.87,36.87,0,0,0,7.5.05c4.15-.35,6.63-3.09,6.82-7.24.06-1.21-.54-2.86.25-3.52s2.34-.19,3.55-.23a7.25,7.25,0,0,0,7.29-7.31C97.2,78.83,97.19,76.91,97.16,75Zm-7.91,8.26c-1.67,0-3.34,0-5,0-2.11,0-2.94.85-3,2.94,0,1.72,0,3.44,0,5.15,0,2-.85,2.89-2.84,2.92-1.71,0-3.43,0-5.15,0-2.06,0-2.89-.88-2.91-3,0-1.62,0-3.24,0-4.86,0-2.38-.79-3.14-3.2-3.15-1.62,0-3.24,0-4.86,0-2.09,0-3-.89-3-3,0-1.67,0-3.34,0-5,0-2.11.88-2.93,3-3,1.67,0,3.34,0,5,0,2.27,0,3.09-.87,3.11-3.14V66.7c0-.94,0-1.87,0-2.8a2.43,2.43,0,0,1,2.56-2.63c1.91-.06,3.83-.06,5.74,0A2.42,2.42,0,0,1,81.26,64c0,1.76,0,3.53,0,5.3,0,2.15.87,3,3,3,1.67,0,3.34,0,5,0,2.16,0,3,.86,3,3,0,1.57,0,3.15,0,4.72C92.33,82.35,91.47,83.23,89.25,83.26Z'
        />
        <path
          class='cls-1'
          d='M98,31a10.25,10.25,0,0,0-.5-1.06C93,22.17,88.61,14.28,83.9,6.61,79.76-.12,72-.23,67.84,6.43,63,14.21,58.6,22.23,54.08,30.19a9.77,9.77,0,0,0-1.27,6.38C53.5,41,57.08,44,62.09,44.15c4.57.11,9.13,0,13.7,0s9.13.12,13.69,0C96.88,43.93,100.74,37.87,98,31Zm-6.89,8a11.12,11.12,0,0,1-3.35.42H64A11.45,11.45,0,0,1,60.54,39a3.75,3.75,0,0,1-2.81-4.87,10.62,10.62,0,0,1,1.24-3q6-10.5,12.12-21a9.87,9.87,0,0,1,2.09-2.64,3.67,3.67,0,0,1,5.29,0,11.19,11.19,0,0,1,2.22,2.9L92.52,30.94a11.2,11.2,0,0,1,1.38,3.22A3.75,3.75,0,0,1,91.08,39Z'
        />
        <path
          class='cls-1'
          d='M22.91,56.48A21.32,21.32,0,1,0,44.19,77.8,21.36,21.36,0,0,0,22.91,56.48Zm-.05,37.8a16.51,16.51,0,1,1,16.55-16.4A16.48,16.48,0,0,1,22.86,94.28Z'
        />
      </g>
    </svg>
  )
}

const useStyles = makeStyles({
  root: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    alignItems: 'center',
    width: 78,
    margin: 0,
    paddingTop: 12,
    paddingBottom: 12,
    '&:hover': {
      backgroundColor: '#0186c4'
    }
  },
  active: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    alignItems: 'center',
    width: 79,
    margin: 0,
    paddingTop: 12,
    paddingBottom: 12,
    backgroundColor: Colors.white
  },
  icon: {
    color: Colors.white
  },
  activeIcon: {
    color: Colors.accentColor
  },
  label: {
    fontFamily: 'GoogleSans',
    color: Colors.white,
    fontSize: 8
  },
  activeLabel: {
    fontFamily: 'GoogleSans',
    fontSize: 8,
    color: Colors.accentColor
  }
})

const MenuButton = ({ label, active, dest }) => {
  const { params } = useRouteMatch()

  const history = useHistory()
  const classes = useStyles()

  const destination = `${AppRoutes.EditOffer.replace(
    ':key',
    params.key
  )}/${dest}`

  const navigate = () => history.push(destination)

  let icon
  const style = {
    color: active ? Colors.accentColor : 'white',
    fill: active ? Colors.accentColor : 'white',
    width: 24,
    marginBottom: 4
  }

  switch (dest) {
    case 'modelos':
      icon = (
        <IconModels
          style={{
            color: active ? Colors.accentColor : 'white',
            fill: active ? Colors.accentColor : 'white',
            width: 28,
            marginBottom: 4
          }}
        />
      )
      break
    case 'arte':
      icon = <BackgroundsIcon style={style} />
      break
    case 'produtos':
      icon = (
        <ProductsIcon
          style={{
            color: active ? Colors.accentColor : 'white',
            fill: active ? Colors.accentColor : 'white',
            width: 28,
            marginBottom: 4
          }}
        />
      )
      break
    case 'etiquetas':
      icon = <TagsIcon style={style} />
      break
    case 'elementos':
      icon = (
        <ElementsIcon
          style={{
            color: active ? Colors.accentColor : 'white',
            fill: active ? Colors.accentColor : 'white',
            width: 28,
            marginBottom: 4
          }}
        />
      )
      break
    case 'textos':
      icon = (
        <TextsIcon
          style={{
            color: active ? Colors.accentColor : 'white',
            fill: active ? Colors.accentColor : 'white',
            width: 28,
            marginBottom: 4
          }}
        />
      )
      break
    case 'salvos':
      icon = (
        <SavedIcon
          style={{
            color: active ? Colors.accentColor : 'white',
            fill: active ? Colors.accentColor : 'white',
            width: 28,
            marginBottom: 4
          }}
        />
      )
      break
    case 'pedidos':
      icon = (
        <OrderIcon
          style={{
            color: active ? Colors.accentColor : 'white',
            fill: active ? Colors.accentColor : 'white',
            width: 28,
            marginBottom: 12
          }}
        />
      )
      break
  }

  return (
    <Grid className={active ? classes.active : classes.root} onClick={navigate}>
      {icon}
      <Typography
        style={{
          color: active ? Colors.accentColor : 'white',
          fontSize: 12
        }}
        variant='button'
      >
        {label}
      </Typography>
    </Grid>
  )
}

export const OfferEditMenu = ({ offer }) => {
  const url = window.location.href
  return (
    <Grid container direction='column'>
      <MenuButton
        label='Arte'
        offer={offer._key}
        active={url.includes('arte')}
        dest='arte'
      />
      <MenuButton
        label='Modelos'
        offer={offer._key}
        active={url.includes('modelos')}
        dest='modelos'
      />
      <MenuButton
        label='Produtos'
        offer={offer._key}
        active={url.includes('produtos')}
        dest='produtos'
      />
      <MenuButton
        label='Etiquetas'
        offer={offer._key}
        active={url.includes('etiquetas')}
        dest='etiquetas'
      />
      <MenuButton
        label='Elementos'
        offer={offer._key}
        active={url.includes('elementos')}
        dest='elementos'
      />
      <MenuButton
        label='Textos'
        offer={offer._key}
        active={url.includes('textos')}
        dest='textos'
      />
    </Grid>
  )
}
