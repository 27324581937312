export const updateTemplateMutation = `
  mutation UpdateTemplate($data: TemplateInput!) {
    updateTemplate(data: $data) {
      _id
    }
  }
`

export const updateUserTemplateMutation = `
  mutation updateUserTemplate($data: TemplateInput!) {
    updateUserTemplate(data: $data) {
      _id
    }
  }
`

export const removeuserTemplateMutation = `
  mutation RemoveUserTemplate($id: String!) {
    removeUserTemplate(id: $id)
  }
`
