import { Card, ClickAwayListener, Grid, makeStyles } from '@material-ui/core'
import { inject, observer } from 'mobx-preact'
import { BackgroundListScreen } from '../../background/list/screen'
import { LogoScreen } from '../../logo/screen'
import { ProductContent } from '../../product/screen'
import { TagScreen } from '../../tag/screen'
import { TextScreen } from '../../text/screen'
import { HorizontalMenuOptions } from './horizontal'

const MobileContextComponent = ({ mobile }) => {
  const classes = useStyles()

  if (!mobile.menu) {
    return null
  }

  return (
    <Grid className={classes.overlay}>
      <ClickAwayListener onClickAway={mobile.toggleMenu}>
        <Card id='offer-content' className={classes.menu}>
          <div style={{ width: '100vw', overflowX: 'scroll', height: 48 }}>
            <HorizontalMenuOptions />
          </div>
          {mobile.content == 'arte' && <BackgroundListScreen />}
          {mobile.content == 'produtos' && <ProductContent />}
          {mobile.content == 'etiquetas' && <TagScreen />}
          {mobile.content == 'elementos' && <LogoScreen />}
          {mobile.content == 'textos' && <TextScreen />}
        </Card>
      </ClickAwayListener>
    </Grid>
  )
}

const useStyles = makeStyles(() => ({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    backgroundColor: '#00000054',
    zIndex: 100
  },
  menu: {
    position: 'fixed',
    height: window.innerHeight * 0.8,
    bottom: 0,
    left: 0,
    width: '100vw',
    overflowY: 'auto',
    paddingTop: 8,
    zIndex: 101
  }
}))

export const MobileContext = inject('mobile')(observer(MobileContextComponent))
