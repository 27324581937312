import { FilledInput, makeStyles } from '@material-ui/core'
import Colors from '../config/colors'

const useStyles = makeStyles({
  root: {
    borderRadius: '8px !important'
  },
  input: {
    padding: 16,
    fontFamily: 'GoogleSans'
  }
})

export const CustomTextField = props => {
  const classes = useStyles()
  return (
    <FilledInput
      classes={{
        root: classes.root,
        input: classes.input,
      }}
      style={{
        borderRadius: '8px !important',
        backgroundColor: Colors.textInputColor
      }}
      disableUnderline
      fullWidth
      {...props}
    />
  )
}
