import { Grid, Snackbar, Typography } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import { inject, observer } from 'mobx-preact'

function SnackbarController ({ layout }) {
  const timeoutInMs = 3000

  const icon = layout.snackbar.alert ? (
    <ReportProblemOutlinedIcon style={{ color: '#ffd600' }} />
  ) : (
    <InfoOutlinedIcon style={{ color: '#00c853' }} />
  )
  return (
    <Snackbar
      open={layout.snackbar.open || false}
      autoHideDuration={timeoutInMs}
      onClose={() => setTimeout(layout.hideSnackbar, timeoutInMs)}
      message={
        <Grid container alignItems='center'>
          <Grid item>{icon}</Grid>
          <Grid item>
            <Typography
              style={{ marginLeft: 16 }}
              color='inherit'
              variant='body1'
            >
              {layout.snackbar.message}
            </Typography>
          </Grid>
        </Grid>
      }
    />
  )
}

export default inject('layout')(observer(SnackbarController))
