import { useState, useEffect } from 'preact/hooks'
import { useRouteMatch } from 'react-router'
import authService from '../providers/auth'

export const useVerifyPasswordResetState = () => {
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const { params } = useRouteMatch()

  useEffect(() => {
    if (!params || !params.hash) {
      return setError('Algo deu errado, entre em contato com nosso suporte.')
    }

    const fetchPassword = async () => {
      try {
        const hash = params.hash
        const password = await authService.verify(hash)
        setPassword(password)
      } catch (e) {
        setError('Algo deu errado, entre em contato com nosso suporte.')
      }
    }

    fetchPassword()
  }, [])

  return { password, error }
}
