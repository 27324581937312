import { Container, Grid, Typography } from '@material-ui/core'
import { Fragment } from 'preact'
import { RaisedButton } from '../common/button'
import Spacer from '../common/spacer'
import store from '../stores/root'
import { formatUnixTimestamp } from '../utils/date'
import { formatMoney } from '../utils/format'

export const SubscriptionUnpaid = () => {
  const invoices = store.auth.invoices || []
  return (
    <Fragment>
      <Container>
        <Grid container direction='column' justify='center'>
          <Grid
            container
            direction='column'
            alignItems='center'
            style={{ marginTop: 24 }}
            item
            xs={12}
          >
            <Typography align='center' variant='h5'>
              Ops! não recebemos seu pagamento
            </Typography>
            <Spacer />
            <Typography variant='caption' align='center'>
              Não conseguimos processar o pagamento da sua última fatura. Fale
              com nosso suporte para reativar o seu plano.
            </Typography>
            <Spacer size={16} />
            <Typography variant='h4'>Fatura em aberto</Typography>
            <Spacer />
            {invoices.map(i => (
              <>
                <Grid container direction='row'>
                  <Grid container>
                    <Grid
                      container
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        padding: 12,
                        borderRadius: 8,
                        border: '1px solid #ededed'
                      }}
                    >
                      <Grid item>
                        <img
                          src='/assets/invoice.jpeg'
                          style={{
                            width: '100px'
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{
                          margin: 20,
                          align: 'center'
                        }}
                      >
                        <Typography variant='h4'>
                          {formatMoney(i.total / 100, null, 2)}
                        </Typography>
                        <Typography variant='body1'>
                          Vencimento:{' '}
                          {formatUnixTimestamp(parseInt(i.dueDate, 0))}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <RaisedButton
                  style={{ marginTop: 4, width: '100%' }}
                  primary
                  onClick={() => {
                    window.location.href = i.url
                  }}
                  label='Ir para pagamento'
                />
              </>
            ))}
          </Grid>
          <Spacer />
        </Grid>
      </Container>
    </Fragment>
  )
}
