export const updateFolderMutation = `
  mutation updateFolder($data: FolderInput!) {
    updateFolder(data: $data) {
      _id
      _key
      _rev
      type
      name
      items
    }
  }
`

export const insertItemIntoFolderMutation = `
  mutation insertItemIntoFolder($folder: String!, $key: String!) {
    insertItemIntoFolder(folder: $folder, key: $key)
  }
`

export const removeItemFromFolderMutation = `
  mutation removeItemFromFolder($folder: String!, $key: String!) {
    removeItemFromFolder(folder: $folder, key: $key)
  }
`
