export const subscriptionQuery = `
 query Subscription {
    subscription {
      active
      amount
      currentPeriodEnd
      currentPeriodStart
      id
      name
      status
      trialEnd
      trialStart
    }
 }
`

export const stripeInvoicesQuery = `
  query stripeInvoices {
    stripeInvoices {
      id
      amountDue
      amountPaid
      subtotal
      total
      dueDate
      paid
      status
      url
    }
  }
`

export const stripeSubscription = `
  query stripeSubscription {
    stripeSubscription {
      canceledAt
      created
      currentPeriodStart
      currentPeriodEnd
      endedAt
      billingCicleAnchor
      status
    }
  }
`
