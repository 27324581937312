import { useEffect } from 'preact/hooks'
import { useHistory, useLocation } from 'react-router'
import AppRoutes from '../navigation/routes'
import { subscriptionProvider } from './providers/subscription'

export const RetrialScreen = () => {
  const history = useHistory()
  const query = new URLSearchParams(useLocation().search)
  const email = query.get('email')

  useEffect(() => {
    const activeRetrial = async () => {
      try {
        await subscriptionProvider.retrial(email)
        history.push(AppRoutes.Login)
      } catch (e) {
        history.push(AppRoutes.Login)
      }
    }
    if (email) {
      activeRetrial()
    }
  }, [])

  return null
}
