import Konva from 'konva'
import { observe } from 'mobx'
import { CDN } from '../http/hostname'
import store from '../stores/root'
import { getImageUrl } from '../utils/url'
import uuidv4 from '../utils/uuid'

export class Canvas extends Konva.Stage {
  _hasCustomScale = false
  currentScale = 1
  isMobile

  constructor(offer, config, mobile) {
    super(config)

    this.isMobile = mobile

    this._store = store.art
    this._store.canvas = this

    observe(offer, 'background', () => {
      this.drawBackground()
    })
    observe(offer, 'textColor', () => {
      this.drawProducts()
    })
    observe(offer, 'textFamily', () => {
      this.drawProducts()
    })
    observe(offer, 'textStyle', () => {
      this.drawProducts()
    })
    observe(offer, 'showTags', () => {
      this.drawProducts()
    })
    observe(offer, 'tag', () => {
      this.drawProducts()
    })
    observe(offer, 'texts', () => {
      this.drawTexts()
    })
    observe(offer, 'products', () => {
      this.drawProducts()
    })
    observe(offer, 'logos', () => {
      this.drawElements()
    })

    this._offer = offer

    this._bgLayer = new Konva.Layer()
    this._defaultLayer = new Konva.Layer()

    this.add(this._bgLayer)
    this.add(this._defaultLayer)

    this._textTransformer = new Konva.Transformer({
      id: uuidv4(),
      name: 'text-transformer',
      keepRatio: true,
      padding: 4,
      anchorSize: 8,
      borderStroke: 'black',
      borderStrokeWidth: 2,
      borderDash: [4, 6],
      anchorStroke: 'black',
      anchorFill: 'white',
      visible: true,
      enabledAnchors: ['middle-left', 'middle-right'],
      boundBoxFunc: (oldBox, newBox) => {
        return newBox.width < 50 || newBox.width > this.width()
          ? oldBox
          : newBox
      }
    })
    this._defaultTransformer = new Konva.Transformer({
      id: uuidv4(),
      name: 'transformer',
      keepRatio: true,
      padding: 4,
      centeredScaling: true,
      anchorSize: 8,
      borderStroke: 'black',
      borderStrokeWidth: 2,
      borderDash: [4, 6],
      anchorStroke: 'black',
      anchorFill: 'white',
      enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
      boundBoxFunc: (oldBox, newBox) => {
        return newBox.width < 10 ||
          newBox.height < 10 ||
          newBox.height > this.height() ||
          newBox.width > this.width()
          ? oldBox
          : newBox
      }
    })

    this._defaultLayer.add(this._defaultTransformer)
    this._defaultLayer.add(this._textTransformer)

    this.on('click tap', e => {
      if (e.target.name() === 'background') {
        this.clearSelected()
        this._store.clearSelected()
        this.draw()
      }
    })

    const container = this.container()

    container.style.marginTop = '18px'

    container.tabIndex = 1
    container.focus()
    container.addEventListener('keydown', e => {
      if (e.keyCode === 46) {
        if (this._selected && this._selected.length > 0) {
          const selected = this._selected[0]
          this._store.removeObject(selected.name(), selected.id())
          this._store.clearSelected()
          this.clearSelected()
        }
      }
    })
  }

  init() {
    this.fitCanvasToScreen()
    this.drawOffer()
  }

  snapToGuide(guides, absPos) {
    guides.forEach(lg => {
      switch (lg.snap) {
        case 'start': {
          switch (lg.orientation) {
            case 'V': {
              absPos.x = lg.lineGuide + lg.offset
              break
            }
            case 'H': {
              absPos.y = lg.lineGuide + lg.offset
              break
            }
          }
          break
        }
        case 'center': {
          switch (lg.orientation) {
            case 'V': {
              absPos.x = lg.lineGuide + lg.offset
              break
            }
            case 'H': {
              absPos.y = lg.lineGuide + lg.offset
              break
            }
          }
          break
        }
        case 'end': {
          switch (lg.orientation) {
            case 'V': {
              absPos.x = lg.lineGuide + lg.offset
              break
            }
            case 'H': {
              absPos.y = lg.lineGuide + lg.offset
              break
            }
          }
          break
        }
      }
    })
  }

  getLineGuideStops(skipShape) {
    const vertical = []
    const horizontal = []
    // products
    this.find('.product').forEach(el => {
      if (el === skipShape) {
        return
      }
      const box = el.getClientRect()
      vertical.push([box.x, box.x + box.width, box.x + box.width / 2])
      horizontal.push([box.y, box.y + box.height, box.y + box.height / 2])
    })
    // elements
    this.find('.element').forEach(el => {
      if (el === skipShape) {
        return
      }
      const box = el.getClientRect()
      vertical.push([box.x, box.x + box.width, box.x + box.width / 2])
      horizontal.push([box.y, box.y + box.height, box.y + box.height / 2])
    })
    // elements
    this.find('.text').forEach(el => {
      if (el === skipShape) {
        return
      }
      const box = el.getClientRect()
      vertical.push([box.x, box.x + box.width, box.x + box.width / 2])
      horizontal.push([box.y, box.y + box.height, box.y + box.height / 2])
    })
    return {
      vertical: vertical.flat(),
      horizontal: horizontal.flat()
    }
  }

  getObjectSnappingEdges(node) {
    const box = node.getClientRect()
    const absPos = node.absolutePosition()

    return {
      vertical: [
        {
          guide: Math.round(box.x),
          offset: Math.round(absPos.x - box.x),
          snap: 'start'
        },
        {
          guide: Math.round(box.x + box.width / 2),
          offset: Math.round(absPos.x - box.x - box.width / 2),
          snap: 'center'
        },
        {
          guide: Math.round(box.x + box.width),
          offset: Math.round(absPos.x - box.x - box.width),
          snap: 'end'
        }
      ],
      horizontal: [
        {
          guide: Math.round(box.y),
          offset: Math.round(absPos.y - box.y),
          snap: 'start'
        },
        {
          guide: Math.round(box.y + box.height / 2),
          offset: Math.round(absPos.y - box.y - box.height / 2),
          snap: 'center'
        },
        {
          guide: Math.round(box.y + box.height),
          offset: Math.round(absPos.y - box.y - box.height),
          snap: 'end'
        }
      ]
    }
  }

  getGuides(lineGuideStops, itemBounds) {
    const resultV = []
    const resultH = []

    lineGuideStops.vertical.forEach(lineGuide => {
      itemBounds.vertical.forEach(itemBound => {
        const diff = Math.abs(lineGuide - itemBound.guide)
        // if the distance between guild line and object snap point is close we can consider this for snapping
        if (diff < 5) {
          resultV.push({
            lineGuide,
            diff,
            snap: itemBound.snap,
            offset: itemBound.offset
          })
        }
      })
    })

    lineGuideStops.horizontal.forEach(lineGuide => {
      itemBounds.horizontal.forEach(itemBound => {
        const diff = Math.abs(lineGuide - itemBound.guide)
        if (diff < 5) {
          resultH.push({
            lineGuide,
            diff,
            snap: itemBound.snap,
            offset: itemBound.offset
          })
        }
      })
    })

    const guides = []

    const minV = resultV.sort((a, b) => a.diff - b.diff)[0]
    const minH = resultH.sort((a, b) => a.diff - b.diff)[0]
    if (minV) {
      guides.push({
        lineGuide: minV.lineGuide,
        offset: minV.offset,
        orientation: 'V',
        snap: minV.snap
      })
    }
    if (minH) {
      guides.push({
        lineGuide: minH.lineGuide,
        offset: minH.offset,
        orientation: 'H',
        snap: minH.snap
      })
    }
    return guides
  }

  drawGuides(guides) {
    guides.forEach(lg => {
      if (lg.orientation === 'H') {
        const line = new Konva.Line({
          points: [-6000, 0, 6000, 0],
          stroke: '#019eec',
          strokeWidth: 2,
          id: 'guid-line',
          name: 'guid-line',
          dash: [4, 6]
        })
        this._defaultLayer.add(line)
        line.absolutePosition({
          x: 0,
          y: lg.lineGuide
        })
        this._defaultLayer.batchDraw()
      } else if (lg.orientation === 'V') {
        const line = new Konva.Line({
          points: [0, -6000, 0, 6000],
          stroke: '#019eec',
          strokeWidth: 2,
          id: 'guid-line',
          name: 'guid-line',
          dash: [4, 6]
        })
        this._defaultLayer.add(line)
        line.absolutePosition({
          x: lg.lineGuide,
          y: 0
        })
        this._defaultLayer.batchDraw()
      }
    })
  }

  drawOffer() {
    this.drawBackground()
    this.drawProducts()
    this.drawElements()
    this.drawTexts()
  }

  selectProduct = id => {
    const el = this.findOne(el => el.name() === 'product' && el.id() === id)
    if (el) {
      this.selectNode(el)
    }
  }

  selectAllProducts = () => {
    const els = this.find(el => el.name() === 'product')
    if (els.length > 0) {
      this._defaultTransformer.setNodes(els)
      this.draw()
    }
  }

  selectText = id => {
    const el = this.findOne(el => el.id() === id)
    if (el) {
      this.selectTextNode(el)
    }
  }

  moveNodeUp = id => {
    const el = this.findOne(el => el.id() === id)
    if (el) {
      el.moveUp()
      this.saveElementIndexes(el.name())
      this.draw()
    }
  }

  saveElementIndexes = name => {
    const el = this.find(el => el.name() === name)
    el.forEach(el =>
      this._store.updateObject(name, el.id(), { index: el.getZIndex() })
    )
  }

  moveNodeDown = id => {
    const el = this.findOne(el => el.id() === id)
    if (el) {
      el.moveDown(el)
      this.saveElementIndexes(el.name())
      this.draw()
    }
  }

  selectNode = (node, append) => {
    this._selected = [node]
    const existingNodes = this._defaultTransformer.getNodes()
    this._defaultTransformer.setNodes(
      append ? [...existingNodes, node] : this._selected
    )

    if (!append) {
      this._store.setMultiSelected([])
    }

    if (this._defaultTransformer.getNodes().length > 0) {
      this._store.setMultiSelected(this._defaultTransformer.getNodes())
    }

    this._defaultTransformer.moveToTop()
    this._textTransformer.setNodes([])
    this.draw()
  }

  selectTextNode = node => {
    this._selected = [node]
    this._store.setMultiSelected([])
    this._textTransformer.setNodes(this._selected)
    this._textTransformer.moveToTop()
    this._defaultTransformer.setNodes([])
    this.draw()
  }

  clearSelected = () => {
    this._defaultTransformer.setNodes([])
    this._textTransformer.setNodes([])
    this._store.setMultiSelected([])
    this.draw()
  }

  drawTexts() {
    const { texts } = this._offer
    if (!texts) return

    const textIds = texts.map(t => t.id)
    const els = this.find(el => el.name() === 'text')
    for (const el of els) {
      if (textIds.includes(el.id())) {
        continue
      }
      if (el) {
        el.destroy()
      }
    }

    for (const text of texts) {
      this.drawText(text)
    }

    this._defaultLayer.draw()
  }

  drawText(text) {
    if (!text.id) {
      text.id = uuidv4()
    }

    let existingText = this.findOne(el => el.id() === text.id)
    let konvaText = existingText || new Konva.Text()

    if (existingText) {
      konvaText.off('click tap')
      konvaText.off('transformend')
      konvaText.off('dragend')
      konvaText.off('dragmove')
    }

    konvaText.setAttrs({
      id: text.id,
      text: text.text,
      name: 'text',
      x: 100,
      y: 200,
      width: this.width() / 2,
      fontFamily: text.fontFamily || 'Roboto',
      fontStyle: text.fontStyle || 'normal',
      align: text.textAlign || 'center',
      fill: text.color || '#222222',
      fontSize: text.fontSize || 50,
      draggable: true
    })

    if (text.attrs) {
      const rawAttrs =
        typeof text.attrs === 'string' ? JSON.parse(text.attrs) : text.attrs
      konvaText.setAttrs({
        x: rawAttrs.x,
        y: rawAttrs.y,
        width: rawAttrs.width
      })
    }

    konvaText.on('click tap', e => {
      this._store.setSelected('text', text)
      this.selectTextNode(e.target)
      this.draw()
    })

    konvaText.on('transformend', e => {
      this._store.updateObject('text', text.id, {
        attrs: JSON.stringify(e.target.attrs)
      })
      this.draw()
    })

    konvaText.on('dragend', e => {
      const elements = this._defaultLayer.find(e => e.id() === 'guid-line')
      for (const el of elements) {
        el?.destroy()
      }
      this._defaultLayer.batchDraw()
      this._store.updateObject('text', text.id, {
        attrs: JSON.stringify(e.target.attrs)
      })
    })

    konvaText.on('dragmove', e => {
      const elements = this._defaultLayer.find(e => e.id() === 'guid-line')
      for (const el of elements) {
        el?.destroy()
      }
      const lineGuideStops = this.getLineGuideStops(e.target)
      const itemBounds = this.getObjectSnappingEdges(e.target)
      const guides = this.getGuides(lineGuideStops, itemBounds)
      if (!guides.length) {
        return
      }
      this.drawGuides(guides)
      let absPos = e.target.absolutePosition()
      this.snapToGuide(guides, absPos)
      e.target.absolutePosition(absPos)
    })

    konvaText.on('transform', () => {
      konvaText.setAttrs({
        width: Math.max(konvaText.width() * konvaText.scaleX(), 80),
        scaleX: 1,
        scaleY: 1
      })
      this.draw()
    })

    if (!existingText) {
      this._defaultLayer.add(konvaText)
    }
  }

  drawElements() {
    const { logos } = this._offer
    if (!logos) return

    const logoIds = logos.map(l => l.id)
    const els = this.find(el => el.name() === 'element')
    for (const el of els) {
      if (!logoIds.includes(el.id())) {
        el.destroy()
      }
    }

    for (const element of logos) {
      this.drawElement(element)
    }

    this.draw()
  }

  drawElement = async element => {
    if (!element.id) {
      element.id = uuidv4()
    }

    let existingElement = this.findOne(el => el.id() === element.id)
    let konvaElement = existingElement || new Konva.Image()

    if (existingElement) {
      konvaElement.off('click tap')
      konvaElement.off('transformend')
      konvaElement.off('dragend')
      konvaElement.off('dragmove')
    }

    const elementImg = await new Promise(resolve => {
      const i = new Image()
      i.onload = () => resolve(i)
      i.src = getImageUrl(element.imageURL)
      i.crossOrigin = 'Anonymous'
    })

    konvaElement.setAttrs({
      id: element.id || uuidv4(),
      name: 'element',
      x: 0,
      y: 0,
      image: elementImg,
      width: elementImg.width,
      height: elementImg.height,
      draggable: true
    })

    konvaElement.on('click tap', e => {
      this._store.setSelected('element', element)
      this.selectNode(e.target, e.evt.shiftKey)
      this.draw()
    })

    konvaElement.on('transformend', e => {
      this._store.updateObject('element', element.id, {
        attrs: JSON.stringify(e.target.attrs)
      })
    })

    konvaElement.on('dragend', e => {
      const elements = this._defaultLayer.find(e => e.id() === 'guid-line')
      for (const el of elements) {
        el?.destroy()
      }
      this._defaultLayer.batchDraw()
      this._store.updateObject('element', element.id, {
        attrs: JSON.stringify(e.target.attrs)
      })
    })

    konvaElement.on('dragmove', e => {
      const elements = this._defaultLayer.find(e => e.id() === 'guid-line')
      for (const el of elements) {
        el?.destroy()
      }
      const lineGuideStops = this.getLineGuideStops(e.target)
      const itemBounds = this.getObjectSnappingEdges(e.target)
      const guides = this.getGuides(lineGuideStops, itemBounds)
      if (!guides.length) {
        return
      }
      this.drawGuides(guides)
      const absPos = e.target.absolutePosition()
      this.snapToGuide(guides, absPos)
      e.target.absolutePosition(absPos)
    })

    this._defaultLayer.add(konvaElement)

    if (element.attrs) {
      const attrs = JSON.parse(element.attrs)
      delete attrs.image
      konvaElement.setAttrs(attrs)
    }

    if (element.index) {
      konvaElement.setZIndex(element.index)
    }

    this.draw()
  }

  removeElementFromCanvas = id => {
    let el = this.findOne(el => el.id() === id)
    el && el.destroy()
  }

  drawProducts() {
    const { products } = this._offer
    if (!products) return

    const productIds = products.map(p => p.id)
    const els = this.find(el => el.name() === 'product')
    for (const el of els) {
      if (!productIds.includes(el.id())) {
        el.destroyChildren()
        el.destroy()
      }
    }

    for (const product of products) {
      this.drawProduct(product)
    }

    this._defaultLayer.draw()
    this.draw()
  }

  async drawProduct(product) {
    if (!product.id) {
      product.id = uuidv4()
    }

    let existingProductGroup = this.findOne(el => el.id() === product.id)
    let productGroup = existingProductGroup || new Konva.Group()

    if (existingProductGroup) {
      productGroup.off('click tap')
      productGroup.off('transformend dragend')
      productGroup.off('dragmove')
    } else {
      this._defaultLayer.add(productGroup)
    }

    productGroup.setAttrs({
      id: product.id,
      name: 'product',
      draggable: true
    })

    const toggleShowTags =
      this._offer.showTags !== false && product.tagVisible !== false

    const productImg = await new Promise(resolve => {
      const i = new Image()
      i.onload = () => resolve(i)
      i.src = getImageUrl(product.imageURL)
      i.crossOrigin = 'Anonymous'
    })

    const imageKey = `img-${product.id}`
    let existingImage = productGroup.getChildren(el => el.id() === imageKey)[0]
    let productKonvaImg = existingImage || new Konva.Image()

    productKonvaImg.setAttrs({
      id: imageKey,
      x: 450,
      y: 450,
      name: 'product-element',
      image: productImg,
      offsetX: productImg.width / 2,
      offsetY: productImg.height / 2,
      width: productImg.width,
      height: productImg.height
    })

    !existingImage && productGroup.add(productKonvaImg)

    const tagImg = await new Promise(resolve => {
      const i = new Image()
      i.onload = () => resolve(i)
      i.src = getImageUrl(this._offer.tag.imageURL)
      i.crossOrigin = 'Anonymous'
    })

    const clubTagImg = await new Promise(resolve => {
      const i = new Image()
      i.onload = () => resolve(i)
      i.src = `${CDN}/6064616d2137cd0001388745-tag-clube.png`
      i.crossOrigin = 'Anonymous'
    })

    const clubTagKey = `clubtag-${product.id}`
    let existingClubTag = productGroup.getChildren(
      el => el.id() === clubTagKey
    )[0]

    const clubPriceKey = `clubprice-${product.id}`
    let existingClubPrice = productGroup.getChildren(
      el => el.id() === clubPriceKey
    )[0]

    if (!product.clubPrice && existingClubTag) {
      existingClubTag.destroy()
    }

    if (!product.clubPrice && existingClubPrice) {
      existingClubPrice.destroy()
    }

    if (product.clubPrice) {
      let clubTagKonvaImg = existingClubTag || new Konva.Image()

      clubTagKonvaImg.setAttrs({
        id: clubTagKey,
        name: 'tag',
        image: clubTagImg,
        offsetX: clubTagImg.x / 2,
        offsetY: clubTagImg.y / 2,
        width: clubTagImg.width * 1.2,
        height: clubTagImg.height * 1.2,
        visible: Boolean(product.clubPrice),
        draggable: false
      })

      clubTagKonvaImg.setAttrs({
        x: productKonvaImg.x() - productKonvaImg.width() - 56,
        y: productKonvaImg.y()
      })

      !existingClubTag && productGroup.add(clubTagKonvaImg)

      const konvaClubPrice = existingClubPrice || new Konva.Text()

      konvaClubPrice.setAttrs({
        id: clubPriceKey,
        name: 'club-price',
        text: product.clubPrice || '',
        fontSize: 95,
        fontWeight: 900,
        fontStyle: 'bold',
        fontFamily: 'Montserrat',
        absolutePositioned: true,
        textAlign: 'center',
        fill: '#ffffff',
        visible: Boolean(product.clubPrice),
        draggable: false
      })

      konvaClubPrice.setAttrs({
        x:
          clubTagKonvaImg.x() +
          clubTagKonvaImg.width() / 2 -
          konvaClubPrice.width() / 2,
        y:
          clubTagKonvaImg.y() +
          clubTagKonvaImg.height() / 2 -
          konvaClubPrice.height() / 2
      })

      !existingClubPrice && productGroup.add(konvaClubPrice)
    }

    // ---------------------- Product tag -------------------------------

    const tagKey = `tag-${product.id}`
    let existingTag = productGroup.getChildren(el => el.id() === tagKey)[0]
    let tagKonvaImg = existingTag || new Konva.Image()

    tagKonvaImg.setAttrs({
      id: tagKey,
      name: 'product-element',
      image: tagImg,
      offsetX: tagImg.width / 2,
      offsetY: tagImg.height / 2,
      width: tagImg.width,
      height: tagImg.height,
      visible: toggleShowTags
    })

    !existingTag && productGroup.add(tagKonvaImg)

    tagKonvaImg.setAttrs({
      x: productKonvaImg.x() + tagKonvaImg.width() / 2,
      y: productKonvaImg.y() + tagKonvaImg.height() / 2
    })

    if (product.tagAttrs) {
      const attrs = JSON.parse(product.tagAttrs)
      if (attrs.x && attrs.y) {
        attrs.x = productKonvaImg.x() - attrs.x
        attrs.y = productKonvaImg.y() - attrs.y
      }
      tagKonvaImg.setAttrs(attrs)
    }

    const priceKey = `price-${product.id}`
    let existingPrice = productGroup.getChildren(el => el.id() === priceKey)[0]
    let konvaPrice = existingPrice || new Konva.Text()

    konvaPrice.listening(false)

    konvaPrice.setAttrs({
      id: priceKey,
      name: 'product-element',
      text: product.price || '',
      fontSize: 80,
      fontWeight: 900,
      fontStyle: 'bold',
      fontFamily: 'Montserrat',
      absolutePositioned: true,
      textAlign: 'center',
      fill: '#ffffff',
      visible: toggleShowTags
    })

    konvaPrice.setAttrs({
      offsetX: konvaPrice.width() / 2,
      offsetY: konvaPrice.height() / 2,
      x: tagKonvaImg.x(),
      y: tagKonvaImg.y()
    })

    if (product.priceAttrs) {
      const attrs = JSON.parse(product.priceAttrs)
      konvaPrice.setAttrs(attrs)
    }

    productGroup.getAbsolutePosition()

    !existingPrice && productGroup.add(konvaPrice)

    const nameKey = `name-${product.id}`
    let existingName = productGroup.getChildren(el => el.id() === nameKey)[0]
    let konvaName = existingName || new Konva.Text()

    const centerNameOnProduct =
      productKonvaImg.x() + productKonvaImg.width() / 4

    konvaName.setAttrs({
      id: nameKey,
      name: 'product-element',
      text: product.name,
      fontSize: 70,
      fontFamily: this._offer?.textFamily || 'Montserrat',
      fontStyle: this._offer?.textStyle || 'normal',
      fill: this._offer.textColor || '#000',
      align: 'center',
      x: centerNameOnProduct
    })

    !existingName && productGroup.add(konvaName)

    if (
      konvaName.width() > productKonvaImg.width() + tagKonvaImg.width() / 2 &&
      !product.nameAttrs
    ) {
      konvaName.setAttrs({
        width: productKonvaImg.width() + tagKonvaImg.width() / 2
      })
      const centerNameOnProduct =
        productKonvaImg.x() + productKonvaImg.width() / 4

      konvaName.setAttrs({
        x: centerNameOnProduct,
        y:
          productKonvaImg.y() -
          productKonvaImg.height() / 2 -
          konvaName.height()
      })
    }

    konvaName.setAttrs({
      offsetX: konvaName.width() / 2,
      offsetY: konvaName.height() / 2
    })

    if (!product.nameAttrs) {
      const centerNameOnProduct =
        productKonvaImg.x() + productKonvaImg.width() / 4

      konvaName.setAttrs({
        x: centerNameOnProduct,
        y:
          productKonvaImg.y() -
          productKonvaImg.height() / 2 -
          konvaName.height()
      })
    }

    if (product.nameAttrs) {
      const attrs = JSON.parse(product.nameAttrs)
      attrs.x = productKonvaImg.x() - (attrs.x || 0)
      attrs.y = productKonvaImg.y() - (attrs.y || 0)
      konvaName.setAttrs(attrs)
    }

    if (product.attrs) {
      const attrs = JSON.parse(product.attrs)
      delete attrs.id
      productGroup.setAttrs(attrs)
    } else {
      productGroup.setAttr('scale', { x: 0.4, y: 0.4 })
    }

    productGroup.on('click tap', e => {
      this._store.setSelected('product', product)
      if (e.target instanceof Konva.Group) {
        this.selectNode(e.target, e.evt.shiftKey)
      } else {
        this.selectNode(e.target.parent, e.evt.shiftKey)
      }
    })

    productGroup.on('transformend', e => {
      this._store.updateObject('product', product.id, {
        attrs: JSON.stringify(e.target.attrs)
      })
    })

    productGroup.on('dragend', e => {
      const elements = this._defaultLayer.find(e => e.id() === 'guid-line')
      for (const el of elements) {
        el?.destroy()
      }
      this._defaultLayer.batchDraw()
      this._store.updateObject('product', product.id, {
        attrs: JSON.stringify(e.target.attrs)
      })
    })

    productGroup.on('dragmove', e => {
      const elements = this._defaultLayer.find(e => e.id() === 'guid-line')
      for (const el of elements) {
        el?.destroy()
      }
      const lineGuideStops = this.getLineGuideStops(e.target)
      const itemBounds = this.getObjectSnappingEdges(e.target)
      const guides = this.getGuides(lineGuideStops, itemBounds)
      if (!guides.length) {
        return
      }
      this.drawGuides(guides)
      let absPos = e.target.absolutePosition()
      this.snapToGuide(guides, absPos)
      e.target.absolutePosition(absPos)
    })

    this._defaultLayer.draw()
  }

  fitCanvasToScreen() {
    const { width = 1080, height = 1080 } = this._offer.format
    const menu = this.isMobile ? 0 : 500
    const horizontalMenu = this.isMobile ? 56 : 120

    const innerWidth = window.document.documentElement.clientWidth
    const innerHeight = window.document.documentElement.clientHeight
    const windowWidth =
      innerWidth - menu > 1080 ? 1080 : innerWidth - (menu + 10)

    const availableHeight = (innerHeight - horizontalMenu - 36) / height
    const availableWidth = windowWidth / width

    this.updateScale(
      availableWidth < availableHeight ? availableWidth : availableHeight
    )
  }

  fitCanvasToWidth = () => {
    const menu = this.isMobile ? 0 : 500

    const innerWidth = window.document.documentElement.clientWidth
    const windowWidth =
      innerWidth - (menu + 10) > 1080 ? 1080 : innerWidth - (menu + 10)
    const widthScale = windowWidth / 1080
    this.updateScale(widthScale)
  }

  updateScale(scale) {
    this.currentScale = scale

    const { width = 1080, height = 1080 } = this._offer.format

    const innerHeight = window.document.documentElement.clientHeight
    let availableHeight =
      innerHeight - height * scale - store.layout.offerEditTopBarsHeight

    const container = this.container()
    if (availableHeight < 0) {
      container.style.marginTop = `${Math.round(36 / 2)}px`
    } else {
      container.style.marginTop = `${Math.round(availableHeight / 2)}px`
    }

    this.scale({ x: scale, y: scale })
    this.setAttr('size', {
      width: width * scale,
      height: height * scale
    })

    this.drawScene()
  }

  async drawBackground() {
    const { background } = this._offer
    if (!background) return

    const bgImg = await new Promise(resolve => {
      const i = new Image()
      i.onload = () => resolve(i)
      i.src = getImageUrl(background.imageURL || '')
      i.crossOrigin = 'Anonymous'
    })

    const konvaImg = new Konva.Image({
      id: background.id || uuidv4(),
      name: 'background',
      x: 0,
      y: 0,
      image: bgImg,
      width: bgImg.width,
      height: bgImg.height,
      draggable: false,
      preventDefault: false
    })

    this._bgLayer.add(konvaImg)
    this._bgLayer.draw()
  }
}
