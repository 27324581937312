import graphql from '../http/graphql'
import {
  updateBusinessMutation,
  updatePasswordMutation,
  updateUserMutation
} from './mutations'
import { userByEmailQuery, userQuery } from './queries'

class UserService {
  async byEmail (email) {
    return graphql.query(userByEmailQuery, { email }).then(d => d.userByEmail)
  }

  async signup (data) {
    return graphql.signup(data)
  }

  async update (data) {
    return graphql.query(updateUserMutation, { data }).then(d => d.updateUser)
  }

  async confirmation (token, email) {
    return graphql.get(`/confirmation?token=${token}&email=${email}`)
  }

  async updatePassword ({ userId, password }) {
    return graphql
      .query(updatePasswordMutation, { userId, password })
      .then(d => d.updatePassword)
  }

  async updateBusiness (business) {
    return graphql
      .query(updateBusinessMutation, { business })
      .then(d => d.updatePassword)
  }

  async user (id) {
    return graphql.query(userQuery, { id }).then(d => d.user)
  }
}

export const userService = new UserService()
