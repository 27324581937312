import { useState } from 'preact/hooks'
import { useHistory, useLocation } from 'react-router'
import AppRoutes from '../navigation/routes'
import { captureWithScope } from '../sentry'
import store from '../stores/root'
import { userService } from '../user/service'
import { useFacebookSDK } from '../utils/providers'
import { useFbqSDK } from '../utils/sdks'
import { isValidEmail } from '../utils/validation'

export const useSignupState = () => {
  useFbqSDK()

  const urlParams = new URLSearchParams(useLocation().search)
  const parsedParams = {
    utmSource: urlParams.get('utm_source'),
    utmMedium: urlParams.get('utm_medium'),
    utmCampaign: urlParams.get('utm_campaign'),
    anonymousId: urlParams.get('anonymousId')
  }

  const history = useHistory()
  const facebookAuth = useFacebookSDK()

  const [credentials, setCredentials] = useState({
    email: '',
    password: ''
  })
  const [agreed, setAgreed] = useState(false)
  const [busy, setBusy] = useState(false)
  const [error, setError] = useState('')
  const [validationError, setValidationError] = useState('')
  const [verificationSent, setVerificantionSent] = useState(false)
  const [emailSignup, setEmailSignup] = useState()

  const signupWithProvider = async providerInfo => {
    try {
      const { user, token } = await userService.signup(
        Object.assign(
          {
            ...providerInfo,
            idToken: providerInfo.token,
            providerId: providerInfo.provider
          },
          parsedParams
        )
      )
      if (token) {
        convert(providerInfo.email)
        localStorage.setItem('token', token)
        localStorage.setItem('user', JSON.stringify(user))
        setTimeout(() => history.push(AppRoutes.Offers), 200)
      }
    } catch (e) {
      captureWithScope(e)
      store.layout.showSnackbar(e.message, true)
    }
  }

  const validate = () => {
    const { email } = credentials

    if (emailSignup) {
      if (!email || !isValidEmail(email)) {
        setValidationError('Endereço de e-mail inválido')
        return false
      }
    }

    if (!agreed) {
      setError('Você deve concordar com os termos e condições')
      return false
    }

    return true
  }

  const authenticateGoogle = async user => {
    if (!validate()) return

    try {
      setBusy(true)
      await signupWithProvider(user)
    } catch (e) {
      setBusy(false)
      captureWithScope(e)
      store.layout.showSnackbar(e.message, true)
    }
  }

  const authenticateFacebook = async () => {
    if (!validate()) return

    try {
      setBusy(true)

      const providerInfo = await facebookAuth()

      await signupWithProvider(providerInfo)
    } catch (e) {
      setBusy(false)
      captureWithScope(e)
      store.layout.showSnackbar(e.message, true)
    }
  }

  const authenticateEmail = async () => {
    if (!validate()) return

    let { email, password } = credentials

    email = email.toLowerCase()

    try {
      setBusy(true)

      await userService.signup(
        Object.assign(
          {
            email,
            password,
            providerId: 'email'
          },
          parsedParams
        )
      )

      convert(email)

      setVerificantionSent(true)
    } catch (e) {
      if (e.status === 405) history.push(AppRoutes.Login)
      setBusy(false)
      setError(e.message)
    }
  }

  const convert = email => {
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-10792278870/AbAwCIzf5_wCENa2lJoo',
        transaction_id: email
      })
    }

    if (window.lintrk) {
      window.lintrk('track', { conversion_id: 7319537 })
    }

    if (window.fbq) {
      window.fbq('trackCustom', 'IniciouTrialOfertaFacil', {
        content_name: 'Oferta Fácil',
        currency: 'BRL',
        value: 3
      })
    }
  }

  return {
    agreed,
    setAgreed,
    credentials,
    setCredentials,
    error,
    validationError,
    setError,
    authenticateEmail,
    busy,
    verificationSent,
    authenticateGoogle,
    authenticateFacebook,
    emailSignup,
    setEmailSignup
  }
}
