import graphql from '../../http/graphql'
import {
  removeuserTemplateMutation,
  updateTemplateMutation,
  updateUserTemplateMutation
} from '../graphql/mutations'
import {
  templateQuery,
  templatesQuery,
  userTemplateQuery,
  userTemplatesQuery
} from '../graphql/queries'

export class TemplateProvider {
  async templates (format) {
    return graphql.query(templatesQuery, { format }).then(d => d.templates)
  }

  async userTemplates (format) {
    return graphql
      .query(userTemplatesQuery, { format })
      .then(d => d.userTemplates)
  }

  async template (id) {
    return graphql.query(templateQuery, { id }).then(d => d.template)
  }

  async userTemplate (id) {
    return graphql.query(userTemplateQuery, { id }).then(d => d.userTemplate)
  }

  async update (data) {
    delete data.slides
    return graphql
      .query(updateTemplateMutation, { data })
      .then(d => d.updateTemplate)
  }

  async userUpdate (data) {
    return graphql
      .query(updateUserTemplateMutation, { data })
      .then(d => d.updateTemplate)
  }

  async userRemove (id) {
    return graphql
      .query(removeuserTemplateMutation, { id })
      .then(d => d.removeUserTemplate)
  }
}

export const templateProvider = new TemplateProvider()
