import { Box, Divider, Grid, ListItem, Typography } from '@material-ui/core'
import { FlatButton } from '../../common/button'

const styles = {
  opacity: 0.74,
  marginRight: 8
}

export const ElementMenu = ({ element, art }) => {
  return (
    <Grid container direction='column'>
      <Box padding={1} />
      <Grid item>
        <Grid container justify='space-between'>
          <Grid item>
            <ListItem button onClick={() => art.canvas.moveNodeUp(element.id)}>
              <img style={styles} src='/assets/move-up.svg' />
              <Typography variant='button'>Para Frente</Typography>
            </ListItem>
          </Grid>
          <Divider variant='fullWidth' orientation='vertical' />
          <Grid item>
            <ListItem
              button
              onClick={() => art.canvas.moveNodeDown(element.id)}
            >
              <img style={styles} src='/assets/move-down.svg' />
              <Typography variant='button'>Para Trás</Typography>
            </ListItem>
          </Grid>
        </Grid>
      </Grid>
      <Box padding={1} />
      <Divider />
      <Box padding={1} />
      <Grid container justify='space-between' item>
        <FlatButton
          onClick={() => art.removeObject('element', element.id)}
          label='Remover'
        />
      </Grid>
    </Grid>
  )
}
