import { Box, Container, Grid, Typography } from '@material-ui/core'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useEffect, useState } from 'preact/hooks'
import { RaisedButton } from '../../common/button'
import { LinearLoadingIndicator } from '../../common/loading'
import { hostname } from '../../http/hostname'
import store from '../../stores/root'
import { userService } from '../../user/service'
import { useFbqSDK, useGtagSDK } from '../../utils/sdks'
import { SubscribeByCardForm } from './form'

const stripeKey =
  hostname === 'localhost'
    ? 'pk_test_51HEfJoE6LLbjSPyuqwjicLzJSEBDpE6ENmUMBhjhLQRAoVLOENW8rTEGHa7xxeGql7ZXVWEDWm11kBw1hM7URCgL00j2l94iWs'
    : 'pk_live_51HEfJoE6LLbjSPyu0VDtcfVRCfdjpO0HICuIpTkRocx5mld7jMYFoRzIfPEezmUYzlTcCwKzkcMQCKKqN5KlzVQg00f1WX5Uxi'

export const OrderPaymentScreen = () => {
  useFbqSDK()
  useGtagSDK()

  const user = store.auth.user

  const [success, setSuccess] = useState(false)
  const [busy, setBusy] = useState(false)

  useEffect(() => {}, [])

  const handlePaymentComplete = async () => {
    setSuccess(true)
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-10792278870/uVhPCJng5fwCENa2lJoo',
        transaction_id: user._id
      })
    }

    if (window.lintrk) {
      window.lintrk('track', { conversion_id: 7450961 })
    }

    if (window.fbq) {
      window.fbq('trackCustom', 'ComprouOfertaFacil', {
        content_name: 'Oferta Fácil',
        currency: 'BRL',
        value: 3
      })
    }
  }

  const reloadUser = async () => {
    setBusy(true)
    const user = await userService.byEmail(store.auth.user.email)
    const company = user.company
    store.auth.setCredentials({ user })
    store.auth.setTrialInfo(company.isTrial, company.trialEnd, company.retrial)
  }

  const stripePromise = loadStripe(stripeKey)

  if (busy) {
    return (
      <Container>
        <Box style={{ width: '100%' }} padding={0}>
          <LinearLoadingIndicator label='Aguarde um pouquinho, liberando o aplicativo...' />
        </Box>
      </Container>
    )
  }

  return (
    <Container>
      <Box style={{ width: '100%' }} padding={0}>
        {success ? (
          <Box padding={3}>
            <Box padding={2} />
            <Grid container direction='column' justify='center'>
              <img src='/assets/success.svg' height={76} />
              <Box padding={1} />
              <Typography
                style={{ color: '#33BA7C' }}
                variant='h5'
                align='center'
              >
                Pagamento concluído!
              </Typography>
              <Box padding={3} />
              <Typography variant='body2' align='center'>
                Seu pagamento foi realizado com sucesso e seu acesso está
                liberado!
              </Typography>
              <Box padding={2} />
              <RaisedButton primary onClick={reloadUser} label='Voltar' />
            </Grid>
          </Box>
        ) : (
          <Elements stripe={stripePromise}>
            <SubscribeByCardForm onPaymentComplete={handlePaymentComplete} />
          </Elements>
        )}
      </Box>
    </Container>
  )
}
