import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  LinearProgress,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core'
import MailOutlineOutlined from '@material-ui/icons/MailOutlineOutlined'
import { Fragment } from 'preact'
import { useEffect } from 'preact/hooks'
import { useHistory } from 'react-router'
import { FlatButton, RaisedButton } from '../common/button'
import Colors from '../config/colors'
import AppRoutes from '../navigation/routes'
import { useGoogleSDK } from '../utils/providers'
import { SignupVerification } from './verification'

export const SignupForm = props => {
  const history = useHistory()
  const user = useGoogleSDK('signup')

  const classes = useStyles()
  const {
    agreed,
    setAgreed,
    credentials,
    setCredentials,
    error,
    validationError,
    authenticateEmail,
    busy,
    verificationSent,
    authenticateGoogle,
    emailSignup,
    setEmailSignup
  } = props

  useEffect(() => {
    if (user.id) {
      authenticateGoogle(user)
    }
  }, [JSON.stringify(user)])

  if (verificationSent) {
    return <SignupVerification email={credentials.email} />
  }

  if (verificationSent) {
    return (
      <Grid className={classes.root}>
        <div className={classes.img}>
          <Typography
            style={{
              color: '#2196f3',
              fontFamily: 'GoogleSans',
              fontWeight: 'bolder'
            }}
            variant='h3'
            align='center'
          >
            +500 novas imagens
          </Typography>
          <Typography variant='h5' align='center'>
            todo mês para você postar e atrair mais clientes
          </Typography>
        </div>
        <Grid container direction='column' className={classes.sidebar}>
          <Grid item>
            <img src='/assets/logo_easyoffer_blue.svg' width={140} />
          </Grid>
          <Box padding={4} />
          <Grid item>
            <Grid container>
              <MailOutlineOutlined className={classes.mailIcon} />
              <Typography variant='h6'>Confirme seu e-mail</Typography>
            </Grid>
            <Box padding={2} />
            <Divider />
            <Box padding={2} />
            <Typography variant='body1'>
              Antes de logar com seu usuário e senha, você precisa confirmar seu
              endereço de e-mail. Nós enviamos um link de confirmação para o
              e-mail abaixo:
            </Typography>
            <Box padding={1} />
            <Typography variant='subtitle2'>{credentials.email}</Typography>
            <Box padding={1} />
            <Typography variant='subtitle2'>
              <strong>Verifique também sua caixa de SPAM ou Promoções</strong>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid className={classes.root}>
      <Grid item>
        <div className={classes.img}>
          <Typography
            style={{
              color: '#2196f3',
              fontFamily: 'GoogleSans',
              fontWeight: 'bolder'
            }}
            variant='h3'
            align='center'
          >
            +500 novas imagens
          </Typography>
          <Typography variant='h5' align='center'>
            todo mês para você postar e atrair mais clientes
          </Typography>
        </div>
      </Grid>
      <Grid className={classes.sidebar}>
        <Grid item>
          <img src='/assets/logo_easyoffer_blue.svg' width={140} />
          <Box padding={2} />
        </Grid>
        {error && (
          <Grid item>
            <Box padding={2}>
              <Typography variant='body1' color='error'>
                {error}
              </Typography>
            </Box>
          </Grid>
        )}
        <Grid item>
          <Typography variant='h6'>Cadastrar nova conta</Typography>
        </Grid>
        <Grid item>
          <Box padding={2} />
          <FormControlLabel
            style={{ marginRight: 0 }}
            control={
              <Checkbox
                color='primary'
                checked={agreed}
                onChange={() => setAgreed(!agreed)}
              />
            }
            label={
              <Typography variant='caption'>
                Ao clicar em Testar Grátis eu afirmo que li e concordei com o{' '}
                <a
                  style={{ color: Colors.accentColor }}
                  href='https://ofertafacilapp.com.br/termos-de-uso'
                  rel='noreferrer'
                  target='_blank'
                >
                  termo de uso
                </a>{' '}
                e a{' '}
                <a
                  style={{ color: Colors.accentColor }}
                  href='https://ofertafacilapp.com.br/politica-de-privacidade'
                  rel='noreferrer'
                  target='_blank'
                >
                  política de privacidade
                </a>
              </Typography>
            }
          />
        </Grid>
        {busy && (
          <Box padding={4}>
            <LinearProgress variant='indeterminate' />
          </Box>
        )}
        {!busy && (
          <Fragment>
            {!emailSignup && (
              <Fragment>
                <Box padding={2} />
                <div id='google-button' />
                <Box padding={1} />
                <Typography variant='caption' align='center'>
                  ou
                </Typography>
              </Fragment>
            )}
            <Grid container>
              {emailSignup && (
                <Grid container>
                  <Box padding={2} />
                  <Grid item xs={12}>
                    <TextField
                      type='email'
                      label='E-mail'
                      variant='outlined'
                      fullWidth
                      error={validationError && Boolean(!credentials.email)}
                      helperText={
                        validationError && !credentials.email
                          ? 'Digite um email válido'
                          : ''
                      }
                      value={credentials.email}
                      onChange={e =>
                        setCredentials({
                          ...credentials,
                          email: e.target.value
                        })
                      }
                    />
                  </Grid>
                  <Box padding={1} />
                  <Grid item xs={12}>
                    <TextField
                      type='password'
                      label='Senha'
                      variant='outlined'
                      error={validationError && Boolean(!credentials.password)}
                      helperText={
                        validationError && !credentials.password
                          ? 'Digite uma senha'
                          : ''
                      }
                      fullWidth
                      value={credentials.password}
                      onChange={e =>
                        setCredentials({
                          ...credentials,
                          password: e.target.value
                        })
                      }
                    />
                  </Grid>
                  <Box padding={1} />
                  <RaisedButton
                    primary
                    fullWidth
                    size='large'
                    label='Registrar com e-mail'
                    onClick={authenticateEmail}
                  />
                </Grid>
              )}
              <Grid container>
                <Box padding={2} />
                <FlatButton
                  onClick={() => setEmailSignup(!emailSignup)}
                  fullWidth
                  label={
                    emailSignup
                      ? 'Registrar com Google'
                      : 'Registrar com e-mail'
                  }
                />
              </Grid>
            </Grid>
            <Box padding={2} />
            <Grid container justify='center'>
              <Grid item>
                <FlatButton
                  primary
                  label='Já possui uma conta e quer acessar?'
                  onClick={() => history.push(AppRoutes.Login)}
                />
              </Grid>
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100vw',
    height: '100vh',
    backgroundSize: 'cover',
    overflowY: 'scroll',
    background: `url(/assets/images/gallaxy.svg) no-repeat`
  },
  sidebar: {
    position: 'fixed',
    overflowY: 'auto',
    zIndex: 999,
    height: '100vh',
    maxWidth: 420,
    minWidth: 360,
    display: 'flex',
    flexDirection: 'column',
    background: Colors.white,
    padding: theme.spacing(4),
    '-moz-box-shadow': '2px 2px 5px 3px rgba(0,0,0,0.05)',
    '-webkit-box-shadow': '2px 2px 5px 3px rgba(0,0,0,0.05)',
    boxShadow: '2px 2px 5px 3px rgba(0,0,0,0.05)'
  },
  img: {
    background: 'url("/assets/images/500-images.png") no-repeat',
    backgroundPosition: 'center',
    alignSelf: 'center',
    left: '40%',
    top: '20%',
    width: '100vw',
    height: '60vh',
    position: 'fixed',
    maxWidth: 640
  },
  containerForm: {
    width: 570,
    height: 'fit-content',
    marginTop: '5%',
    padding: 32,
    borderRadius: 16
  },
  loginButton: {
    marginTop: 2,
    marginLeft: 0,
    backgroundColor: '#2196F3',
    color: 'white'
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alingItems: 'center',
    marginTop: 15
  },
  backgroundForm: {
    minWidth: 500,
    padding: 0,
    margin: 0,
    marginBottom: 15,
    borderRadius: (5, 5, 5, 5),
    backgroundColor: '#2196F3',
    textAlign: 'center'
  },
  form: {
    marginTop: '5%'
  },
  mailIcon: {
    marign: 12,
    marginRight: 16
  },
  register: {
    justifyContent: 'space-between'
  }
}))
