import { API_ROOT_URL } from '../http/hostname'

export default async function uploadImage (file, company, type = 'logo') {
  try {
    let formData = new FormData()
    formData.append('photo', file)
    formData.append('company', company)

    const url = await fetch(`${API_ROOT_URL}/upload/${type}`, {
      headers: {
        Authorization: localStorage.getItem('token')
      },
      method: 'POST',
      body: formData
    })
      .then(r => r.json())
      .then(r => r.data)

    return url
  } catch (e) {
    return null
  }
}
