import graphql from '../../http/graphql'
import {
  resetSubscriptionMutation,
  subscribeMutation
} from '../graphql/mutations'
import {
  stripeInvoicesQuery,
  stripeSubscription,
  subscriptionQuery
} from '../graphql/queries'

class SubscriptionProvider {
  subscription = async () => {
    return graphql.query(subscriptionQuery).then(d => d.subscription)
  }

  retrial = email => {
    return graphql.get(`/retrial?email=${email}`)
  }

  subscribe = async data => {
    return graphql.query(subscribeMutation, { data }).then(d => d.subscribe)
  }

  stripeInvoices = async () => {
    return graphql.query(stripeInvoicesQuery).then(d => d.stripeInvoices)
  }

  stripeSubscription = async () => {
    return graphql.query(stripeSubscription).then(d => d.stripeSubscription)
  }

  resetSubscription = async () => {
    return graphql
      .query(resetSubscriptionMutation)
      .then(d => d.resetSubscription)
  }
}

export const subscriptionProvider = new SubscriptionProvider()
