import { useEffect } from 'preact/hooks'

export const useGtagSDK = () => {
  useEffect(() => {
    window.dataLayer = []
    const s = document.createElement('script')
    s.type = 'text/javascript'
    s.src = 'https://www.googletagmanager.com/gtag/js?id=AW-10792278870'
    s.async = true
    document.getElementsByTagName('head')[0].appendChild(s)
    s.onload = function () {
      window.gtag = function () {
        window.dataLayer.push(arguments)
      }
      window.gtag('js', new Date())
      window.gtag('config', 'AW-10792278870')
    }
  }, [])
}

export const useFbqSDK = () => {
  useEffect(() => {
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    window.fbq('init', '757319768435430')
    window.fbq('track', 'PageView')
  }, [])
}
