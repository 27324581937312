import {
  Box,
  Container,
  Divider,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core'
import { Fragment } from 'preact'
import { useState } from 'preact/hooks'
import { useHistory } from 'react-router'
import { LinearLoadingIndicator } from '../common/loading'
import formats from '../formats'
import AppRoutes from '../navigation/routes'
import { captureWithScope } from '../sentry'
import store from '../stores/root'
import { useMobileDetect } from '../utils/mobile'
import offerService from './providers/offer'

const useStyles = makeStyles({
  format: {
    cursor: 'pointer',
    marginBottom: 8
  },
  image: {
    borderRadius: 8
  }
})

export const OfferFormats = () => {
  const mobileDetect = useMobileDetect()
  const history = useHistory()
  const classes = useStyles()

  const [creating, setCreating] = useState(false)

  const handleNavigation = key => {
    const route = mobileDetect.isMobile()
      ? `${AppRoutes.EditOfferMobile.replace(':key', key)}/arte`
      : `${AppRoutes.EditOffer.replace(':key', key)}/arte`

    history.push(route)
  }

  const handleCreateOffer = async format => {
    setCreating(true)

    try {
      const { _key } = await offerService.update({
        format: {
          code: format.code,
          height: format.height,
          ratio: format.ratio,
          size: format.size,
          vertical: format.vertical,
          width: format.width
        }
      })
      handleNavigation(_key)
    } catch (e) {
      setCreating(false)
      captureWithScope(e)
      store.layout.showSnackbar(
        'Erro ao criar o encarte, tente novamente',
        true
      )
    }
  }

  return (
    <Container maxWidth='md' disableGutters>
      <Grid container spacing={3}>
        {creating ? (
          <Grid container alignItems='center' justify='center'>
            <Box padding={3} />
            <LinearLoadingIndicator label='Aguenta um pouquinho, carregando o encarte...' />
          </Grid>
        ) : (
          <Fragment>
            <Grid item xs={12}>
              <Typography variant='h6'>
                Qual encarte você deseja criar?
              </Typography>
              <Box padding={1} />
              <Divider />
            </Grid>
            <Grid
              className={classes.format}
              onClick={() => handleCreateOffer(formats[0])}
              item
            >
              <img
                className={classes.image}
                src='/assets/images/formato-stories.jpg'
                width='148'
              />
              <Typography align='center' variant='body2'>
                <strong>Tela cheia</strong>
              </Typography>
            </Grid>
            <Grid
              className={classes.format}
              onClick={() => handleCreateOffer(formats[1])}
              item
            >
              <img
                className={classes.image}
                src='/assets/images/formato-feed.jpg'
                width='148'
              />
              <Typography align='center' variant='body2'>
                <strong>Médio</strong>
              </Typography>
            </Grid>
            <Grid
              className={classes.format}
              onClick={() => handleCreateOffer(formats[2])}
              item
            >
              <img
                className={classes.image}
                src='/assets/images/formato-square.jpg'
                width='148'
              />
              <Typography align='center' variant='body2'>
                <strong>Quadrado</strong>
              </Typography>
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Container>
  )
}
