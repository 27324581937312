import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core'
import { Fragment } from 'preact'
import { useState } from 'preact/hooks'
import useSWR from 'swr'
import { FlatButton } from '../../common/button'
import { LinearLoadingIndicator } from '../../common/loading'
import store from '../../stores/root'
import { applyTemplateToOffer } from '../apply'
import { templateProvider } from '../providers/template'
import { TemplateList } from './list'

export const TemplateListContainer = () => {
  const { format } = store.art.offer

  const [confirm, setConfirm] = useState(null)

  const handleClose = () => {
    setConfirm(null)
  }

  const handleApplyTemplate = () => {
    applyTemplateToOffer({ ...confirm })
    setConfirm(null)
  }

  const { data: templates } = useSWR(
    [format?.code || '', 'templates'],
    (...args) => templateProvider.templates(...args)
  )

  const busy = !templates

  return (
    <Fragment>
      <Dialog maxWidth='xs' open={confirm || false} onClose={handleClose}>
        <DialogTitle>Confirme</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Gostaria de aplicar esse modelo? Ele vai substituir todas as suas
            alterações.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box padding={1}>
            <FlatButton
              style={{ marginRight: 16 }}
              onClick={handleClose}
              label='Cancelar'
            />
            <FlatButton onClick={handleApplyTemplate} primary label='Aplicar' />
          </Box>
        </DialogActions>
      </Dialog>
      <Box padding={2}>
        {busy && <LinearLoadingIndicator />}
        {!busy && templates && templates.length === 0 && (
          <Grid container justify='center'>
            <Box padding={3}>
              <Typography variant='body1' align='center'>
                Ops! Nenhum modelo ainda para esse formato
              </Typography>
              <br />
              <Typography variant='caption' align='center'>
                Toda semana estamos adicionando novos modelos
              </Typography>
            </Box>
          </Grid>
        )}
        {!busy && templates && templates.length > 0 && (
          <TemplateList
            onSelect={setConfirm}
            format={store.art.offer.format}
            templates={templates}
            art={store.art}
          />
        )}
      </Box>
    </Fragment>
  )
}
