export const FolderIcon = () => {
  return (
    <svg width='56' height='56' viewBox='0 0 56 56'>
      <rect width='56' height='56' fill='none' />
      <path
        d='M48,14H32L27.17,9.17A4,4,0,0,0,24.34,8H8a4,4,0,0,0-4,4V40a4,4,0,0,0,4,4H48a4,4,0,0,0,4-4V18A4,4,0,0,0, 48,14Z'
        opacity='0.3'
      />
    </svg>
  )
}

export const RecentFolderIcon = () => {
  return (
    <svg width='56' height='56' viewBox='0 0 56 56'>
      <rect width='56' height='56' fill='none' />
      <path
        d='M48,14H32L27.17,9.17A4,4,0,0,0,24.34,8H8a4,4,0,0,0-4,4V40a4,4,0,0,0,4,4H48a4,4,0,0,0,4-4V18A4,4,0,0,0, 48,14Z'
        opacity='0.3'
      />
      <path
        d='M28,24A12,12,0,1,0,40,36,12,12,0,0,0,28,24Zm6.5,18.5a1.49,1.49,0,0,1-2.12,0l-5.44-5.44h0a1.75,1.75,0,0, 1-.32-.48v0A1.46,1.46,0,0,1,26.5,36V30a1.5,1.5,0,0,1,3,0v5.38l5,5A1.49,1.49,0,0,1,34.5,42.5Z'
        fill='#fff'
      />
    </svg>
  )
}

export const FavoritesIcon = () => {
  return (
    <svg width='56' height='56' viewBox='0 0 56 56'>
      <rect width='56' height='56' fill='none' />
      <path
        d='M48,14H32L27.17,9.17A4,4,0,0,0,24.34,8H8a4,4,0,0,0-4,4V40a4,4,0,0,0,4,4H48a4,4,0,0,0,4-4V18A4,4,0,0,0, 48,14Z'
        opacity='0.3'
      />
      <path
        d='M28.9,23.82l3.42,6.93a1,1,0,0,0,.75.54l7.65,1.11a1,1,0,0,1,.55,1.71L35.74,39.5a1,1,0,0,0-.29.89L36.76, 48a1,1,0,0,1-1.46,1.06l-6.83-3.6a1,1,0,0,0-.94,0l-6.83,3.6A1,1,0,0,1,19.24,48l1.31-7.61a1,1,0,0, 0-.29-.89l-5.53-5.39a1,1,0,0,1,.55-1.71l7.65-1.11a1,1,0,0,0,.75-.54l3.42-6.93A1,1,0,0,1,28.9,23.82Z'
        fill='#fff'
      />
    </svg>
  )
}

export const UploadsIcon = () => {
  return (
    <svg width='56' height='56' viewBox='0 0 56 56'>
      <rect width='56' height='56' fill='none' />
      <path
        d='M48,14H32L27.17,9.17A4,4,0,0,0,24.34,8H8a4,4,0,0,0-4,4V40a4,4,0,0,0,4,4H48a4,4,0,0,0,4-4V18A4, 4,0,0,0,48,14Z'
        opacity='0.3'
      />
      <path
        d='M14,36,28,22,42,36H34V48a2,2,0,0,1-2,2H24a2,2,0,0,1-2-2V36Z'
        fill='#fff'
      />
    </svg>
  )
}

export const HoriziontalAlignment = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 202.659 202.659'
      height='16'
      width='16'
    >
      <g>
        <g>
          <g>
            <path
              d='M171.448,124.71v-0.001c2.152,0,3.897-1.745,3.897-3.897V81.847c0-2.152-1.745-3.897-3.897-3.897H38.966
				c-2.152,0-3.897,1.745-3.897,3.897v38.966c0,2.152,1.745,3.897,3.897,3.897H171.448z M42.862,85.743h124.69v31.172H42.862V85.743
				z'
            />
            <rect y='46.778' width='202.659' height='7.793' />
            <rect y='148.088' width='202.659' height='7.793' />
          </g>
        </g>
      </g>
    </svg>
  )
}

export const ExpandIcon = () => {
  return (
    <svg
      enable-background='new 0 0 20 20'
      height='14'
      viewBox='0 0 20 20'
      width='14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='#3C4043'>
        <path d='m14 0v2h2.6l-4.6 4.6 1.4 1.4 4.6-4.6v2.6h2v-6z' />
        <path d='m18 16.6-4.6-4.6-1.4 1.4 4.6 4.6h-2.6v2h6v-6h-2z' />
        <path d='m6 2v-2h-6v6h2v-2.6l4.6 4.6 1.4-1.4-4.6-4.6z' />
        <path d='m6.6 12-4.6 4.6v-2.6h-2v6h6v-2h-2.6l4.6-4.6z' />
      </g>
    </svg>
  )
}

export const ShrinkIcon = () => {
  return (
    <svg
      enable-background='new 0 0 20 20'
      height='16'
      viewBox='0 0 20 20'
      width='16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='#3C4043'>
        <path d='m18 6h-2.6l4.6-4.6-1.4-1.4-4.6 4.6v-2.6h-2v6h6z' />
        <path d='m18 14v-2h-6v6h2v-2.6l4.6 4.6 1.4-1.4-4.6-4.6z' />
        <path d='m6 4.6-4.6-4.6-1.4 1.4 4.6 4.6h-2.6v2h6v-6h-2z' />
        <path d='m2 14h2.6l-4.6 4.6 1.4 1.4 4.6-4.6v2.6h2v-6h-6z' />
      </g>
    </svg>
  )
}

export const AlignTopIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
    >
      <path
        fill='#3C4043'
        d='M21 4c0 .41-.34.75-.75.75H3.75a.75.75 0 0 1 0-1.5h16.5c.41 0 .75.34.75.75zM11 9v9a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V9c0-1.1.9-2 2-2h1a2 2 0 0 1 2 2zm7 0v4a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2V9c0-1.1.9-2 2-2h1a2 2 0 0 1 2 2zM9.5 9a.5.5 0 0 0-.5-.5H8a.5.5 0 0 0-.5.5v9c0 .28.22.5.5.5h1a.5.5 0 0 0 .5-.5V9zm7 0a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v4c0 .28.22.5.5.5h1a.5.5 0 0 0 .5-.5V9z'
      />
    </svg>
  )
}

export const AlignMiddleIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
    >
      <path
        fill='currentColor'
        d='M11 11.25h2V7c0-1.1.9-2 2-2h1a2 2 0 0 1 2 2v4.25h2.25a.75.75 0 1 1 0 1.5H18V17a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-4.25h-2V14a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-1.25H3.75a.75.75 0 1 1 0-1.5H6V10c0-1.1.9-2 2-2h1a2 2 0 0 1 2 2v1.25zM16.5 7a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v10c0 .28.22.5.5.5h1a.5.5 0 0 0 .5-.5V7zm-7 3a.5.5 0 0 0-.5-.5H8a.5.5 0 0 0-.5.5v4c0 .28.22.5.5.5h1a.5.5 0 0 0 .5-.5v-4z'
      />
    </svg>
  )
}

export const AlignBottomIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
    >
      <path
        fill='currentColor'
        d='M21 20c0 .41-.34.75-.75.75H3.75a.75.75 0 1 1 0-1.5h16.5c.41 0 .75.34.75.75zM11 6v9a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h1a2 2 0 0 1 2 2zm7 5v4a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-4c0-1.1.9-2 2-2h1a2 2 0 0 1 2 2zM9.5 6a.5.5 0 0 0-.5-.5H8a.5.5 0 0 0-.5.5v9c0 .28.22.5.5.5h1a.5.5 0 0 0 .5-.5V6zm7 5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v4c0 .28.22.5.5.5h1a.5.5 0 0 0 .5-.5v-4z'
      />
    </svg>
  )
}
