import graphql from '../../http/graphql'
import {
  addFavoriteMutation,
  removeFavoriteMutation
} from '../graphql/mutations'
import { favoritesQuery } from '../graphql/queries'

class FavoriteProvider {
  favorites = async type => {
    return graphql.query(favoritesQuery, { type }).then(b => b.favorites)
  }

  add = async (type, key) => {
    return graphql
      .query(addFavoriteMutation, { type, key })
      .then(b => b.favorites)
  }

  remove = async key => {
    return graphql.query(removeFavoriteMutation, { key }).then(b => b.favorites)
  }
}

export const favoriteProvider = new FavoriteProvider()
