import { MoreHorizOutlined } from '@material-ui/icons'
import { useCallback, useState } from 'preact/hooks'
import { useEventListener } from '../utils/event'
import { getImageUrl } from '../utils/url'

export const Thumbnail = props => {
  const [showControls, setShowControls] = useState(false)
  const { image, onMenuClick, children } = props

  const elementId = `thumb-${image._id}`
  const thumb = getImageUrl(image.imageURL, 180)

  const onMouseEnter = useCallback(() => {
    setShowControls(true)
  }, [showControls])

  const onMouseLeave = useCallback(() => {
    setShowControls(false)
  }, [showControls])

  useEventListener('mouseenter', onMouseEnter, elementId)
  useEventListener('mouseleave', onMouseLeave, elementId)

  return (
    <div
      id={elementId}
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        padding: 4
      }}
    >
      <img
        title={image.name}
        style={{
          width: '100%'
        }}
        src={thumb}
      />
      <MoreHorizOutlined
        onClick={e => {
          e.stopPropagation()
          onMenuClick(e.currentTarget)
        }}
        style={{
          visibility: showControls ? 'visible' : 'hidden',
          borderRadius: '50%',
          color: 'white',
          backgroundColor: '#00000064',
          position: 'absolute',
          bottom: 4,
          padding: 3,
          right: 4
        }}
      />
      {children}
    </div>
  )
}
