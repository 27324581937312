import { captureException, init, withScope } from '@sentry/browser'
import { hostname } from './http/hostname'

init({
  dsn: 'https://9d668c2b6b4d4921a3fce94499f0f554@sentry.berry.tech/5',
  environment: hostname === 'localhost' ? 'app_dev' : 'app_prod'
})

export let captureWithScope = e => {
  if (typeof e === 'string') {
    e = new Error(e)
  }

  if (e) {
    hostname === 'localhost' && console.log(e)
    captureException(e)
  }
}

export const initSentryScope = user => {
  captureWithScope = e => {
    hostname === 'localhost' && console.log(e)
    withScope(scope => {
      const subscribed = user.company.subscription != null
      scope.setUser({ email: user.email })
      scope.setContext('subscription', {
        subscribed,
        trial: !subscribed,
        trialEnd: user.company.trialEnd
      })
      captureException(e)
    })
  }
}
