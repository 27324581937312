export const KeyboardKey = ({ label }) => {
  return (
    <span
      style={{
        border: '1px solid #ededed',
        borderRadius: 4,
        backgroundColor: 'white',
        padding: 5,
        marginLeft: 8,
        marginRight: 4
      }}
    >
      {label}
    </span>
  )
}
