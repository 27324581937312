import ArtStore from './art'
import AuthStore from './auth'
import { FavoriteStore } from './favorite'
import { FolderStore } from './folder'
import { LayoutStore } from './layout'
import { MobileStore } from './mobile'

class RootStore {
  constructor() {
    this.art = new ArtStore(this)
    this.auth = new AuthStore(this)
    this.layout = new LayoutStore(this)
    this.favorite = new FavoriteStore(this)
    this.folder = new FolderStore(this)
    this.mobile = new MobileStore(this)
  }
}

const store = new RootStore()
export default store
