import { IconButton } from '@material-ui/core'
import { LabelOffOutlined, LabelOutlined } from '@material-ui/icons'
import store from '../stores/root'

export const TagToggle = ({ showTags }) => {
  const show = showTags == null ? false : showTags
  return (
    <IconButton
      title='Mostrar ou esconder as estiquetas'
      onClick={() => store.art.setOfferProperty('showTags', !show)}
    >
      {show ? <LabelOutlined /> : <LabelOffOutlined />}
    </IconButton>
  )
}
