export const loginUsingProviderQuery = `
  query LoginUsingProvider(id: String!, provider: String!) {
    loginUsingProvider(id: $id, provider: $provider) {
      token
    }
  }
`

export const stripeSession = `
  query session {
    session {
      customer
      url
    }
  }
`
