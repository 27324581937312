export const addFavoriteMutation = `
  mutation addFavorite($type: String!, $key: String!) {
    addFavorite(type: $type, key: $key)
  }
`

export const removeFavoriteMutation = `
  mutation RemoveFavorite($key: String!) {
    removeFavorite(key: $key)
  }
`
