import { action, observable } from 'mobx'
import { favoriteProvider } from '../favorite/providers/favorite'

export class FavoriteStore {
  constructor (root) {
    this.root = root
  }

  @observable favorites = []

  @action
  addProduct = async id => {
    await favoriteProvider.add('product', id)
    this.favorites.push(id)
    this.favorites = [...this.favorites]
  }

  @action
  removeProduct = async id => {
    await favoriteProvider.remove(id)
    const index = this.favorites.indexOf(id)
    this.favorites.splice(index, 1)
    this.favorites = [...this.favorites]
  }

  @action
  loadFavorites = async () => {
    try {
      const favorites = await favoriteProvider.favorites()
      this.favorites = favorites
    } catch (e) {
      this.root.layout.showSnackbar(e.message, true)
    }
  }
}
