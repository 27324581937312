import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { inject, observer } from 'mobx-preact'
import { useEffect, useState } from 'preact/hooks'
import { useHistory } from 'react-router'
import { Redirect, Route } from 'react-router-dom'
import { RaisedButton } from '../common/button'
import { captureWithScope, initSentryScope } from '../sentry'
import store from '../stores/root'
import { subscriptionProvider } from '../subscription/providers/subscription'
import { userService } from '../user/service'
import AppRoutes from './routes'

function ProtectedRouteComponent({ auth, ...rest }) {
  const history = useHistory()

  const [token, setToken] = useState(null)
  const [error, setError] = useState(false)

  useEffect(() => {
    const updateUserInfo = async () => {
      const storedUser = localStorage.getItem('user')
      try {
        const parsedUser = JSON.parse(storedUser)
        if (!parsedUser) {
          return <Redirect to={AppRoutes.Login} />
        }

        if (!parsedUser.email) {
          return history.replace(AppRoutes.Login, true)
        }

        if (!parsedUser.email) {
          history.replace(AppRoutes.Login, true)
        }

        const user = await userService.byEmail(parsedUser.email)

        store.folder.loadFolders()
        store.favorite.loadFavorites()

        window.$crisp && window.$crisp.push(['set', 'user:email', [user.email]])
        const company = user.company

        if (!company) {
          return history.replace(AppRoutes.Login, true)
        }

        initSentryScope(user)

        try {
          const invoices = await subscriptionProvider.stripeInvoices()
          const subscription = await subscriptionProvider.stripeSubscription()
          auth.setSubscription(subscription)
          auth.setInvoices(invoices)
        } catch (error) {
          console.log(error)
        }

        auth.setCredentials({ user, token })
        auth.setTrialInfo(company.isTrial, company.trialEnd, company.retrial)
        auth.updateUser({ ...user, company: { ...user.company } })
      } catch (e) {
        setError(true)
        captureWithScope(e)
      }
    }
    if (!token) {
      updateUserInfo()
    }
  }, [token])

  let storedToken = localStorage.getItem('token')
  if (!storedToken) {
    return <Redirect to={AppRoutes.Login} />
  }

  setToken(storedToken)

  if (error) {
    return (
      <Grid
        container
        style={{ height: '100vh', width: '100vw' }}
        direction='column'
        alignContent='center'
        justify='center'
        alignItems='center'
        spacing={2}
      >
        <Grid item>
          <Typography>Ops! ocorreu um erro ao carregar a página.</Typography>
        </Grid>
        <Grid item>
          <RaisedButton
            primary
            onClick={() => window.location.reload()}
            label='Recarregar'
          />
        </Grid>
      </Grid>
    )
  }

  if (!auth.user) {
    return (
      <Grid
        container
        style={{ height: '100vh', width: '100vw' }}
        alignContent='center'
        justify='center'
      >
        <CircularProgress />
      </Grid>
    )
  }

  return <Route {...rest}>{rest.children}</Route>
}

export const ProtectedRoute = inject('auth')(observer(ProtectedRouteComponent))
