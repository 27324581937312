import { Grid, IconButton, Typography } from '@material-ui/core'
import { KeyboardArrowLeft } from '@material-ui/icons'
import { useHistory } from 'react-router'

export const BackButton = ({ onClick, label }) => {
  const history = useHistory()

  return (
    <Grid container alignItems='center'>
      <Grid item>
        <IconButton
          style={{ marginRight: 16 }}
          onClick={() => {
            if (onClick) {
              onClick()
            } else {
              history.go(-1)
            }
          }}
        >
          <KeyboardArrowLeft />
        </IconButton>
      </Grid>
      <Grid item xs={8}>
        <Typography variant='h6' align='center'>
          {label}
        </Typography>
      </Grid>
    </Grid>
  )
}
