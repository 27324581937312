export function dataURLtoBlob (dataURI) {
  const mime = dataURI.split(';base64,')[0].split(':')[1]
  const filename =
    'dataURI-file-' + new Date().getTime() + '.' + mime.split('/')[1]
  const bytes = atob(dataURI.split(';base64,')[1])
  const writer = new Uint8Array(new ArrayBuffer(bytes.length))

  for (let i = 0; i < bytes.length; i++) {
    writer[i] = bytes.charCodeAt(i)
  }

  return new File([writer.buffer], filename, { type: mime })
}
