import { useEffect } from 'preact/hooks'

const getMobileDetect = userAgent => {
  const isAndroid = () => Boolean(userAgent.match(/Android/i))
  const isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i))
  const isOpera = () => Boolean(userAgent.match(/Opera Mini/i))
  const isWindows = () => Boolean(userAgent.match(/IEMobile/i))
  const isSSR = () => Boolean(userAgent.match(/SSR/i))
  const isSmallScreen = () => window.document.documentElement.clientWidth < 480

  const isMobile = () =>
    Boolean(
      isAndroid() || isIos() || isOpera() || isWindows() || isSmallScreen()
    )
  const isDesktop = () => Boolean(!isMobile() && !isSSR())
  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
    isSSR
  }
}

export const useMobileDetect = () => {
  useEffect(() => {}, [])
  const userAgent =
    typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent
  return getMobileDetect(userAgent)
}
