export const foldersQuery = `
  query folders {
    folders {
      _id
      _key
      _rev
      type
      name
      items
    }
  }
`

export const folderQuery = `
  query folder($id: String!) {
    folders(id: $id) {
      _id
      _key
      _rev
      type
      name
      items
    }
  }
`

export const productsForFolderQuery = `
  query productsForFolder($folder: String!) {
    productsForFolder(folder: $folder) {
      _id
      _key
      name
      imageURL
    }
  }
`
