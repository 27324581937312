import {
  Box,
  Divider,
  Grid,
  IconButton,
  Slider,
  Toolbar,
  Typography
} from '@material-ui/core'
import {
  KeyboardArrowRightOutlined,
  LabelOffOutlined,
  LabelOutlined
} from '@material-ui/icons'
import Konva from 'konva'
import { useEffect, useState } from 'preact/hooks'
import NumberFormat from 'react-number-format'
import { FlatButton } from '../../common/button'
import { CustomTextField } from '../../common/input'
import Colors from '../../config/colors'
import { CDN } from '../../http/hostname'
import store from '../../stores/root'
import { useMobileDetect } from '../../utils/mobile'
import { getImageUrl } from '../../utils/url'

const clubTagUrl = `${CDN}/6064616d2137cd0001388745-tag-clube.png`

export const ProductMenu = ({ art }) => {
  const mobileDetect = useMobileDetect()

  const [ref, setRef] = useState(null)
  const [canvas, setCanvas] = useState(null)
  const [size, setSize] = useState(70)
  const [product, setProduct] = useState(null)

  const initCanvas = () => {
    const konva = new Konva.Stage({
      color: '#ededed',
      container: 'product-canvas',
      width: 336,
      height: 260
    })

    const layer = new Konva.Layer({ id: 'default-layer' })
    konva.setScale({ x: 0.25, y: 0.25 })

    konva.add(layer)
    return konva
  }

  const drawProduct = async (canvas, product) => {
    if (!canvas || !product) return

    let fontSize = 70
    if (product.nameAttrs) {
      const attrs = JSON.parse(product.nameAttrs)
      if (attrs && attrs.fontSize) {
        fontSize = attrs.fontSize
        setSize(attrs.fontSize)
      }
    }

    const clearTag = canvas.findOne(el => el.id() === 'tag-transformer')
    clearTag && clearTag.setNodes([])
    const clearName = canvas.findOne(el => el.id() === 'name-transformer')
    clearName && clearName.setNodes([])

    const layer = canvas.findOne(el => el.id() === 'default-layer')
    canvas._nameFontSize = fontSize

    const productImg = await new Promise(resolve => {
      const i = new Image()
      i.onload = () => resolve(i)
      i.src = getImageUrl(product.imageURL)
      i.crossOrigin = 'Anonymous'
    })

    const existingImage = canvas.findOne(el => el.name() === 'product-image')
    const productKonvaImg = existingImage || new Konva.Image()

    productKonvaImg.setAttrs({
      name: 'product-image',
      image: productImg,
      offsetX: productImg.width / 2,
      offsetY: productImg.height / 2,
      width: productImg.width,
      height: productImg.height
    })

    !existingImage && layer.add(productKonvaImg)

    productKonvaImg.setAttrs({
      x: canvas.width() * 1.8,
      y: canvas.height() * 2
    })

    canvas.updateFontSize = function (size) {
      const konvaName = canvas.findOne(el => el.name() == 'product-name')
      canvas._nameFontSize = size
      if (konvaName) {
        const centerNameOnProduct =
          productKonvaImg.x() + productKonvaImg.width() / 4
        konvaName.setAttrs({
          fontSize: size,
          x: centerNameOnProduct,
          y:
            productKonvaImg.y() -
            productKonvaImg.height() / 2 -
            konvaName.height()
        })
        const update = {
          fontSize: size,
          x: productKonvaImg.x() - konvaName.x(),
          y: productKonvaImg.y() - konvaName.y(),
          width: konvaName.width(),
          offsetX: konvaName.offsetX,
          offsetY: konvaName.offsetY
        }
        let attrs = {}
        if (product.nameAttrs) {
          attrs = Object.assign(attrs, JSON.parse(product.nameAttrs))
        }
        art.updateObjectOnCanvas('product', product.id, {
          nameAttrs: JSON.stringify(Object.assign(attrs, update))
        })
        canvas.draw()
      }
    }

    // ---------------------- product tag ------------------------//

    const tagImg = await new Promise(resolve => {
      const i = new Image()
      i.onload = () => resolve(i)
      i.src = getImageUrl(art.offer.tag.imageURL)
      i.crossOrigin = 'Anonymous'
    })

    // ---------------------- club tag ------------------------//

    const clubTagImg = await new Promise(resolve => {
      const i = new Image()
      i.onload = () => resolve(i)
      i.src = clubTagUrl
      i.crossOrigin = 'Anonymous'
    })

    const existingClubTag = canvas.findOne(el => el.id() === 'club-tag')
    const clubTagKonvaImg = existingClubTag || new Konva.Image()

    clubTagKonvaImg.setAttrs({
      id: 'club-tag',
      name: 'tag',
      image: clubTagImg,
      width: clubTagImg.width * 1.2,
      offsetX: clubTagImg.x / 2,
      offsetY: clubTagImg.y / 2,
      height: clubTagImg.height * 1.2,
      visible: Boolean(product.clubPrice),
      draggable: true
    })

    clubTagKonvaImg.setAttrs({
      x: productKonvaImg.x() - productKonvaImg.width(),
      y: productKonvaImg.y()
    })

    !existingClubTag && layer.add(clubTagKonvaImg)

    const existingClubPrice = canvas.findOne(el => el.name() === 'club-price')
    const konvaClubPrice = existingClubPrice || new Konva.Text()

    konvaClubPrice.setAttrs({
      name: 'club-price',
      text: product.clubPrice || '',
      fontSize: 95,
      fontWeight: 900,
      fontStyle: 'bold',
      fontFamily: 'Montserrat',
      absolutePositioned: true,
      textAlign: 'center',
      fill: '#ffffff',
      visible: Boolean(product.clubPrice),
      draggable: false
    })

    konvaClubPrice.setAttrs({
      x:
        clubTagKonvaImg.x() +
        clubTagKonvaImg.width() / 2 -
        konvaClubPrice.width() / 2,
      y:
        clubTagKonvaImg.y() +
        clubTagKonvaImg.height() / 2 -
        konvaClubPrice.height() / 2
    })

    !existingClubPrice && layer.add(konvaClubPrice)

    const existingTag = canvas.findOne(el => el.id() === 'product-tag')
    const tagKonvaImg = existingTag || new Konva.Image()

    if (existingTag) {
      tagKonvaImg.off('click tap dragstart')
      tagKonvaImg.off('dragmove')
      tagKonvaImg.off('transformend dragend')
      tagKonvaImg.off('transformstart')
    }

    tagKonvaImg.setAttrs({
      id: 'product-tag',
      name: 'tag',
      image: tagImg,
      width: tagImg.width,
      offsetX: tagImg.width / 2,
      offsetY: tagImg.height / 2,
      height: tagImg.height,
      visible: product.tagVisible,
      draggable: true
    })

    tagKonvaImg.setAttrs({
      x: productKonvaImg.x() + tagKonvaImg.width() / 2,
      y: productKonvaImg.y() + tagKonvaImg.height() / 2
    })

    // ---------------- product tag ---------------

    if (product.tagAttrs) {
      const attrs = JSON.parse(product.tagAttrs)
      if (attrs.x && attrs.y) {
        attrs.x = productKonvaImg.x() - attrs.x
        attrs.y = productKonvaImg.y() - attrs.y
      }
      tagKonvaImg.setAttrs(attrs)
    }

    tagKonvaImg.on('click tap dragstart', e => {
      tagTransformer.nodes([])
      tagTransformer.nodes([e.target])
    })

    tagKonvaImg.on('dragmove', e => {
      const widthDifference = e.target.width() - konvaPrice.width()
      const heightDifference = e.target.height() - konvaPrice.height()
      konvaPrice.setAttrs({
        x: e.target.x() + widthDifference / 2,
        y: e.target.y() + heightDifference / 2,
        offsetX: e.target.width() / 2,
        offsetY: e.target.height() / 2
      })
    })

    tagKonvaImg.on('transformstart', () => {
      if (!product.price) {
        alert('Defina um preço para o produto')
        tagTransformer.setNodes([])
        layer.draw()
      }
    })

    tagKonvaImg.on('transformend dragend', e => {
      const delta = {
        x: productKonvaImg.x() - e.target.attrs.x,
        y: productKonvaImg.y() - e.target.attrs.y,
        width: e.target.attrs.width,
        height: e.target.attrs.height,
        scaleX: e.target.attrs.scaleX,
        scaleY: e.target.attrs.scaleY,
        offsetX: e.target.attrs.offsetX,
        offsetY: e.target.attrs.offsetY
      }

      const absoluteScale = tagKonvaImg.scale()
      const deltaX = absoluteScale.x

      konvaPrice.setAttrs({
        fontSize: 70 * deltaX + 10
      })
      konvaPrice.setAttrs({
        offsetX: konvaPrice.width() / 2,
        offsetY: konvaPrice.height() / 2,
        x: tagKonvaImg.x(),
        y: tagKonvaImg.y()
      })
      const priceAttrs = {
        fontSize: konvaPrice.fontSize(),
        offsetX: konvaPrice.width() / 2,
        offsetY: konvaPrice.height() / 2
      }
      art.updateObjectOnCanvas('product', product.id, {
        priceAttrs: JSON.stringify(priceAttrs)
      })

      art.updateObjectOnCanvas('product', product.id, {
        priceAttrs: JSON.stringify(priceAttrs),
        tagAttrs: JSON.stringify(delta)
      })

      layer.draw()
    })

    !existingTag && layer.add(tagKonvaImg)

    // ---------------------- product price ----------------------//

    const existingPrice = canvas.findOne(el => el.name() === 'product-price')
    const konvaPrice = existingPrice || new Konva.Text()

    konvaPrice.setAttrs({
      name: 'product-price',
      text: product.price || '',
      fontSize: 82,
      fontWeight: 900,
      fontStyle: 'bold',
      fontFamily: 'Montserrat',
      absolutePositioned: true,
      textAlign: 'center',
      fill: '#ffffff',
      visible: product.tagVisible,
      draggable: false
    })

    konvaPrice.setAttrs({
      offsetX: konvaPrice.width() / 2,
      offsetY: konvaPrice.height() / 2,
      x: tagKonvaImg.x(),
      y: tagKonvaImg.y()
    })

    if (product.priceAttrs) {
      const attrs = JSON.parse(product.priceAttrs)
      konvaPrice.setAttrs(attrs)
    }

    !existingPrice && layer.add(konvaPrice)

    // ---------------------- product name ----------------------//

    const existingName = canvas.findOne(el => el.name() === 'product-name')
    const konvaName = existingName || new Konva.Text()

    if (existingName) {
      konvaName.off('transformend dragend')
      konvaName.off('transform')
      konvaName.off('click tap dragstart')
    }

    konvaName.setAttrs({
      name: 'product-name',
      text: product.name,
      fontSize: size || 70,
      fontFamily: store.art?.offer?.textFamily || 'Montserrat',
      fontStyle: store.art?.offer?.textStyle || 'normal',
      fill: '#000',
      drawBorder: true,
      align: 'center',
      draggable: true
    })

    konvaName.setAttrs({
      offsetX: konvaName.width() / 2,
      offsetY: konvaName.height() / 2
    })

    const centerNameOnProduct =
      productKonvaImg.x() + productKonvaImg.width() / 4

    konvaName.setAttrs({
      x: centerNameOnProduct,
      y: productKonvaImg.y() - productKonvaImg.height() / 2 - konvaName.height()
    })

    if (product.nameAttrs) {
      const attrs = JSON.parse(product.nameAttrs)
      attrs.x = productKonvaImg.x() - (attrs.x || 0)
      attrs.y = productKonvaImg.y() - (attrs.y || 0)
      konvaName.setAttrs(attrs)
    } else {
      konvaName.setAttrs({
        width: productKonvaImg.width() + tagKonvaImg.width() / 2
      })
      const centerNameOnProduct =
        productKonvaImg.x() + productKonvaImg.width() / 4
      konvaName.setAttrs({
        x: centerNameOnProduct,
        y:
          productKonvaImg.y() -
          productKonvaImg.height() / 2 -
          konvaName.height()
      })
    }

    layer.add(konvaName)
    konvaName.on('click tap dragstart', e => {
      nameTransformer.nodes([])
      nameTransformer.nodes([e.target])
      nameTransformer.moveToTop()
      layer.draw()
    })
    konvaName.on('transform', () => {
      konvaName.setAttrs({
        width: Math.max(konvaName.width() * konvaName.scaleX(), 80),
        scaleX: 1,
        scaleY: 1
      })
      let attrs = {}
      if (product.nameAttrs) {
        attrs = Object.assign(attrs, JSON.parse(product.nameAttrs))
      }

      art.updateObjectOnCanvas('product', product.id, {
        nameAttrs: JSON.stringify(
          Object.assign(attrs, {
            x: productKonvaImg.x() - konvaName.x(),
            y: productKonvaImg.y() - konvaName.y(),
            width: konvaName.width()
          })
        )
      })
      canvas.draw()
    })

    konvaName.on('transformend dragend', e => {
      let attrs = {}
      if (product.nameAttrs) {
        attrs = Object.assign(attrs, JSON.parse(product.nameAttrs))
      }
      art.updateObjectOnCanvas('product', product.id, {
        nameAttrs: JSON.stringify(
          Object.assign(attrs, {
            fontSize: canvas._nameFontSize,
            x: productKonvaImg.x() - e.target.attrs.x,
            y: productKonvaImg.y() - e.target.attrs.y,
            width: e.target.attrs.width,
            offsetX: e.target.attrs.offsetX,
            offsetY: e.target.attrs.offsetY
          })
        )
      })
      canvas.draw()
    })

    const existingHelp = canvas.findOne(el => el.name() === 'helptext')
    const helpText = existingHelp || new Konva.Text()

    helpText.setAttrs({
      name: 'helptext',
      text: 'Clique no nome do produto ou etiqueta para posicionar',
      fontSize: 50,
      fill: '#999999',
      x: 60,
      y: canvas.height() + 710
    })

    !existingHelp && layer.add(helpText)

    const existingTagTransformer = canvas.findOne(
      el => el.id() === 'tag-transformer'
    )
    const tagTransformer = existingTagTransformer || new Konva.Transformer()
    tagTransformer.setAttrs({
      id: 'tag-transformer',
      rotateEnabled: true,
      centeredScaling: true,
      padding: -12,
      anchorSize: 8,
      borderStroke: 'black',
      borderStrokeWidth: 1,
      borderDash: [3, 3],
      anchorStroke: 'black',
      anchorFill: 'white',
      enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
      boundBoxFunc: (oldBox, newBox) => {
        return newBox.width < 50 ||
          newBox.width > canvas.width() ||
          newBox.height > 300
          ? oldBox
          : newBox
      }
    })
    !existingTagTransformer && layer.add(tagTransformer)

    const existingNameTransformer = canvas.findOne(
      el => el.id() === 'name-transformer'
    )
    const nameTransformer = existingNameTransformer || new Konva.Transformer()
    nameTransformer.setAttrs({
      id: 'name-transformer',
      rotateEnabled: false,
      centeredScaling: true,
      padding: 8,
      anchorSize: 8,
      borderStroke: 'black',
      borderStrokeWidth: 1,
      borderDash: [3, 3],
      anchorStroke: 'black',
      anchorFill: 'white',
      enabledAnchors: ['middle-left', 'middle-right'],
      boundBoxFunc: (oldBox, newBox) => {
        return newBox.width < 80 || newBox.width > canvas.width()
          ? oldBox
          : newBox
      }
    })

    !existingNameTransformer && layer.add(nameTransformer)
    canvas.draw()
  }

  useEffect(() => {
    const product = art.selectedElement.element
    if (!product) return
    if (!canvas) {
      const canvas = initCanvas()
      setCanvas(canvas)
      drawProduct(canvas, { ...product })
    }

    drawProduct(canvas, { ...product })

    if (ref && ref.base) {
      ref.base.focus()
    }
  }, [art.selectedElement.element, product])

  return (
    <Grid
      style={{ overflow: 'hidden', overflowY: 'auto' }}
      container
      wrap='nowrap'
      direction='column'
    >
      <Grid item xs={12}>
        <Toolbar disableGutters>
          {mobileDetect.isMobile() && (
            <FlatButton onClick={art.hideElementDetails} label='Esconder' />
          )}
          <div style={{ flex: 1 }} />
          <FlatButton
            onClick={() =>
              art.selectNextProduct(art.selectedElement.element.id)
            }
            label={
              <Grid container alignItems='center'>
                Próximo Produto <KeyboardArrowRightOutlined />
              </Grid>
            }
          />
        </Toolbar>
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            height: 260,
            width: mobileDetect.isMobile() ? 336 : 365,
            backgroundColor: Colors.textInputColor,
            display: 'flex',
            borderRadius: 12,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Grid id='product-canvas' />
        </div>
        <Box padding={1} />
      </Grid>
      <Grid item xs={12}>
        <Slider
          min={12}
          max={180}
          step={5}
          value={size}
          onChange={(_, size) => {
            setSize(size)
            canvas.updateFontSize(size)
          }}
        />
        <Box padding={1} />
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={10}>
          <CustomTextField
            value={art.selectedElement.element.name}
            onChange={e => {
              art.updateObjectOnCanvas(
                'product',
                art.selectedElement.element.id,
                {
                  name: e.target.value
                }
              )
              setProduct(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <IconButton
            onClick={e => {
              e.preventDefault()
              art.updateObjectOnCanvas(
                'product',
                art.selectedElement.element.id,
                {
                  tagVisible: !art.selectedElement.element.tagVisible
                }
              )
            }}
          >
            {art.selectedElement.element.tagVisible === true ? (
              <LabelOutlined />
            ) : (
              <LabelOffOutlined />
            )}
          </IconButton>
        </Grid>
        <Box padding={1} />
      </Grid>
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={6}>
          <NumberFormat
            onKeyPress={e => {
              e.keyCode === 13 &&
                art.selectNextProduct(art.selectedElement.element.id)
            }}
            onClick={e => e.target.select && e.target.select()}
            onFocus={e => e.target.select && e.target.select()}
            getInputRef={setRef}
            value={art.selectedElement.element.price || ''}
            onValueChange={e => {
              art.updateObjectOnCanvas(
                'product',
                art.selectedElement.element.id,
                {
                  price: e.formattedValue
                }
              )
              setProduct(e.formattedValue)
            }}
            variant='outlined'
            type='tel'
            fullWidth
            decimalSeparator=','
            thousandSeparator='.'
            placeholder='Preço Normal'
            customInput={CustomTextField}
          />
        </Grid>
        <Grid item xs={6}>
          <NumberFormat
            onKeyPress={e => {
              e.keyCode === 13 &&
                art.selectNextProduct(art.selectedElement.element.id)
            }}
            onClick={e => e.target.select && e.target.select()}
            onFocus={e => e.target.select && e.target.select()}
            getInputRef={setRef}
            value={art.selectedElement.element.clubPrice || ''}
            onValueChange={e => {
              console.log(e)
              art.updateObjectOnCanvas(
                'product',
                art.selectedElement.element.id,
                {
                  clubPrice: e.formattedValue
                }
              )
              setProduct(e.formattedValue)
            }}
            variant='outlined'
            type='tel'
            fullWidth
            decimalSeparator=','
            thousandSeparator='.'
            placeholder='Preço Clube'
            customInput={CustomTextField}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box padding={1} />
        <Typography variant='caption'>
          Pressione ENTER para ver o próximo produto
        </Typography>
        <Box padding={1} />
        <Divider />
        <Box padding={1} />
        <Grid container justify='space-between' item>
          <FlatButton
            onClick={() =>
              art.removeObject('product', art.selectedElement.element.id)
            }
            label='Remover'
          />
          <FlatButton
            onClick={() => art.canvas.selectAllProducts()}
            label='Selecionar todos'
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
