import {
  Box,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton, Typography
} from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import { Fragment } from 'preact'
import { useState } from 'preact/hooks'
import LazyLoad from 'react-lazyload'
import useSWR from 'swr'
import { FlatButton } from '../common/button'
import store from '../stores/root'
import logoService from './providers/logo'
import { ElementUpload } from './upload/container'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper
    },
    gridList: {
      flexWrap: 'nowrap',
      transform: 'translateZ(0)'
    },
    title: {
      padding: 10,
      objectFit: 'scale-down',
      cursor: 'pointer',
      color: '#FFFFFF'
    },
    titleBar: {
      background:
        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.1) 15%, rgba(0,0,0,0) 70%)'
    },
    CancelIcon: {
      color: '#F45959'
    }
  })
)

export const LogoScreen = () => {
  const classes = useStyles()
  const [removing, setRemoving] = useState(false)

  const { data: logos = [], mutate } = useSWR(`logos`, logoService.logos)

  const handleDeleteLogos = async id => {
    await logoService.remove(id)
    mutate()
  }

  const height = 375 / 4

  return (
    <Fragment>
      <Box padding={2}>
        <ElementUpload onElementUploaded={() => mutate()} />
      </Box>
      <Box flex width='100%' padding={2}>
        <FlatButton
          onClick={() => setRemoving(!removing)}
          variant='contained'
          startIcon={removing ? <CloseIcon /> : <DeleteIcon />}
          label={removing ? 'Cancelar' : 'Remover elementos'}
          paddingRight={2}
        />
      </Box>
      <Box px={2}>
        <Typography variant='caption' color='secondary'>
          {removing && 'Selecione os elementos que deseja remover'}
        </Typography>
      </Box>
      <Box padding={2} paddingTop={0}>
        {logos && (
          <GridList spacing={8} cellHeight={100} cols={4}>
            {logos &&
              logos.map(logo => (
                <LazyLoad
                  scrollContainer='#offer-content'
                  key={logo._id}
                  unmountIfInvisible={true}
                  debounce={5}
                  height={height}
                  style={{ overflow: 'hidden' }}
                >
                  <GridListTile
                    button
                    className={classes.tile}
                    key={logo._id}
                    style={{ cursor: 'pointer', objectFit: 'cover' }}
                    onClick={() =>
                      removing
                        ? handleDeleteLogos(logo._id)
                        : store.art.addLogos(logo)
                    }
                  >
                    <img
                      src={logo.imageURL}
                      style={{
                        width: height,
                        overflow: 'hidden'
                      }}
                    />
                    {logo._id.includes('logos') && (
                      <GridListTileBar
                        classes={{
                          root: classes.titleBar,
                          title: classes.title
                        }}
                        actionIcon={
                          removing && (
                            <IconButton
                              onClick={() => handleDeleteLogos(logo._id)}
                              aria-label={'Mais informações'}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )
                        }
                      />
                    )}
                  </GridListTile>
                </LazyLoad>
              ))}
          </GridList>
        )}
      </Box>
    </Fragment>
  )
}
