import graphql from '../../http/graphql'
import {
  addCustomBackgroundMutation,
  updateBackgroundMutation
} from '../graphql/mutations'
import {
  customBackgroundsQuery,
  fetchBackgroundQuery,
  fetchBackgroundsByFilterQuery,
  fetchBackgroundsQuery,
  recentBackgroundsQuery
} from '../graphql/queries'

class BackgroundService {
  async backgrounds(format, term) {
    return graphql
      .query(fetchBackgroundsQuery, { format, term })
      .then(b => b.backgrounds)
  }

  async backgroundsByFilter(filter) {
    return graphql
      .query(fetchBackgroundsByFilterQuery, { filter })
      .then(b => b.backgroundsByFilter)
  }

  async background(id) {
    return graphql.query(fetchBackgroundQuery, { id }).then(b => b.background)
  }

  async recent(format) {
    return graphql
      .query(recentBackgroundsQuery, { format })
      .then(d => d.recentBackgrounds)
  }

  update = data => {
    return graphql.query(updateBackgroundMutation, { data })
  }

  custom = () => {
    return graphql.query(customBackgroundsQuery).then(d => d.customBackgrounds)
  }

  addCustom = data => {
    return graphql.query(addCustomBackgroundMutation, { data })
  }
}

const backgroundService = new BackgroundService()
export default backgroundService
