import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  Grid,
  Typography,
} from '@material-ui/core'
import { DateTime } from 'luxon'
import { Fragment } from 'preact'
import { useState } from 'preact/hooks'
import { useHistory } from 'react-router'
import ConfirmDialog from '../../common/confirm'
import AppRoutes from '../../navigation/routes'
import { useMobileDetect } from '../../utils/mobile'
import { getImageUrl } from '../../utils/url'

export default function OfferCard({ offer, onRemove, onDuplicate }) {
  const mobileDetect = useMobileDetect()
  const [dialog, setDialog] = useState(false)
  const history = useHistory()

  const handleNavigation = () => {
    const route = mobileDetect.isMobile() ? `${AppRoutes.EditOfferMobile.replace(':key', offer._key)}/arte` :
      `${AppRoutes.EditOffer.replace(':key', offer._key)}/arte`

    history.push(route)
  }

  return (
    <Fragment>
      <ConfirmDialog
        style={{ fontFamily: 'Montserrat' }}
        cancelLabel='Cancelar'
        confirmLabel='Remover'
        content='Deseja remover este encarte? Esta ação não pode ser desfeita'
        onCancel={() => setDialog(false)}
        open={dialog}
        title='Remover Encarte'
        onConfirm={() => {
          setDialog(false)
          onRemove()
        }}
      />
      <Grid item xs={12} md={3} key={offer._id}>
        <Card
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%'
          }}
          elevation={1}
        >
          <CardActionArea onClick={handleNavigation}>
            <CardMedia
              style={{
                height: 80,
                paddingTop: '56.25%',
                backgroundPosition: 'top'
              }}
              image={
                offer.preview ||
                getImageUrl(offer.background?.imageURL || '', 180)
              }
            />
            <Box padding={2}>
              <Typography variant='body2'>
                {DateTime.fromISO(offer.updatedAt).toFormat('dd/MM/yyyy hh:mm')}
              </Typography>
            </Box>
          </CardActionArea>
          <CardActions>
            <Grid style={{ padding: 8 }} container justify='space-between'>
              <Grid item>
                <Button onClick={() => setDialog(true)} size='small'>
                  <Typography color='primary' variant='button'>
                    Remover
                  </Typography>
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={onDuplicate} size='small'>
                  <Typography color='primary' variant='button'>
                    Duplicar
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
    </Fragment>
  )
}
