import graphql from '../http/graphql'
import {
  createProductToReviewMutation,
  removeProductMutation,
  updateProductMutation
} from './graphql/mutations'
import {
  favoriteProductsQuery,
  fetchProductQuery,
  fetchProductsQuery,
  lastProductPriceQuery,
  recentProductsQuery,
  searchProductsQuery,
  userProductsQuery
} from './graphql/queries'

class ProductService {
  async products () {
    return graphql.query(fetchProductsQuery).then(p => p.products)
  }

  async product (id) {
    return graphql.query(fetchProductQuery, { id }).then(p => p.product)
  }

  async update (data) {
    return graphql
      .query(updateProductMutation, { data })
      .then(p => p.updateProduct)
  }

  async search (term) {
    return graphql
      .query(searchProductsQuery, { term })
      .then(d => d.searchProducts)
  }

  async productToReview (data) {
    return graphql
      .query(createProductToReviewMutation, { data })
      .then(d => d.createProductToReview)
  }

  async recent () {
    return graphql.query(recentProductsQuery).then(d => d.recentProducts)
  }

  async userProducts () {
    return graphql.query(userProductsQuery).then(d => d.userProducts)
  }

  async favorite () {
    return graphql.query(favoriteProductsQuery).then(d => d.favoriteProducts)
  }

  async remove (id) {
    return graphql
      .query(removeProductMutation, { id })
      .then(d => d.removeProduct)
  }

  async lastPrice (key) {
    return graphql
      .query(lastProductPriceQuery, { key })
      .then(d => d.lastProductPrice)
  }
}

const productService = new ProductService()
export default productService
