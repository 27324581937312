import { Button, Typography } from '@material-ui/core'
import Colors from '../config/colors'

export const RaisedButton = props => {
  const { label, onClick, primary, accent, disabled, ...rest } = props
  return (
    <Button
      color={primary ? 'primary' : accent ? 'secondary' : 'inherit'}
      disableElevation
      variant='contained'
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      <Typography
        color={primary || accent ? 'inherit' : 'primary'}
        variant='button'
      >
        {label}
      </Typography>
    </Button>
  )
}

export const FlatButton = props => {
  const { label, onClick, primary, disabled, ...rest } = props
  return (
    <Button
      {...rest}
      color={primary ? 'primary' : 'inherit'}
      disableElevation
      disabled={disabled}
      onClick={onClick}
    >
      <Typography
        color={primary ? 'primary' : 'textSecondary'}
        variant='button'
      >
        {label}
      </Typography>
    </Button>
  )
}

export const GlowingButton = ({ onClick, label }) => {
  return (
    <Button
      style={{
        alignSelf: 'center',
        padding: 8,
        backgroundColor: Colors.accentColor,
        '-webkit-box-shadow': '0 0 30px -10px #2196F3',
        '-moz-box-shadow': '0 0 30px -10px #2196F3',
        'box-shadow': '0 0 30px -10px #2196F3'
      }}
      onClick={onClick}
      fullWidth
      disableElevation
      size='large'
      color='primary'
      variant='contained'
    >
      {label || ''}
    </Button>
  )
}
