export const fetchProductsQuery = `
  query Products {
      products {
          _id
          _key
          _rev
          name
          imageURL
      }
  }
`

export const fetchProductQuery = `
  query Product($id: String!) {
      product(id: $id) {
         _id
         _key
         _rev
         name
         imageURL
      }
  }
`

export const searchProductsQuery = `
  query SearchProducts($term: String!){
      searchProducts(term: $term){
         _id
         _key
         _rev
         name
         imageURL
      }
  }
`

export const recentProductsQuery = `
  query recentProducts {
    recentProducts {
      _id
      _key
      _rev
      name
      imageURL
    }
  }
`

export const userProductsQuery = `
  query userProducts {
    userProducts {
      _id
      _key
      _rev
      name
      custom
      imageURL
    }
  }
`

export const favoriteProductsQuery = `
  query favoriteProducts {
    favoriteProducts {
      _id
      _key
      name
      imageURL
    }
  }
`

export const lastProductPriceQuery = `
  query lastProductPrice($key: String!) {
    lastProductPrice(key: $key)
  }
`