export let hostname
export let origin
export let CDN = 'https://d2bxayf79dnwor.cloudfront.net'

if (typeof window !== 'undefined') {
  origin = window.location.origin
  hostname = window.location && window.location.hostname
}

export const WEBSITE_URL =
  hostname === 'localhost'
    ? 'http://localhost:8080'
    : 'https://app.ofertafacilapp.com.br'

export const API_ROOT_URL =
  hostname === 'localhost'
    ? 'http://localhost:9800'
    : 'https://api.ofertafacilapp.com.br'
