export const updateProductMutation = `
mutation updateProduct($data: ProductInput!) {
    updateProduct(data: $data) {
        _id
        _key
        _rev
        name
        imageURL
    }
}
`
export const createProductToReviewMutation = `
mutation CreateProductToReview($data: ProductInput!) {
    createProductToReview(data: $data) {
        _id
        _key
        _rev
        name
        imageURL
    }
}
`
export const removeProductMutation = `
    mutation removeProduct($id: String!) {
        removeProduct(id: $id)
    }
`
