import { inject, observer } from 'mobx-preact'
import { SignupForm } from './form'
import { useSignupState } from './state'

const Signup = () => {
  const state = useSignupState()
  return <SignupForm {...state} />
}

export const SignupScreen = inject('auth')(observer(Signup))
