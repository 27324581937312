import { GridList, GridListTile } from '@material-ui/core'
import { ProductMenuThumb } from '../menu'

export const ProductSearchResults = ({ results, onSelect, selected }) => {
  const isSelected = id => {
    return selected.findIndex(p => p._id === id) > -1
  }

  return (
    <GridList cellHeight={375 / 3} cols={3}>
      {results.map(p => (
        <GridListTile button onClick={() => onSelect(p)}>
          <ProductMenuThumb product={p} selected={isSelected} />
          {/* <img
            title={p.name}
            style={{
              cursor: 'pointer',
              opacity: isSelected(p._id) ? 1 : 0.54,
              objectFit: 'scale-down'
            }}
            src={getImageUrl(p.imageURL, 180)}
          /> */}
        </GridListTile>
      ))}
    </GridList>
  )
}
